/* eslint-disable react/require-default-props */
import React, {
  ChangeEvent, Dispatch, FC, SetStateAction, memo, useEffect, useState,
} from 'react';
import styled, { useTheme } from 'styled-components';
import Themes from '../../themes';
import BlockForFileAdd from '../NewBlockForAddingFiles';

import {
  InputWithNoValidation,
  CustomDateInput,
  InputBox,
  Label,
  ErrorForInput,
} from '../FormElements';
import { ButtonWithCross, UniversalButton } from '../RestyledButtons';
import { useSelector, useDispatch } from '../../store/store.types';
import { TravelsFontMixixn20, TravelsFontMixixn24 } from '../../constants/fontsConfigs';
import { TEducation, TOrganizationForEducaiton } from '../../types/apiTypes';
import createAutoCorrectedDatePipe from '../../helpers/dateFunc';
import { BlackDelIcon } from '../icons';

export const GridBox = styled.ul<{ isOpen: boolean }>`
    display: ${({ isOpen }) => (isOpen ? 'grid' : 'none')};
    grid-template-columns: repeat(2, minmax(340px, 1fr));
    grid-auto-flow: row;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    transition: all ease .4s;
    list-style: none;
    margin: 0;
    padding: 0;
    column-gap: 22px;
    row-gap: 22px;
    width: 100%;
`;

export const GridColumnElement = styled.li`
  display:flex;
  flex-direction: column;
  gap: 25px;
  position: relative;
  align-items: flex-start;
  & button {
    font-size: 20px;
  }
`;

const ListOfSkills = styled.ul<{ isOpen: boolean }>`
    padding: 16px 24px;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    flex-direction: column;
    gap: 16px;
    transition: opacity ease .4s;
    background-color: ${({ isOpen, theme: { plateColor, focusColor } }) => plateColor};
    box-shadow: ${({ isOpen }) => (isOpen ? '0px 8px 20px 0px rgba(0, 0, 0, 0.25)' : 'none')};
    overflow: auto;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    z-index: 0;
    list-style: none;
    margin: 0;
    margin-top: -25px;
    width: 100%;
    max-height: 500px;
    box-sizing: border-box;
`;

const ListItem = styled.li`
     ${TravelsFontMixixn20}
     color:  ${({ theme: { mainTextColor } }) => mainTextColor};
     display: flex;
     align-items: center;
     gap: 21px;
     cursor: pointer;
     align-items: flex-start;
     align-items: center;
     &:hover {
       color: ${({ theme: { mainButtonColor } }) => mainButtonColor};
  }
`;

const SpecialityInput = styled.input<{ isOpen: boolean, withError: boolean }>`
    padding: 16px 15px;
    width: 100%;
    border: ${({ withError, theme: { errorColor } }) => (withError ? `1px solid ${errorColor}` : 'none')};
    border-radius: 7px;
    outline: none;
    z-index: 5;
    box-sizing: border-box;
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
    box-shadow: ${({ isOpen }) => (isOpen ? '0px 8px 20px 0px rgba(0, 0, 0, 0.25)' : 'none')};
    ${TravelsFontMixixn20}
    background-color: ${({ isOpen, theme: { plateColor, focusColor } }) => (isOpen ? focusColor : plateColor)};
    & ::placeholder {
      ${TravelsFontMixixn20}
      color:  ${({ theme: { placeholderColor } }) => placeholderColor};
    }

`;

const NewEducationBlock: FC<{
  onChange: (e: ChangeEvent<HTMLInputElement>, index?: number) => void,
  fileOnChange: (e: ChangeEvent<HTMLInputElement>, index?: number) => void,
  text: string,
  addOption: () => void,
  organizationsList?: TOrganizationForEducaiton[],
  setValueToRequest?: (item: string) => void,
  coursesList: TEducation[] | Omit<TEducation, 'organization_name_id'>[],
  nameOfInputForFile: string,
  deleteFunc: (index: number, pos: number) => void,
  submitEducation: (index: number) => void,
  setEducationValue?: (el: TOrganizationForEducaiton, idx: number) => void,
  isSubmited: null | number,
}> = ({
  onChange,
  fileOnChange,
  addOption,
  coursesList,
  organizationsList = [],
  setValueToRequest,
  setEducationValue,
  text,
  isSubmited,
  deleteFunc,
  submitEducation,
  nameOfInputForFile,
}) => {
  const [isOpen, openListOfEducation] = useState<null | number>(null);
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('yyyy', { minYear: 1970, maxYear: new Date().getFullYear() + 4 });
  const { theme } = useSelector((state) => state.all);
  const closeList = (e: any) => {
    if (e.target.id === 'educationName') { return; }
    openListOfEducation(null);
  };
  useEffect(() => {
    document.addEventListener('click', closeList);
    return () => {
      document.removeEventListener('click', closeList);
    };
  }, []);

  return (
    <>
      <GridBox isOpen>
        {coursesList?.map((el, index) => (
          <GridColumnElement>

            <BlackDelIcon
              onClick={(e: any) => { e.preventDefault(); deleteFunc(el.id!, index); }}
              style={{ position: 'absolute', top: '0px', right: '0px' }} />
            <Label htmlFor='educationName'>
              Название организации
            </Label>
            <SpecialityInput
              id='educationName'
              isOpen={organizationsList?.length > 0 && isOpen === index}
              name='educationName'
              type='text'
              withError={false}
              value={el.organization_name}
              onChange={(e) => { onChange(e, index); openListOfEducation(index); setValueToRequest && setValueToRequest(e.target.value); }}
              placeholder='Введите название учреждения' />
            {isSubmited === index && el.organization_name === '' && <ErrorForInput>Укажите название учреждения</ErrorForInput>}
            <ListOfSkills id='educationName' isOpen={organizationsList?.length > 0 && isOpen === index}>
              {organizationsList?.map((elem, idx) => (
                <ListItem onClick={() => { setEducationValue && setEducationValue(elem, index); openListOfEducation(null); }}>{elem.name}</ListItem>
              ))}
            </ListOfSkills>
            <InputWithNoValidation
              name='speciality'
              label='Специальность/название курса'
              type='text'
              value={el.speciality}
              onChange={(e) => onChange(e, index)}
              placeholder='Введите название специальности или курса' />
            {isSubmited === index && el.speciality === '' && <ErrorForInput>Укажите название специальности</ErrorForInput>}
            <InputBox>
              <Label htmlFor='educationEnd'>Год окончания обучения</Label>
              <CustomDateInput
                mask={[/\d/, /\d/, /\d/, /\d/]}
                pipe={autoCorrectedDatePipe}
                guide={false}
                name='educationEnd'
                id='educationEnd'
                value={el.education_end_year}
                onChange={(e) => onChange(e, index)}
                placeholder='2023' />
              {isSubmited === index && el.education_end_year === '' && <ErrorForInput>Укажите год</ErrorForInput>}
            </InputBox>

            <BlockForFileAdd
              headerText='Документ'
              fileName={el.file_name || ''}
              file={el.image || ''}
              currentIndex={index}
              inputName={nameOfInputForFile}
              textStub='Прикрепите документ'
              buttonText='Добавить файл с устройства'
              setFileToState={fileOnChange} />
            <UniversalButton
              onClick={(e) => { e.preventDefault(); submitEducation(index); }}
              type='button'
              textColor={Themes[theme].mainBg}
              backColor={Themes[theme].sliderColor}
              borderColor=''
              paddingLeft={40}
              paddingTop={20}>
              Сохранить
            </UniversalButton>

          </GridColumnElement>

        ))}
        {
            coursesList.length < 2
            && (
              <GridColumnElement style={{ width: '50%' }}>
                <ButtonWithCross
                  onClick={addOption}
                  withText={false}
                  height={526.86} />
              </GridColumnElement>
            )
          }
      </GridBox>

      {coursesList.length >= 2 && (
      <ButtonWithCross
        onClick={addOption}
        withText={false}
        height={56} />
      )}

    </>
  );
};

export default memo(NewEducationBlock);
