/* eslint-disable import/prefer-default-export */
import React, { FC } from 'react';
import styled from 'styled-components';

import { TravelsFontMixixn20, TravelsFontMixixn24 } from '../constants/fontsConfigs';
import { useSelector } from '../store/store.types';
import { FullDonePicBlackIcon, GreenOkIcon } from './icons';
import {
  List,
  ListItemWithIcon,
  ListItemWithiconAndColoredTextv,
  TextSpan,
  CostElement,
  P,
  BuyFuncComponent,
  UniversalContentContainer,
} from './widgets/PayFormAndSubscription/PayComponents';
import { UniversalButton } from './RestyledButtons';
import Themes from '../themes';

const Span = styled.span`
  ${TravelsFontMixixn20}
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  align-self: flex-start;
`;
const CancelButton = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color:${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn20}
  align-self: flex-start !important;
  margin-top: 0 !important;
  cursor: pointer;
`;
const Devider = styled.div`
    width: 1px;
    background-color: ${({ theme: { mainTextColor } }) => mainTextColor};
    height: 180px;
    
`;

const CustomList = styled(List)`
  width: 100%;
  max-width: 655px;
`;

const CustomUniversalContainer = styled(UniversalContentContainer)`
  gap: 60px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const CostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

export const SubscriptionInclusive: FC<{ subType: string, theme: 'light' | 'dark' }> = ({ subType, theme }) => (
  <>
    <Span>Входит в вашу подписку</Span>
    <CustomUniversalContainer direction={false} width={1360} isBorderClicked>
      {subType === 'primary' && (
        <>
          <CustomList>
            <ListItemWithIcon text='Заполнение 1 hard skill' />
            <ListItemWithIcon text='Добавление 1 папки с достижением' />
            <ListItemWithIcon text='Добавление 1 проекта' />
            <ListItemWithIcon text='Неограниченный просмотр Витрины проектов и Витрины специалистов' />
          </CustomList>
          <Devider />
          <CustomList>
            <ListItemWithiconAndColoredTextv>
              <P>
                Публикация
                {' '}
                <TextSpan>2 проектов</TextSpan>
                {' '}
                на Витрину проектов
              </P>
            </ListItemWithiconAndColoredTextv>
            <ListItemWithiconAndColoredTextv>
              <P>
                Публикация
                {' '}
                <TextSpan>2 анкет</TextSpan>
                {' '}
                на Витрину специалистов
              </P>
            </ListItemWithiconAndColoredTextv>
            <ListItemWithiconAndColoredTextv>
              <P>

                <TextSpan>2 отклика</TextSpan>
                {' '}
                на вакансию или проект
              </P>
            </ListItemWithiconAndColoredTextv>
          </CustomList>
        </>
      )}
      {subType === 'primaryplus' && (
        <List>
          <ListItemWithiconAndColoredTextv>
            <P>
              <TextSpan>Неограниченные</TextSpan>
              {' '}
              действия на платформе
            </P>
          </ListItemWithiconAndColoredTextv>
          <ListItemWithIcon text='Новости о будущих мероприятиях, форумах и акселлераторах' />
          <ListItemWithIcon text='Проверка и обратная связь от разработчиков ваших проектов и анкет ' />
        </List>
      )}
      {
        subType === 'dop' && (
          <Wrapper>
            <List>
              <BuyFuncComponent isChoosen text='Публикация 1 проекта на Витрину проектов' />
              <BuyFuncComponent isChoosen={false} text='Публикация 1 анкеты на Витрину специалистов' />
              <BuyFuncComponent isChoosen={false} text='1 отклик на вакансию или проект' />
            </List>
            <CostWrapper>
              <CostElement text='за одну функцию' cost={99} />
              <UniversalButton
                style={{ marginTop: '0' }}
                type='button'
                textColor={Themes[theme].mainTextColor}
                backColor={Themes[theme].sliderColor}
                borderColor=''
                paddingLeft={40}
                paddingTop={20}>
                Купить ещё
              </UniversalButton>
            </CostWrapper>
          </Wrapper>
        )
      }

    </CustomUniversalContainer>
    <CancelButton>Отменить подписку</CancelButton>
  </>
);
