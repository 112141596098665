import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import {
  ExtraLinkIcon,
  GitIcon,
  SmsTrackIcon,
  TgIcon,
  VkIcon,
} from '../icons';
import {
  TravelsFontMixixn24,
  TravelsFontMixixn20,
  TravelsFontMixixn30,

} from '../../constants/fontsConfigs';
import { UniversalButton } from '../RestyledButtons';
import Themes from '../../themes';
import { CustomMaskedInput } from '../FormElements';
import { useSelector, useDispatch } from '../../store/store.types';
import { openResponseToProjectPopup } from '../../store/allSlice';

const P = styled.p`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn20}
  white-space: break-spaces;
  display: inline;
`;

const H2 = styled.span`
  color: ${({ theme: { sliderColor } }) => sliderColor};
  ${TravelsFontMixixn30}
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 31px 55px;
  border-radius: 20px;
  background-color: ${({ theme: { mainBg } }) => mainBg};
  border: ${({ theme: { mainTextColor } }) => `1px solid ${mainTextColor}`};
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 850px;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  & button {
    align-self: center;
  }
`;

const AttentionPopupProjects: FC<{ proj: string, prof: string }> = ({ proj, prof }) => {
  const { pair, theme } = useSelector((state) => state.all);
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <P>
        В проекте
        <H2>{proj}</H2>
        {' '}
        не требуются ваши специальности.
      </P>

      <UniversalButton
        onClick={() => { dispatch(openResponseToProjectPopup(false)); }}
        type='button'
        textColor={Themes[theme].mainTextColor}
        backColor={Themes[theme].sliderColor}
        borderColor=''
        paddingLeft={44}
        paddingTop={20}>
        Закрыть
      </UniversalButton>
    </Wrapper>

  );
};

export default AttentionPopupProjects;
