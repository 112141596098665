import React, { FC } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from '../../store/store.types';
import { BlackDelIcon } from '../icons';

const Div = styled.div`
    max-width: 90vw;
    height: 81vh;
    border-radius: 20px;
    position: relative;
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const ImageInBigScreen: FC<{ close:any }> = ({ close }) => {
  const { imageOfDiploma } = useSelector((state) => state.all);
  const dispatch = useDispatch();
  return (
    <Div>
      <BlackDelIcon
        onClick={() => dispatch(close(false))}
        style={{ position: 'absolute', top: '-27px', right: '-30px' }} />
      <Image src={imageOfDiploma} alt='картинка с товим достижением' />
    </Div>
  );
};

export default ImageInBigScreen;
