/* eslint-disable import/prefer-default-export */
import React, { useEffect, useRef, useState } from 'react';
import {
  setMany,
} from 'idb-keyval';
import { TProfession, TUser } from '../types/apiTypes';

/// используй на лендинге для выплывающих анимаций блоков
export function useOnScreen(ref: any) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), { threshold: 0.2 });

  useEffect(() => {
    if (ref.current !== null) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

// const setUserData = async (obj: any) => {
//   const [lastName, firstName, email, hobbies, about, fact, avatar, firstEdu, secondEdu, socialNetworks] = id ? [] : await getMany([
//     'lastName',
//     'firstName',
//     'email',
//     'hobbies',
//     'about',
//     'fact',
//     'avatar',
//     'firstEdu',
//     'secondEdu',
//     'socialNetworks']);
//   setValue('lastName', obj?.lastName || lastName);
//   setValue('firstName', obj?.firstName || firstName);
//   setValue('dateOfBirth', obj?.dateOfBirth);
//   setValue('email', obj?.email || email);
//   setValue('hobbies', obj?.hobbies || hobbies);
//   setValue('about', obj?.about || about);
//   setValue('fact', obj?.fact || fact);
//   setSocialArr(obj?.socialNetworks || (socialNetworks || ['']));
//   setAvatar(obj?.avatar || avatar);
//   if (!jwt.test()) {
//     setEducationValue(firstEdu || [{ image: '', organizationName: '', educationDates: ['', ''] }]);
//     setSecondaryObject(secondEdu || [{ image: '', organizationName: '', educationDates: ['', ''] }]);
//     return;
//   }
//   setEducationValue(obj?.education?.firstEducation?.length === 0 ? (firstEdu || [{ image: '', organizationName: '', educationDates: ['', ''] }]) : obj?.education?.firstEducation!);
//   setSecondaryObject(obj?.education?.secondaryEducation?.length === 0 ? (secondEdu || [{ image: '', organizationName: '', educationDates: ['', ''] }]) : obj?.education?.secondaryEducation!);
// };

// useEffect(() => {
//   const asyncGet = async (index: string | number) => {
//     try {
//       const newUser = await getUserById(index).unwrap();
//       newUser && setUserData(newUser);
//     } catch (err: any) {
//       console.log('');
//     }
//   };
//   id ? asyncGet(id) : setUserData(data);
// }, [id, data]);

// const timer = useRef<string | number | NodeJS.Timeout | undefined>();
// useEffect(() => {
//   clearTimeout(timer.current);

//   timer.current = setTimeout(setDataToDB, 1000, userAvatar, educationObject, secondaryEducationObject, socialArr);
// }, [userAvatar, educationObject, secondaryEducationObject, socialArr]);

export const useAutoSaveForms = (): [TUser, React.Dispatch<React.SetStateAction<TUser>>] => {
  const [objectForSave, setValuesForSaveOject] = useState<TUser>({
    first_name: '',
    last_name: '',
    full_name: '',
    image: '',
    email: '',
    birth_date: '',
    age: '',
    address: '',
    vk: '',
    telegram: '',
    github: '',
    extra_social_network: '',
    hard_skills: [],
    soft_skills: [],
    team_work_experience: '',
    educations: [],
    soft_skills_edu: [],
    tests: [],
    professions: [],
    password: '',
    re_password: '',
    achievements: [],
  });

  const setDataToDB = async ({
    first_name,
    last_name,
    full_name,
    image,
    email,
    birth_date,
    age,
    address,
    vk,
    telegram,
    github,
    extra_social_network,
    hard_skills,
    soft_skills,
    team_work_experience,
    educations,
    soft_skills_edu,
    tests,
    professions,
    password,
    re_password,
    achievements,
  }: TUser) => {
    await setMany([
      ['avatar', image],
      ['educations', educations],
      ['soft_skills_edu', soft_skills_edu],
      ['telegram', telegram],
      ['vk', vk],
      ['github', github],
      ['extra_social_network', extra_social_network],
      ['first_name', first_name],
      ['last_name', last_name],
      ['birth_date', birth_date],
      ['email', email],
      ['age', age],
      ['tests', tests],
      ['address', address],
      ['hard_skills', hard_skills],
      ['soft_skills', soft_skills],
      ['professions', professions],
      ['achievements', achievements],
    ]);
  };

  const timer = useRef<string | number | NodeJS.Timeout | undefined>();
  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(setDataToDB, 1000, objectForSave);
  }, [objectForSave]);

  return [objectForSave, setValuesForSaveOject];
};

export const useAutoSaveFormsProfCreate = (): [TProfession, React.Dispatch<React.SetStateAction<TProfession>>] => {
  const [objectForSave, setValuesForSaveOject] = useState<TProfession>({
    soft_skills: [],
    hard_skills: [],
    profession_name: '',
    level: '',
    experience: '',
    development_goals: '',
    achievements: [],
  });

  const setDataToDB = async ({
    soft_skills,
    hard_skills,
    profession_name,
    level,
    experience,
    development_goals,
    achievements,
  }: TProfession) => {
    await setMany([
      ['hard_skills_prof', hard_skills],
      ['soft_skills_prof', soft_skills],
      ['profession_name', profession_name],
      ['level', level],
      ['experience', experience],
      ['development_goals', development_goals],
      ['achievements', achievements],
    ]);
  };

  const timer = useRef<string | number | NodeJS.Timeout | undefined>();
  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(setDataToDB, 1000, objectForSave);
  }, [objectForSave]);

  return [objectForSave, setValuesForSaveOject];
};

export const onChange = (e: any, index: any, setFunc: any, arr: any) => {
  const coyarr = [...arr];
  const Object = coyarr[index];
  const copyobject = { ...Object };
  const lue = e.target.value;
  copyobject.hardSkills = lue;
  coyarr[index] = copyobject;
  setFunc(coyarr);
};
