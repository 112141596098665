import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TState = {
  isFullFilled: boolean;
  isColoured: boolean;
  isExit: Date | null;
  isOpenDatalist: boolean;
  theme: 'light' | 'dark';
  isFetchError: boolean;
  isFetchSuccess: boolean;
  filterProfessionValues: string[];
  filterDirectionsValues: string[];
  filterNewFirst: boolean;
  currentFilter: string[];
  isResponsesModalOpen: boolean;
  isBigPopupOpen: boolean;
  currentImages: string[];
  isProfessionCreatePopupOpen: boolean;
  isDeleteFolderModalOpen: boolean;
  isRedactFolderPopupOpen: boolean;
  isCreateFolderModalOpen: boolean;
  isConfirmOpen: boolean;
  folderId: number | null;
  randomArr: any;
  imageOfDiploma: string;
  isImagePopupOpenInProfileAbout: boolean;
  profId: number | null;
  projectId: number | null;
  isSpecCreatePopupOpen: boolean;
  specCreatePopupHeading: string;
  overlayLength: number;
  requestUser: number;
  isFullProjectViewPopupOpen: boolean;
  isProjectPublished: any;
  type: number | null;
  isContactsFormOpen: boolean;
  isEmailSendPopupOpen: boolean,
  isChoosePopupOpen: boolean;
  contactFormType: number | null;
  isResponseToProjectPopupOpen: boolean;
  objectForForm: {
    tel: number | null,
    name: string,
    email: string,
    vk: string,
    github: string,
    tg: string,
    image: string,
    extra: string,
  }
  isSpecResumePopupOpen: boolean;
  pair: {
    prof: string,
    proj: string,
  },
};

const initialState: TState = {
  objectForForm: {
    tel: null,
    name: '',
    email: '',
    vk: '',
    github: '',
    tg: '',
    extra: '',
    image: '',
  },
  pair: {
    prof: '',
    proj: '',
  },
  isSpecResumePopupOpen: false,
  isChoosePopupOpen: false,
  isResponseToProjectPopupOpen: false,
  isContactsFormOpen: false,
  contactFormType: null,
  isProjectPublished: null,
  requestUser: 0,
  isFullProjectViewPopupOpen: false,
  overlayLength: 1,
  isFullFilled: false,
  isColoured: false,
  isOpenDatalist: false,
  theme: 'light',
  isExit: null,
  isFetchError: false,
  isFetchSuccess: false,
  currentFilter: [],
  filterProfessionValues: [],
  filterDirectionsValues: [],
  filterNewFirst: true,
  isResponsesModalOpen: false,
  isBigPopupOpen: false,
  currentImages: [],
  isDeleteFolderModalOpen: false,
  isProfessionCreatePopupOpen: false,
  isRedactFolderPopupOpen: false,
  isConfirmOpen: false,
  folderId: null,
  isCreateFolderModalOpen: false,
  isImagePopupOpenInProfileAbout: false,
  imageOfDiploma: '',
  randomArr: [1, 2, 3],
  profId: null,
  isSpecCreatePopupOpen: false,
  specCreatePopupHeading: '',
  projectId: null,
  type: null,
  isEmailSendPopupOpen: false,
};

const allSlice = createSlice({
  name: 'allSlice',
  initialState,
  reducers: {
    setChoosePopupOpen: (state, action: PayloadAction<boolean>) => ({
      ...state, isChoosePopupOpen: action.payload,
    }),
    setEmailSendPopup: (state, action: PayloadAction<boolean>) => ({
      ...state, isEmailSendPopupOpen: action.payload,
    }),
    setPairForPopup: (state, action: PayloadAction<any>) => ({
      ...state, pair: action.payload,
    }),
    openSpecResumePopup: (state, action: PayloadAction<boolean>) => ({
      ...state, isSpecResumePopupOpen: action.payload,
    }),
    openResponseToProjectPopup: (state, action: PayloadAction<boolean>) => ({
      ...state, isResponseToProjectPopupOpen: action.payload,
    }),
    openContactsForm: (state, action: PayloadAction<boolean>) => ({
      ...state, isContactsFormOpen: action.payload,
    }),
    setContactFormType: (state, action: PayloadAction<number>) => ({
      ...state, contactFormType: action.payload,
    }),
    setObjectForForm: (state, action: PayloadAction<any>) => ({
      ...state, objectForForm: action.payload,
    }),
    ///
    setType: (state, action: PayloadAction<number>) => ({
      ...state, type: action.payload,
    }),
    openFullProjectViewPopup: (state, action: PayloadAction<boolean>) => ({
      ...state, isFullProjectViewPopupOpen: action.payload,
    }),
    setPublishStatus: (state, action: PayloadAction<any>) => ({
      ...state, isProjectPublished: action.payload,
    }),
    setRequestUser: (state, action: PayloadAction<number>) => ({
      ...state, requestUser: action.payload,
    }),
    setOverlayLength: (state, action: PayloadAction<number>) => ({
      ...state, overlayLength: action.payload,
    }),
    setProjectId: (state, action: PayloadAction<number | null>) => ({
      ...state, projectId: action.payload,
    }),
    setHeadingForPopup: (state, action: PayloadAction<string>) => ({
      ...state, specCreatePopupHeading: action.payload,
    }),
    openSpecCreatePopup: (state, action: PayloadAction<boolean>) => ({
      ...state, isSpecCreatePopupOpen: action.payload,
    }),
    setProfId: (state, action: PayloadAction<number | null>) => ({
      ...state, profId: action.payload,
    }),
    setImageForDiploma: (state, action: PayloadAction<string>) => ({
      ...state, imageOfDiploma: action.payload,
    }),
    openImagePopupOpenInProfileAbout: (state, action: PayloadAction<boolean>) => ({
      ...state, isImagePopupOpenInProfileAbout: action.payload,
    }),
    openConfirmPopup: (state, action: PayloadAction<boolean>) => ({
      ...state, isConfirmOpen: action.payload,
    }),
    openCreateProfessionPopup: (state, action: PayloadAction<boolean>) => ({
      ...state, isProfessionCreatePopupOpen: action.payload,
    }),
    openFolderCreatePopup: (state, action: PayloadAction<boolean>) => ({
      ...state, isCreateFolderModalOpen: action.payload,
    }),
    openFolderRedactPopup: (state, action: PayloadAction<boolean>) => ({
      ...state, isRedactFolderPopupOpen: action.payload,
    }),
    setFolderId: (state, action: PayloadAction<number>) => ({
      ...state, folderId: action.payload,
    }),
    openFolderDeletePopup: (state, action: PayloadAction<boolean>) => ({
      ...state, isDeleteFolderModalOpen: action.payload,
    }),
    setImgesToSlider: (state, action: PayloadAction<string[]>) => ({
      ...state, currentImages: action.payload,
    }),
    openBigPopup: (state, action: PayloadAction<boolean>) => ({
      ...state, isBigPopupOpen: action.payload,
    }),
    openResponsesModal: (state, action: PayloadAction<boolean>) => ({
      ...state, isResponsesModalOpen: action.payload,
    }),
    setIsFullFilled: (state, action: PayloadAction<boolean>) => ({
      ...state, isFullFilled: action.payload,
    }),
    setButtonColour: (state, action: PayloadAction<boolean>) => ({
      ...state, isColoured: action.payload,
    }),
    closeAllDatalists: (state, action: PayloadAction<boolean>) => ({
      ...state, isColoured: action.payload,
    }),
    setTheme: (state, action: PayloadAction<'light' | 'dark'>) => ({
      ...state, theme: action.payload,
    }),
    setDateOfExit: (state, action: PayloadAction<Date>) => ({
      ...state, isExit: action.payload,
    }),
    setAppSuccess: (state, action: PayloadAction<boolean>) => ({
      ...state, isFetchSuccess: action.payload,
    }),
    setAppError: (state, action: PayloadAction<boolean>) => ({
      ...state, isFetchError: action.payload,
    }),
    setDirectionsForFilter: (state, action: PayloadAction<string[]>) => ({
      ...state, filterDirectionsValues: action.payload,
    }),
    setProfessionsForFilter: (state, action: PayloadAction<string[]>) => ({
      ...state, filterProfessionValues: action.payload,
    }),
    setFiltervalue: (state, action: PayloadAction<string[]>) => ({
      ...state, currentFilter: action.payload,
    }),
    setFilteForDates: (state, action: PayloadAction<boolean>) => ({
      ...state, filterNewFirst: action.payload,
    }),
  },
});

const allReducer = allSlice.reducer;
export const {
  setIsFullFilled,
  setButtonColour,
  closeAllDatalists,
  setTheme,
  setType,
  setPublishStatus,
  setDateOfExit,
  setAppSuccess,
  setAppError,
  setFiltervalue,
  openFolderCreatePopup,
  setDirectionsForFilter,
  setProfessionsForFilter,
  setFilteForDates,
  openResponsesModal,
  openBigPopup,
  setImgesToSlider,
  setFolderId,
  openCreateProfessionPopup,
  openFolderRedactPopup,
  openFolderDeletePopup,
  openConfirmPopup,
  openImagePopupOpenInProfileAbout,
  setImageForDiploma,
  setProjectId,
  setProfId,
  setHeadingForPopup,
  openSpecCreatePopup,
  setOverlayLength,
  setRequestUser,
  openContactsForm,
  openFullProjectViewPopup,
  setContactFormType,
  setObjectForForm,
  openResponseToProjectPopup,
  setEmailSendPopup,
  openSpecResumePopup,
  setPairForPopup,
  setChoosePopupOpen,
} = allSlice.actions;

export default allReducer;
