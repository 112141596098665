/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-nested-ternary */
/* eslint-disable ternary/nesting */
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { Toaster } from 'react-hot-toast';
import { SectionHeader } from '../ui-lib/TextBlocks';
import Button from '../ui-lib/Button';
import SectionPlateWithFilter from '../ui-lib/widgets/SectionPlateWithFilter';
import {
  useNewGetCurrentUserQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
} from '../api/api';
import { openCreateProfessionPopup } from '../store/allSlice';
import { makeToast } from '../helpers/promts';
import { useSelector, useDispatch } from '../store/store.types';
import NewPlateForSpec from '../ui-lib/NewPlateForSpec';
import { UniversalButton } from '../ui-lib/RestyledButtons';
import { Skeleton3, Skeleton4 } from '../ui-lib/widgets/Skeleton';
import avatarDefault from '../assets/dedaultAvaImg.png';
import { ComfortaFontMixixn39 } from '../constants/fontsConfigs';
import Themes from '../themes';
import { TUser } from '../types/apiTypes';
import { ErrorForInput } from '../ui-lib/FormElements';
import { professions } from '../constants/textsForLanding';

export const SpecList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    row-gap: 20px;
    column-gap: 20px;
    display: grid;
    grid-template-columns: repeat(2, minmax(340px, 1fr));
    grid-auto-flow: row;
`;

const Header2 = styled.h2`
  color: ${({ theme: { mainButtonColor } }) => mainButtonColor};
  ${ComfortaFontMixixn39}
  margin: 0;
`;

const TeamList: FC = () => {
  const navigate = useNavigate();

  const [curOfset, setOffset] = useState(0);
  const [curLimit, setLimit] = useState(2);
  const [intersect, setIntersecting] = useState<boolean>(false);
  const [localStateProjects, setLocalStateProjects] = useState<TUser[]>([]);
  console.log(localStateProjects);
  const [canGetMore, setCanGetMore] = useState(true);
  const [rightError, setError] = useState(false);
  const [preventStop, setStop] = useState(true);
  const [getUsers, { data, error, isLoading: loading }] = useLazyGetUsersQuery();
  const { data: usrs } = useGetUsersQuery({ offset: 0, limit: 1000 });
  const { data: user } = useNewGetCurrentUserQuery();
  /* const { data: projects, error: projectError } = useGetCurrentUserProjectsQuery(); */
  const { filterProfessionValues, theme, requestUser } = useSelector((state) => state.all);
  const dispatch = useDispatch();
  const filterEachSpecialityfromUser = (ar: TUser[]) => {
    console.log(ar);
    const arr: any = [];
    ar?.forEach((el) => {
      if (el.professions!.length !== 0) {
        el.professions?.forEach((item) => {
          arr.push({ ...el, professions: [item] });
        });
      }
    });
    return arr;
  };
  useEffect(() => {
    makeToast(error, undefined);
  }, [error]);
  const checkUserRights = () => {
    if (user?.age && user?.full_name) {
      dispatch(openCreateProfessionPopup(true));
      setError(false);
    } else {
      setError(true);
    }
  };

  /*  useEffect(() => {
    if (user) {
      (async () => {
        if (canGetMore) {
          const projects = await getUsers({ offset: curOfset, limit: 12 }).unwrap();
          const copy = structuredClone(localStateProjects);

          const newArr = [...localStateProjects, ...projects.results];
          setLocalStateProjects(newArr);
          projects.next === null && setCanGetMore(false);
        }
      })();
    }
  }, [user, data, curOfset]); */

  useEffect(() => {
    (async () => {
      if (curOfset === 0) {
        setLocalStateProjects([]);
      }
      const projects = await getUsers({ offset: curOfset, limit: 12 }).unwrap();
      if (preventStop) {
        setLocalStateProjects([...localStateProjects, ...projects.results]);
      }
      if (projects.next === null) {
        setStop(false);
      }
    })();
  }, [user, data, curOfset]);

  useEffect(() => {
    setStop(true);
    setOffset(0);
  }, [requestUser]);

  useEffect(() => {
    if (intersect) {
      setOffset((prev) => prev + 12);
    }
  }, [intersect]);

  const filterExpertsWithProfessions = (arr: any) => {
    const arr4 = ['ИТ-разработка', 'Упаковка продукта, менеджмент продукта, оптимизация процессов', 'Креативные индустрии, искусство, развлечения', 'Экономика-финансы, аналитика, юридические услуги', 'Специалисты в сфере науки', 'Специалисты техническо-промышленной сферы'];
    const direct1: any[] = [];
    const secArr1: any[] = [];
    filterProfessionValues.forEach((el) => {
      if (arr4.includes(el)) {
        direct1.push(el);
      }
    });
    professions.forEach((elm) => {
      if (direct1.includes(elm.name)) {
        elm.array.forEach((u) => {
          secArr1.push(u);
        });
      }
    });
    return filterEachSpecialityfromUser(arr).filter((el: any) => (filterProfessionValues.length === 0 ? el : [...filterProfessionValues, ...secArr1].includes(el.professions![0].profession_name!)));
  };
  console.log(usrs);
  return (

    <SectionPlateWithFilter withOneSelect path='/'>
      <Toaster position='top-center' />
      <Header2>
        Витрина специалистов

      </Header2>
      <UniversalButton
        onClick={() => checkUserRights()}
        type='button'
        textColor={Themes[theme].mainTextColor}
        backColor={Themes[theme].sliderColor}
        borderColor=''
        paddingLeft={40}
        paddingTop={20}>
        Добавить себя, как специалиста
      </UniversalButton>
      {rightError && <ErrorForInput>Вы не заполнили все данные в личном кабинете</ErrorForInput>}
      {!data && !usrs ? <Skeleton3 />
        /* : data?.length === 0 ? <EmptyDefault /> */
        : (
          <SpecList style={{ alignItems: 'center' }}>
            {filterExpertsWithProfessions(usrs?.results)?.filter((itm:any) => (user?.id === itm.id)).map((el:any) => {
              console.log();
              return (
                <NewPlateForSpec
                  hardSkills={el.hard_skills || []}
                  onClick={() => navigate(`/team-list/${el.professions![0].id}/${el.id}`)}
                  image={el.image || avatarDefault}
                  name={el.full_name || ''}
                  level={el?.professions![0].level || ''}
                  specislity={el.professions![0].profession_name || ''} />
              );
            })}
            {filterExpertsWithProfessions(localStateProjects)?.filter((itm:any) => (user?.id !== itm.id)).map((el:any) => {
              console.log();
              return (
                <NewPlateForSpec
                  setIntersecting={setIntersecting}
                  hardSkills={el.hard_skills || []}
                  onClick={() => navigate(`/team-list/${el.professions![0].id}/${el.id}`)}
                  image={el.image || avatarDefault}
                  name={el.full_name || ''}
                  level={el?.professions![0].level || ''}
                  specislity={el.professions![0].profession_name || ''} />
              );
            })}
          </SpecList>
        )}
    </SectionPlateWithFilter>
  );
};

export default TeamList;

/// onClick={() => navigate(`/team-list/${el.professions![0].id}/${el.id}`)
