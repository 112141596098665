/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import SpecResumeResponses from '../pages/SpecResumeResponses';

const SpecResume = React.lazy(() => import('../pages/SpecResume'));
const SpecResume2 = React.lazy(() => import('../pages/SpecResumeResponses'));
const Profile = React.lazy(() => import('../pages/Profile_v2'));
const ProfileAbout = React.lazy(() => import('../pages/ProfileAbout'));
const MyProjectsList = React.lazy(() => import('../pages/MyProjectsList'));
const WindowList = React.lazy(() => import('../pages/WindowList'));
const TeamList = React.lazy(() => import('../pages/TeamList'));
const ProjectResponses = React.lazy(() => import('../pages/ProjectResponses'));
const ProjectFullShow = React.lazy(() => import('../pages/ProjectFullShow/ProjectFullShowLogic'));
const CreateLogic = React.lazy(() => import('../pages/CreateProject/CreateProjectLogic'));
const MyResponses = React.lazy(() => import('../pages/MyResponses'));
const ExpertResponses = React.lazy(() => import('../pages/ExpertsResponses'));

export const routes = [
  { path: '/profile', Component: <Profile /> },
  { path: '/proj', Component: <CreateLogic /> },
  { path: '/team-list/:profId/:userId', Component: <SpecResume /> },
  { path: '/responses-experts/:profId/:userId', Component: <SpecResume /> },
  { path: '/my-responses/:profId/:userId', Component: <SpecResumeResponses /> },
  { path: '/profile-info', Component: <ProfileAbout /> },
  { path: '/profile/:id', Component: <Profile /> },
  { path: '/projects', Component: <MyProjectsList /> },
  { path: '/projects/:id', Component: <ProjectFullShow /> },
  { path: '/projects/createProject', Component: <CreateLogic /> },
  { path: '/:id', Component: <CreateLogic /> },
  { path: '/responses-projects', Component: <ProjectResponses /> },
  { path: '/my-responses', Component: <MyResponses /> },
  { path: 'responses-experts', Component: <ExpertResponses /> },
];

export const navRoutes = [
  { path: 'projects', name: 'Мои проекты' },
  { path: 'all-projects', name: 'Витрина проектов' },
  { path: 'team-window', name: 'Витрина специалистов' },
  { path: 'responses', name: 'Отклики' },
];

/* { path: '/projects-window', Component: <WindowList /> } */
/* { path: '/team-list', Component: <TeamList /> },, */
