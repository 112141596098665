/* eslint-disable no-constant-condition */
/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable import/prefer-default-export */
import React, {
  ChangeEvent, FC, memo, useEffect, useRef, useState,
} from 'react';
import {
  FieldValues,
  UseFormRegister,
  Controller,
} from 'react-hook-form';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from '../helpers/dateFunc';
import { TSkill } from '../types/apiTypes';

import {
  ArrowIconNew,
  ShowIcon,
  HideIcon,
  BlackDelIcon,
  PushPic,
  TgIcon,
  GitIcon,
  VkIcon,
  ExtraLinkIcon,
  ArrowIcon,
} from './icons';
import {
  TravelsFontMixixn15,
  TravelsFontMixixn20,
  TravelsFontMixixn24,
  ComfortaFontMixixn31,
  ComfortaFontMixixn39,
} from '../constants/fontsConfigs';
import { ButtonWithCross } from './RestyledButtons';

export const Label = styled.label`
    width: fit-content;
    display: flex;
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
    ${TravelsFontMixixn20}
    position: relative;
`;

export const RequiredSpan = styled.span`
  color: rgb(255, 0, 0);
`;

export const Input = styled.input<{ notValid?: boolean }>`
    padding: 16px 15px;
    padding-right: 30px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
    border: ${({ notValid, theme: { errorColor } }) => (notValid ? `1px solid ${errorColor}` : 'none')};
    ${TravelsFontMixixn20}
    background-color: ${({ theme: { plateColor } }) => plateColor};
    & ::placeholder {
       ${TravelsFontMixixn20} 
      color:  ${({ theme: { placeholderColor } }) => placeholderColor};
    }
`;

export const CustomMaskedInput = styled(MaskedInput)<{ notValid?: boolean }>`
   padding: 16px 15px;
    padding-right: 30px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
    border: ${({ notValid, theme: { errorColor } }) => (notValid ? `1px solid ${errorColor}` : 'none')};
    ${TravelsFontMixixn20}
    background-color: ${({ theme: { plateColor } }) => plateColor};
    & ::placeholder {
       ${TravelsFontMixixn20} 
      color:  ${({ theme: { placeholderColor } }) => placeholderColor};
    }
`;

export const CustomDateInput = styled(MaskedInput) <{ notValid?: boolean }>`
    padding: 16px 15px;
    padding-right: 30px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
    border: ${({ notValid, theme: { errorColor } }) => (notValid ? `1px solid ${errorColor}` : 'none')};
    ${TravelsFontMixixn20}
    background-color: ${({ theme: { plateColor } }) => plateColor};
    & ::placeholder {
      ${TravelsFontMixixn20} 
      color:  ${({ theme: { placeholderColor } }) => placeholderColor};
    }
`;
export const ErrorForInput = styled.p`
    color:  ${({ theme: { errorColor } }) => errorColor};
    ${TravelsFontMixixn15}
    font-weight: 400;
    margin: 0;
`;
export const FieldSet = styled.fieldset`
    border: none;
    margin: none;
    padding: none;
    display: flex;
    gap: 25px;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    & legend {
      margin-bottom: 25px;
    }
`;

export const Legend = styled.legend`
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
    ${TravelsFontMixixn24}
    position: relative;
`;

const SmallLegend = styled.legend`
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
    ${TravelsFontMixixn20}
    position: relative;
    grid-column: -1/1;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    position: relative;
`;

export const InputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;

export const ExtendedBlockHeader: FC<{
  isOpen: boolean,
  color: string,
  width: number,
  fontConfig: any,
  openHiddenBlock: () => void,
  text: string,
}> = ({
  isOpen,
  color,
  width,
  fontConfig,
  openHiddenBlock,
  text,
}) => (
  <Wrapper style={{ cursor: 'pointer' }} onClick={openHiddenBlock}>
    {/* <P fontConfig={fontConfig} color={color}>{text}</P> */}
    <ArrowIconNew isOpen={isOpen} color={color} width={width} />
  </Wrapper>
);

const TextArea = styled.textarea<{ notValid?: boolean, height: number }>`
    padding: 16px 15px;
    width: 100%;
    min-height: ${({ height }) => height}px;
    border: none;
    resize: none;
    border-radius: 7px;
    outline: none;
    box-sizing: border-box;
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
    ${TravelsFontMixixn20}  
    border: ${({ notValid, theme: { errorColor } }) => (notValid ? `1px solid ${errorColor}` : 'none')};
    background-color: ${({ theme: { plateColor } }) => plateColor};
    & ::placeholder {
      ${TravelsFontMixixn20}  
      color:  ${({ theme: { placeholderColor } }) => placeholderColor};
    }
`;
const IconsWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 60px;
  right: 8px;
  cursor: pointer;
  z-index: 9999999;
`;
export const DateInput: FC<{
  forRequired?: boolean,
  register: UseFormRegister<FieldValues>,
  name: string,
  control: any,
  onBlure?: any,
  label: string,
  options: { [key: string]: any },
  errorMessage: string,
  mask: any,
  placeholder?: string,
}> = memo(({
  forRequired = false,
  register,
  name,
  label,
  options,
  mask,
  onBlure,
  errorMessage,
  control,
  placeholder = '',
}) => {
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy', { minYear: 1970, maxYear: new Date().getFullYear() });
  return (
    <InputBox>
      <Label htmlFor={name}>
        {label}
        {forRequired && <RequiredSpan>*</RequiredSpan>}
      </Label>
      <Controller
        control={control}
        name={name}
        rules={{
          required: 'Заполните поле',
          minLength: {
            value: 10,
            message: 'Данные введены не полностью',
          },
        }}
        render={({
          field: {
            onChange, onBlur, value, ref,
          },
        }) => (
          <CustomDateInput
            onChange={onChange}
            onBlur={onBlure}
            value={value}
            mask={mask}
            pipe={autoCorrectedDatePipe}
            guide={false}
            notValid={!!errorMessage}
            placeholder={placeholder}
            id={name} />
        )} />
      {errorMessage && <ErrorForInput>{errorMessage}</ErrorForInput>}
    </InputBox>
  );
});

export const BasicInput: FC<{
  forRequired?: boolean,
  register: UseFormRegister<FieldValues>,
  name: string,
  label: string,
  options: { [key: string]: any },
  errorMessage: string,
  maxLength?: number,
  type: string,
  value?: string,
  placeholder?: string,
  autocomplete?: string,
}> = memo(({
  forRequired = false,
  register,
  name,
  label,
  options,
  errorMessage,
  maxLength = 10000,
  type,
  value,
  autocomplete = 'off',
  placeholder = '',
}) => {
  const [inputType, changeType] = useState<string>('password');

  return (
    <InputBox>
      <Label htmlFor={name}>
        {label}
        {forRequired && <RequiredSpan>*</RequiredSpan>}
      </Label>
      {type === 'password'
        && (
          <IconsWrapper onClick={() => changeType((prevState) => (prevState === 'password' ? 'text' : 'password'))}>
            {inputType === 'password' ? <ShowIcon /> : <HideIcon />}
          </IconsWrapper>
        )}
      <Input
        autoComplete={autocomplete}
        notValid={!!errorMessage}
        placeholder={placeholder}
        type={type === 'password' ? inputType : type}
        maxLength={maxLength}
        id={name}
        {...register(name, options)} />
      {errorMessage && <ErrorForInput>{errorMessage}</ErrorForInput>}
    </InputBox>
  );
});
/* <RequiredStarIcon /> */
const GridFieldset = styled.fieldset`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto 1fr;
  gap: 25px;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  & svg {
    align-self: center;
    cursor: auto;
  }
`;

const GridError = styled(ErrorForInput)`
  grid-column: -1/1;
`;

export const SocialsInputs: FC<{
  isErrorFlag: boolean,
  vk: string,
  tg: string,
  git: string,
  link: string,
  vkError?: any,
  gitError?: any,
  tgError?: any,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}> = memo(({
  vk,
  tg,
  git,
  link,
  onChange,
  isErrorFlag,
  vkError = false,
  gitError = false,
  tgError = false,
}) => {
  const [openRules, setOpenRule] = useState({ vk: false, ok: false });

  return (
    <GridFieldset>
      <SmallLegend style={{ marginBottom: '25px' }}>
        Социальные сети
        <RequiredSpan>*</RequiredSpan>

      </SmallLegend>
      {isErrorFlag && <GridError>Укажите хотя бы одну социальную сеть</GridError>}

      <VkIcon />

      <Input
        placeholder='id0000001'
        notValid={!!vkError}
        type='text'
        value={vk}
        maxLength={100}
        name='vk'
        onChange={(e) => onChange(e)}
        id='vk' />

      {vkError && <GridError style={{ paddingLeft: '65px' }}>Некорректное значение</GridError>}

      <TgIcon />

      <Input
        placeholder='@proecteam'
        notValid={!!tgError}
        type='text'
        maxLength={100}
        name='tg'
        value={tg}
        onChange={(e) => onChange(e)}
        id='tg' />
      {tgError && <GridError style={{ paddingLeft: '65px' }}>Некорректное значение</GridError>}

      <GitIcon />

      <Input
        placeholder='https://github.com/'
        notValid={!!gitError}
        type='text'
        maxLength={100}
        value={git}
        name='git'
        onChange={(e) => onChange(e)}
        id='git' />

      {gitError && <GridError style={{ paddingLeft: '65px' }}>Некорректное значение</GridError>}

      <ExtraLinkIcon />
      <Input
        placeholder='https://example.com'
        notValid={!!gitError}
        type='text'
        maxLength={100}
        value={link}
        name='link'
        onChange={(e) => onChange(e)}
        id='link' />

    </GridFieldset>
  );
});

export const InputWithNoValidation: FC<{
  name: string,
  label: string,
  maxLength?: number,
  type: string,
  index?: number,
  value?: string,
  onChange: (e: ChangeEvent<HTMLInputElement>, index?: number) => void;
  placeholder?: string,
}> = memo(({
  name,
  label,
  onChange,
  maxLength,
  type,
  value,
  index,
  placeholder,
}) => (
  <InputBox>
    <Label htmlFor={name}>
      {label}
    </Label>
    <Input
      onChange={(e) => onChange(e, index)}
      value={value}
      placeholder={placeholder}
      type={type}
      maxLength={maxLength}
      name={name}
      id={name} />
  </InputBox>
));

export const BasicTextArea: FC<{
  forRequired?: boolean,
  register: UseFormRegister<FieldValues>,
  name: string,
  label: string,
  options: { [key: string]: any },
  errorMessage: string,
  maxLength?: number,
  value?: string,
  placeholder?: string,
  height: number,
}> = memo(({
  forRequired = false,
  register,
  name,
  label,
  options,
  errorMessage,
  maxLength = 10000,
  value,
  placeholder = '',
  height,
}) => (
  <InputBox>
    <Label htmlFor={name}>
      {label}
      {forRequired && <RequiredSpan>*</RequiredSpan>}
    </Label>
    <TextArea
      height={height}
      notValid={!!errorMessage}
      placeholder={placeholder}
      maxLength={maxLength}
      id={name}
      {...register(name, options)} />
    {errorMessage && <ErrorForInput>{errorMessage}</ErrorForInput>}
  </InputBox>
));
/// with no hook form
export const TextAreaWithNoValidation: FC<{
  name: string,
  label: string,
  maxLength?: number,
  minLength?: number,
  value?: string,
  placeholder?: string,
  errorMessage?: boolean,
  height: number,
  currentIndex?: number,
  forRequired?: boolean,
  onChange: (e: ChangeEvent<HTMLTextAreaElement>, index?: number) => void,
}> = memo(({
  name,
  label,
  minLength = 0,
  errorMessage = false,
  maxLength = 10000,
  value,
  placeholder = '',
  height,
  onChange,
  forRequired = false,
  currentIndex,
}) => (
  <InputBox>
    <Label htmlFor={name}>
      {label}
      {forRequired && <RequiredSpan>*</RequiredSpan>}
    </Label>
    <TextArea
      notValid={errorMessage}
      onChange={(e) => onChange(e, currentIndex)}
      value={value}
      minLength={minLength}
      height={height}
      name={name}
      placeholder={placeholder}
      maxLength={maxLength}
      id={name} />
    {errorMessage && <ErrorForInput>Значение должно содержать от 10 букв</ErrorForInput>}
  </InputBox>
));

export const RoundTagBlock = styled.li`
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
  border-radius: 100px;
  background-color: ${({ theme: { sliderColor } }) => sliderColor};
  ${TravelsFontMixixn20}
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  word-break: break-all;
  flex-shrink: 0;
  `;

export const TagBox = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: auto;
  gap: 20px;
  position: relative;
  scrollbar-width: none;
`;

const CustomTagBox = styled(TagBox)`
display: flex;
flex-wrap: wrap;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 26px;
    height: 100%;
    z-index: 99999999;
    box-shadow: inset -38px -8px 15px -15px rgb(251, 252, 252);
  }
`;
const PushButton = styled.button`
  border: none;
  outline: none;
  background-color: ${({ theme: { sliderColor } }) => sliderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 7px;
  height: 56px;
  max-width: 120px;
  width: 100%;
  align-self: flex-start;
`;

const ListOfSkills = styled.ul<{ isOpen: boolean }>`
    padding: 16px 24px;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    flex-direction: column;
    gap: 16px;
    transition: opacity ease .4s;
    background-color: ${({ isOpen, theme: { plateColor, focusColor } }) => plateColor};
    box-shadow: ${({ isOpen }) => (isOpen ? '0px 8px 20px 0px rgba(0, 0, 0, 0.25)' : 'none')};
    overflow: auto;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    z-index: 0;
    list-style: none;
    margin: 0;
    margin-top: -20px;
    width: 100%;
    max-height: 500px;
    box-sizing: border-box;
    overscroll-behavior: contain;
`;

const ListItem = styled.li`
     ${TravelsFontMixixn20}
     color:  ${({ theme: { mainTextColor } }) => mainTextColor};
     display: flex;
     align-items: center;
     gap: 21px;
     cursor: pointer;
     align-items: flex-start;
     align-items: center;
     &:hover {
       color: ${({ theme: { mainButtonColor } }) => mainButtonColor};
  }
`;

const SpecialityInput = styled.input<{ isOpen: boolean, withError: boolean }>`
    padding: 16px 15px;
    padding-right: 40px;
    width: 100%;
    border: ${({ withError, theme: { errorColor } }) => (withError ? `1px solid ${errorColor}` : 'none')};
    border-radius: 7px;
    outline: none;
    z-index: 5;
    box-sizing: border-box;
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
    box-shadow: ${({ isOpen }) => (isOpen ? '0px 8px 20px 0px rgba(0, 0, 0, 0.25)' : 'none')};
    ${TravelsFontMixixn20}
    background-color: ${({ isOpen, theme: { plateColor, focusColor } }) => (isOpen ? focusColor : plateColor)};
    & ::placeholder {
      ${TravelsFontMixixn20}
      color:  ${({ theme: { placeholderColor } }) => placeholderColor};
    }

`;

const LeftToggle = styled.div`
  left: 0;
  top: 31px;
  position: absolute;
  background-color: transparent;
  width: 30px;
  z-index: 9999999999999;
  height: 54px;
`;
const RightToggle = styled.div`
  right: 0;
  top: 27px;
  position: absolute;
  background-color: transparent;
  width: 30px;
  height: 54px;
  z-index: 999999999999;
 
`;
const IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 1%;
  z-index: 999999;
`;
const InvisibleList = styled.ul`
  height: fit-content;
  background-color: ${({ theme: { plateColor, focusColor } }) => plateColor};
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 20px;
  border: ${({ theme: { mainButtonColor } }) => `${mainButtonColor} 1px solid`};
  position: absolute;
  padding: 20px;
    z-index: 999999999999;
    top: 94px;
    box-sizing: border-box;
    overflow: hidden;
    transition: all ease .3s;
`;
export const InputWithTags: FC<{
  tags: TSkill[];
  downloadedValues?: TSkill[],
  currentObjectIndex?: number,
  setTagToArray: (item: TSkill) => void;
  savedHardFromLk?: TSkill[],
  savedSoftFromLk?: TSkill[],
  label: string;
  name: string;
  withButton?: boolean;
  forRequired: boolean;
  deleteTagFromArray: (tagId: number, index: number) => void;
  placeholder: string;
  type: string;
  maxLength: number;
  hasError: boolean;
  setValueToRequest: (item: string) => void;
}> = memo(({
  tags,
  setTagToArray,
  deleteTagFromArray,
  downloadedValues = [],
  setValueToRequest,
  savedSoftFromLk = undefined,
  savedHardFromLk = undefined,
  label,
  hasError,
  withButton = false,
  name,
  forRequired,
  placeholder,
  type,
  currentObjectIndex = 0,
  maxLength,
}) => {
  const [isListOpen, openList] = useState(false);
  const [value, setValue] = useState<string>('');
  const [isHovered, setHover] = useState(false);
  const ref = useRef<HTMLUListElement>(null);
  const timer = useRef<string | number | NodeJS.Timeout | undefined>();
  const closeList = (e: any) => {
    if (e.target.id === 'inputbox' || e.target.id === 'toggle') { return; }
    openList(false);
  };
  useEffect(() => {
    document.addEventListener('click', closeList);
    return () => {
      document.removeEventListener('click', closeList);
    };
  }, []);
  /*  const infiniteScroll = (position: number) => {
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      ref.current?.scrollBy({
        left: position,
        behavior: 'smooth',
      });
    }, 100);
  }; */
  return (
    <InputBox id='inputbox'>
      <Label htmlFor={name}>
        {label}
        {forRequired && <RequiredSpan>*</RequiredSpan>}
      </Label>
      {/* <LeftToggle
        onMouseOver={() => infiniteScroll(-10)}
        onMouseOut={() => clearInterval(timer.current)} /> */}
      <CustomTagBox ref={ref}>

        {tags?.map((el, index) => (
          <RoundTagBlock>
            {el.name}
            <BlackDelIcon onClick={() => deleteTagFromArray(index, currentObjectIndex)} />
          </RoundTagBlock>
        ))}

      </CustomTagBox>
      {/*  <InvisibleList>
        {tags?.map((el, index) => (
          <RoundTagBlock>
            {el.name}
          </RoundTagBlock>
        ))}
      </InvisibleList> */}
      {/* <RightToggle
        onMouseOut={() => clearInterval(timer.current)}
        onMouseOver={() => infiniteScroll(10)} /> */}
      <Wrapper>
        <Wrapper style={{ flexDirection: 'column' }}>
          <SpecialityInput
            withError={hasError}
            isOpen={(downloadedValues?.length > 0 || savedSoftFromLk?.length! > 0 || savedHardFromLk?.length! > 0) && isListOpen}
            onChange={(e) => { setValueToRequest(e.target.value); openList(true); setValue(e.target.value); }}
            placeholder={placeholder}
            type={type}
            value={value}
            maxLength={maxLength}
            id={name} />
          {(savedSoftFromLk! || savedHardFromLk!)?.length > 0 && (
          <IconWrapper style={{ zIndex: '9' }} id='toggle' onClick={(e: any) => { openList(true); }}>
            <ArrowIcon id='toggle' isOpen={isListOpen} />
          </IconWrapper>
          )}
          <ListOfSkills isOpen={(downloadedValues?.length > 0 || savedSoftFromLk?.length! > 0 || savedHardFromLk?.length! > 0) && isListOpen}>
            {(downloadedValues?.length === 0 ? (savedSoftFromLk || savedHardFromLk) : downloadedValues)?.map((el) => (
              <ListItem onClick={() => { setTagToArray(el); openList(false); setValue(''); }}>{el.name}</ListItem>
            ))}
          </ListOfSkills>
        </Wrapper>
        {withButton
          && (
            <PushButton type='button' onClick={() => { setTagToArray({ name: value, id: 9999999 }); setValue(''); }}>
              <PushPic />
            </PushButton>
          )}
      </Wrapper>

      {hasError && <ErrorForInput>Укажите хотя бы один навык</ErrorForInput>}
    </InputBox>
  );
});
