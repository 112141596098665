import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { SmsTrackIcon } from '../icons';
import {
  TravelsFontMixixn24,
  TravelsFontMixixn20,

} from '../../constants/fontsConfigs';
import { UniversalButton } from '../RestyledButtons';
import Themes from '../../themes';
import { BasicInput, CustomMaskedInput } from '../FormElements';
import { useSelector, useDispatch } from '../../store/store.types';
import { openConfirmPopup, setEmailSendPopup } from '../../store/allSlice';
import { useResetPasswordMutation } from '../../api/api';

const Form = styled.form`
  padding: 31px 55px;
  border-radius: 20px;
  background-color: ${({ theme: { mainBg } }) => mainBg};
  border: ${({ theme: { mainTextColor } }) => `1px solid ${mainTextColor}`};
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 780px;
  width: 100%;
  align-items: start;
  box-sizing: border-box;
  & button {
    align-self: center;
  }
`;

const Label = styled.label`
  ${TravelsFontMixixn24}
  color: ${({ theme: { mainTextColor } }) => `1ox solid ${mainTextColor}`};

`;

const P = styled.p`
  ${TravelsFontMixixn20};
  color: ${({ theme: { mainTextColor } }) => `1ox solid ${mainTextColor}`};
`;

const EmailSendPopup: FC = () => {
  const [code, setCode] = useState<number | string>('');
  const { theme } = useSelector((state) => state.all);
  const dispatch = useDispatch();
  const [res] = useResetPasswordMutation();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    control,
  } = useForm();

  const submitForm = async (data:any) => {
    await res({ email: data.email! });
    toast.success('Вам на почту отправлена ссылка для сброса пароля');
    dispatch(setEmailSendPopup(false));
  };

  return (
    <Form onSubmit={handleSubmit(submitForm)} data-cy='regConfirm'>
      <SmsTrackIcon style={{ alignSelf: 'center' }} />
      <Label htmlFor='submit'>Подтвердите Email</Label>
      <P>
        Для сброса пароля укажите вашу почту.
        <br />
        {' '}
        На нее будет отправлена ссылка для сброса пароля.
      </P>
      <BasicInput
        forRequired
        name='email'
        type='text'
        label='Ваш Email'
        errorMessage={errors?.full_name?.message as string}
        options={{
          required: 'Заполните поле',
          minLength: {
            value: 2,
            message: 'Данные введены не полностью',
          },
          maxLength: {
            value: 105,
            message: 'Превышен лимит символов. Максимум 100 знаков',
          },
          pattern: {
            value: /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,5})$/,
            message: 'Некорректное значение',
          },
        }}
        register={register} />
      <UniversalButton
        type='submit'
        textColor={Themes[theme].mainTextColor}
        backColor={Themes[theme].sliderColor}
        borderColor=''
        paddingLeft={44}
        paddingTop={20}>
        Отправить
      </UniversalButton>
    </Form>
  );
};

export default EmailSendPopup;
