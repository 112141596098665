/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  API_ROOT,
  LOGIN_USER,
  USER,
  GET_USER,
  ALL_USERS,
  GET_ALL_PROJECTS,
  GET_ALL_EXPERTS,
} from '../constants/urls';
import {
  TUser,
  TUserCreation,
  TUserLogin,
  TEducation,
  TProfession,
  TTest,
  TAchievementNew,
  TSkill,
  TOrganizationForEducaiton,
  TUserForUpdate,
  TSoftEducation,
  TNewProject,
  TUniversalResponseType,
  TProffesionForUpdate,
  MyResponsesToProjects,
  MySpecsInvitedToMyProject,
  Stuff,
  InvitesOfMeToProject,
  UsersWhoWantToMyProjects,
} from '../types/apiTypes';

/// ///

export const jwt = {
  set: (value: string): void => {
    if (value) {
      localStorage.setItem('JWT', `${value}`);
    } else {
      localStorage.removeItem('JWT');
    }
  },
  get: (): string => {
    const res = localStorage.getItem('JWT');
    return res || '';
  },
  test: (): boolean => !!localStorage.getItem('JWT'),
  remove: (): void => localStorage.removeItem('JWT'),
};

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['user'],
  endpoints: (build) => ({
    register: build.mutation<TUserCreation, TUserCreation>({
      query: (body) => ({
        url: USER,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['user'],
    }),
    loginUser: build.mutation<{ auth_token: string }, TUserLogin>({
      query: (body) => ({
        url: LOGIN_USER,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['user'],
    }),
    activateUser: build.mutation<void, { uid: string, token: string }>({
      query: (body) => ({
        url: '/api/users/activation/',
        method: 'POST',
        body,
      }),
    }),
    newGetCurrentUser: build.query<TUser, void>({
      query: () => ({
        url: GET_USER,
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },

      }),
      providesTags: ['user'],
    }),
    getUsers: build.query<TUniversalResponseType<TUser>, { offset: number, limit: number }>({
      query: ({ offset, limit }) => ({
        url: `api/users/?offset=${offset}&limit=${limit}`,
        method: 'GET',
        /*  headers: {
          Authorization: `Token ${jwt.get()}`,
        }, */

      }),
      providesTags: ['user'],
    }),
    newGetUserById: build.query<TUser, number>({
      query: (body) => ({
        url: `/api/users/${body}/`,
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },

      }),
      providesTags: ['user'],
    }),

    /// all education
    createEducation: build.mutation<TEducation, { eduObj: TEducation, userId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/educations/`,
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: body.eduObj,
      }),
      invalidatesTags: ['user'],
    }),
    updateEducation: build.mutation<TEducation, { eduObj: TEducation, userId: number, eduId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/educations/${body.eduId}/`,
        method: 'PATCH',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: body.eduObj,
      }),
    }),
    deleteEducation: build.mutation<void, { userId: number, eduId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/educations/${body.eduId}/`,
        method: 'DELETE',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },

      }),
    }),
    /// all soft education
    createSoftEducation: build.mutation<TSoftEducation, { eduObj: TSoftEducation, userId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/soft_skills_edu/`,
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: body.eduObj,
      }),
      invalidatesTags: ['user'],
    }),
    updateSoftEducation: build.mutation<TSoftEducation, { eduObj: TSoftEducation, userId: number, eduId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/soft_skills_edu/${body.eduId}/`,
        method: 'PATCH',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: body.eduObj,
      }),
    }),
    deleteSoftEducation: build.mutation<void, { userId: number, eduId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/soft_skills_edu/${body.eduId}/`,
        method: 'DELETE',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },

      }),
    }),
    /// all tests
    createTest: build.mutation<TTest, { eduObj: TTest, userId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/tests/`,
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: body.eduObj,
      }),
      invalidatesTags: ['user'],
    }),
    updateTest: build.mutation<TTest, { eduObj: TTest, userId: number, eduId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/tests/${body.eduId}/`,
        method: 'PATCH',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: body.eduObj,
      }),
      invalidatesTags: ['user'],
    }),
    deleteTest: build.mutation<void, { userId: number, eduId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/tests/${body.eduId}/`,
        method: 'DELETE',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },

      }),
    }),
    /// all prof
    addProfession: build.mutation<TProfession, { prof: TProffesionForUpdate, userId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/professions/`,
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: body.prof,
      }),
      invalidatesTags: ['user'],
    }),
    updateProfession: build.mutation<TProfession, { prof: TProffesionForUpdate, userId: number, professionId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/professions/${body.professionId}/`,
        method: 'PATCH',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: body.prof,
      }),
      invalidatesTags: ['user'],
    }),
    getProfessionWithId: build.query<TProfession, { userId: number, profId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/professions/${body.profId}/`,
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },

      }),
      providesTags: ['user'],
    }),
    deleteProfession: build.mutation<void, { userId: number, professionId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/professions/${body.professionId}/`,
        method: 'DELETE',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      invalidatesTags: ['user'],
    }),
    // all achieves
    createAchieve: build.mutation<TAchievementNew, { achieve: TAchievementNew, userId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/achievements/`,
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: body.achieve,
      }),
      invalidatesTags: ['user'],
    }),
    updateAchieve: build.mutation<TAchievementNew, { achieve: TAchievementNew, userId: number, achieveId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/achievements/${body.achieveId}/`,
        method: 'PATCH',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: body.achieve,
      }),
      invalidatesTags: ['user'],
    }),
    deleteAchieve: build.mutation<void, { userId: number, achieveId: number }>({
      query: (body) => ({
        url: `/api/users/${body.userId}/achievements/${body.achieveId}/`,
        method: 'DELETE',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      invalidatesTags: ['user'],
    }),
    /// get organizations
    getOrganizations: build.query<TOrganizationForEducaiton[], string>({
      query: (name) => ({
        url: `/api/organization-names?name=${name}`,
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
    }),
    createOrganization: build.mutation<{ name: string, id: number }, { name: string }>({
      query: (body) => ({
        url: '/api/organization-names/',
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body,
      }),
      invalidatesTags: ['user'],
    }),
    getAllExperts: build.query<TUser[], void>({
      query: () => ({
        url: GET_ALL_EXPERTS,
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },

      }),
      providesTags: ['user'],
    }),
    changePassword: build.mutation<any, { oldPassword: string, newPassword: string }>({
      query: ({ newPassword, oldPassword }) => ({
        url: '/api/users/set_password/',
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: {
          new_password: newPassword,
          re_new_password: newPassword,
          current_password: oldPassword,
        },
      }),
    }),
    resetPassword: build.mutation<any, { email: string }>({
      query: (body) => ({
        url: '/api/users/reset_password/',
        method: 'POST',
        body,
      }),
    }),
    setNewPassword: build.mutation<any, { new_password: string, uid:string, token:string }>({
      query: (body) => ({
        url: '/api/users/reset_password_confirm/',
        method: 'POST',
        body,
      }),
    }),
    updateUser: build.mutation<TUser, TUserForUpdate>({
      query: (body) => ({
        url: GET_USER,
        method: 'PATCH',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body,
      }),
      invalidatesTags: ['user'],
    }),
  }),
});

export const skillsApi = createApi({
  reducerPath: 'skillsApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['skills'],
  endpoints: (build) => ({
    getSoftSkills: build.query<TSkill[], string>({
      query: (skill) => ({
        url: `/api/softskills?name=${skill}`,
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      providesTags: ['skills'],
    }),
    getHardSkills: build.query<TSkill[], string>({
      query: (skill) => ({
        url: `/api/hardskills?name=${skill}`,
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      providesTags: ['skills'],
    }),
    createHardSkill: build.mutation<{ name: string, id: number }, { name: string }>({
      query: (body) => ({
        url: '/api/hardskills/',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${jwt.get()}`,
        },
        body,
      }),
    }),
  }),
});

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['project'],
  endpoints: (build) => ({
    createProject: build.mutation<TNewProject, { project: TNewProject, userId: number }>({
      query: ({ project, userId }) => ({
        url: 'api/projects/',
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: project,
      }),
      invalidatesTags: ['project'],
    }),

    updateProject: build.mutation<TNewProject, { project: any /* TNewProject */, userId: number, projectId: number }>({
      query: ({ userId, projectId, project }) => ({
        url: `api/projects/${projectId}/`,
        method: 'PATCH',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: project,
      }),
      invalidatesTags: ['project'],
    }),
    pubishProject: build.mutation<any, number>({
      query: (projectId) => ({
        url: `api/projects/${projectId}/publish-project/`,
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      invalidatesTags: ['project'],
    }),
    unPublishProject: build.mutation<any, number>({
      query: (projectId) => ({
        url: `api/projects/${projectId}/publish-project/`,
        method: 'PUT',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      invalidatesTags: ['project'],
    }),
    newGetAllProjects: build.query<TNewProject[], void>({
      query: () => ({
        url: GET_ALL_PROJECTS,
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },

      }),
      providesTags: ['project'],
    }),
    getAllPublishedProjects: build.query<TUniversalResponseType<TNewProject>, { offset: number, limit: number }>({
      query: ({ offset, limit }) => ({
        url: `api/projects?is_published=True&offset=${offset}&limit=${limit}`,
        method: 'GET',
        /*  headers: {
          Authorization: `Token ${jwt.get()}`,
        }, */

      }),
      providesTags: ['project'],
    }),
    getCurrentUserProjects: build.query<TUniversalResponseType<TNewProject>, { offset: number, limit: number }>({
      query: ({ offset, limit }) => ({
        url: `api/my-projects/?offset=${offset}&limit=${limit}`,
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      providesTags: ['project'],
    }),
    getUserProjectById: build.query<TNewProject, number>({
      query: (projectId) => ({
        url: `api/projects/${projectId}/`,
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },

      }),
      providesTags: ['project'],
    }),
    deleteProject: build.mutation<any, { userId: number, projectId: number }>({
      query: ({ userId, projectId }) => ({
        url: `api/projects/${projectId}/`,
        method: 'DELETE',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },

      }),
      invalidatesTags: ['project'],
    }),
    /// отдаю обьект { projectId: number }
    respondToProject: build.mutation<any, number>({
      query: (projectId) => ({
        url: `api/projects/${projectId}/responses/`,
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      invalidatesTags: ['project'],
    }),
    getBackProjectResponse: build.mutation<any, number>({
      query: (projectId) => ({
        url: `api/projects/${projectId}/responses/`,
        method: 'DELETE',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      invalidatesTags: ['project'],
    }),
    /// приглашения на проект
    newInviteToProject: build.mutation<any, { userId: number, projectId: number }>({
      query: (body) => ({
        url: `api/${body.projectId}/${body.userId}/invitation/`,
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      invalidatesTags: ['project'],
    }),
    getBackUserInvitation: build.mutation<any, { userId: number, projectId: number }>({
      query: (body) => ({
        url: `api/${body.projectId}/${body.userId}/invitation/`,
        method: 'DELETE',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      invalidatesTags: ['project'],
    }),
    getAllInvitedProjects: build.query<InvitesOfMeToProject[], void>({
      query: () => ({
        url: 'api/my-invitations/',
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },

      }),
    }),
    getAllUserResponses: build.query<TNewProject[], void>({
      query: () => ({
        url: 'api/my-responses/',
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },

      }),
      providesTags: ['project'],
    }),
  }),
});

export const inviteApi = createApi({
  reducerPath: 'inviteApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['invites'],
  endpoints: (build) => ({
    /// списка моих откликов на проекты
    getMyResponsesToproject: build.query<MyResponsesToProjects[], void>({
      query: () => ({
        url: '/api/my-responses',
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      providesTags: ['invites'],
    }),
    /// приглашенных специалистов на проект.
    getSpecsIinviteToMyProjects: build.query<MySpecsInvitedToMyProject[], void>({
      query: () => ({
        url: '/api/invited-specialist/',
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      providesTags: ['invites'],
    }),
    /// я откликаюсь на проекте ?
    iResponseToProject: build.mutation<void, { userId: number, projectId: number }>({
      query: ({ userId, projectId }) => ({
        url: `/api/${userId}/${projectId}/responses/`,
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: {
          status: 'pending',
          user: userId,
          project: projectId,
        },
      }),
      invalidatesTags: ['invites'],
    }),
    iGetResponseToProjectBack: build.mutation<void, { userId: number, projectId: number }>({
      query: ({ userId, projectId }) => ({
        url: `/api/${userId}/${projectId}/responses/`,
        method: 'DELETE',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      invalidatesTags: ['invites'],
    }),
    /// я приглашаю юзера
    iInviteUserToProject: build.mutation<void, { userId: number, id: number, stuff: Stuff }>({
      query: ({ id, stuff, userId }) => ({
        url: `/api/${id}/${userId}/invitation/`,
        method: 'POST',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: stuff,
      }),
      invalidatesTags: ['invites'],
    }),
    deleteIInviteUserToProject: build.mutation<void, { userId: number, id: number }>({
      query: ({ id, userId }) => ({
        url: `/api/${id}/${userId}/invitation/`,
        method: 'DELETE',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      invalidatesTags: ['invites'],
    }),
    /// приглашенный юзер соглашается на проект
    agreeTooriject: build.mutation<void, { userId: number, id: number }>({
      query: ({ id, userId }) => ({
        url: `/api/${id}/${userId}/invitation/`,
        method: 'PATCH',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: {
          user: userId,
          project: id,
          status: 'accepted',
        },
      }),
      invalidatesTags: ['invites'],
    }),
    disagreeTooriject: build.mutation<void, { userId: number, id: number }>({
      query: ({ id, userId }) => ({
        url: `/api/${id}/${userId}/invitation/`,
        method: 'PATCH',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: {
          user: userId,
          project: id,
          status: 'declined',
        },
      }),
      invalidatesTags: ['invites'],
    }),
    declineProject: build.mutation<void, { userId: number, id: number }>({
      query: ({ id, userId }) => ({
        url: `/api/${userId}/${id}/responses/`,
        method: 'PATCH',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: {
          user: userId,
          project: id,
          status: 'declined',
        },
      }),
      invalidatesTags: ['invites'],
    }),
    sendContacts: build.mutation<void, { userId: number, id: number }>({
      query: ({ id, userId }) => ({
        url: `/api/${userId}/${id}/responses/`,
        method: 'PATCH',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
        body: {
          user: userId,
          project: id,
          status: 'accepted',
        },
      }),
      invalidatesTags: ['invites'],
    }),
    /// эксперты котрые хотят в мой проект
    getSpecsWantedToProject: build.query<UsersWhoWantToMyProjects[], void>({
      query: () => ({
        url: '/api/specialist-responses/',
        method: 'GET',
        headers: {
          Authorization: `Token ${jwt.get()}`,
        },
      }),
      providesTags: ['invites'],
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterMutation,
  useUpdateUserMutation,
  useGetAllExpertsQuery,
  useChangePasswordMutation,
  useNewGetCurrentUserQuery,
  useLazyNewGetCurrentUserQuery,
  useCreateEducationMutation,
  useDeleteEducationMutation,
  useUpdateEducationMutation,
  useCreateTestMutation,
  useUpdateTestMutation,
  useDeleteTestMutation,
  useAddProfessionMutation,
  useUpdateProfessionMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetProfessionWithIdQuery,
  useNewGetUserByIdQuery,
  useDeleteProfessionMutation,
  useCreateAchieveMutation,
  useUpdateAchieveMutation,
  useDeleteAchieveMutation,
  useLazyGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useCreateSoftEducationMutation,
  useDeleteSoftEducationMutation,
  useUpdateSoftEducationMutation,
  useActivateUserMutation,
  useSetNewPasswordMutation,
  useResetPasswordMutation,
} = userApi;

export const {
  useCreateProjectMutation,
  useGetCurrentUserProjectsQuery,
  useLazyGetCurrentUserProjectsQuery,
  useRespondToProjectMutation,
  useUpdateProjectMutation,
  useLazyGetUserProjectByIdQuery,
  useDeleteProjectMutation,
  useGetUserProjectByIdQuery,
  usePubishProjectMutation,
  useUnPublishProjectMutation,
  useGetAllPublishedProjectsQuery,
  useLazyGetAllPublishedProjectsQuery,
  useGetBackProjectResponseMutation,
  useNewInviteToProjectMutation,
  useGetBackUserInvitationMutation,
  useGetAllInvitedProjectsQuery,
  useLazyGetAllInvitedProjectsQuery,
  useGetAllUserResponsesQuery,
  useNewGetAllProjectsQuery,
} = projectsApi;

export const {
  useLazyGetSoftSkillsQuery,
  useLazyGetHardSkillsQuery,
  useCreateHardSkillMutation,
} = skillsApi;

export const {
  useLazyGetMyResponsesToprojectQuery,
  useIResponseToProjectMutation,
  useIInviteUserToProjectMutation,
  useDeleteIInviteUserToProjectMutation,
  useDeclineProjectMutation,
  useAgreeToorijectMutation,
  useSendContactsMutation,
  useGetSpecsIinviteToMyProjectsQuery,
  useLazyGetSpecsWantedToProjectQuery,
  useLazyGetSpecsIinviteToMyProjectsQuery,
  useIGetResponseToProjectBackMutation,
  useDisagreeToorijectMutation,
  useGetMyResponsesToprojectQuery,
  useGetSpecsWantedToProjectQuery,
} = inviteApi;
