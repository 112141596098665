/* eslint-disable max-len */
import { configureStore } from '@reduxjs/toolkit';

import {
  userApi,
  projectsApi,
  skillsApi,
  inviteApi,
} from '../api/api';
import allReducer from './allSlice';

const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [skillsApi.reducerPath]: skillsApi.reducer,
    [inviteApi.reducerPath]: inviteApi.reducer,
    all: allReducer,
  },
  middleware:
    (getDefaultMiddleware) => getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(projectsApi.middleware)
      .concat(inviteApi.middleware)
      .concat(skillsApi.middleware),
});

export default store;
