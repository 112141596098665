import React, { FC, ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { useDispatch } from '../../store/store.types';
import { openResponsesModal, setProfId } from '../../store/allSlice';

const ModalOverlay = styled.div<{ animatedProp:any }>` 
    z-index: 9999999999;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display:  ${({ animatedProp }) => (animatedProp === 'exited' ? 'none' : 'flex')};
    opacity: ${({ animatedProp }) => (animatedProp === 'entered' ? '1' : '0')}; ;
    background-color: rgba(0, 0, 0, 0.30);
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    transition: all ease .3s;
`;

const NewModalCustom: FC<{ children: ReactNode, animatedProp?: any }> = ({ children, animatedProp = false }) => {
  console.log(animatedProp);
  const dispatch = useDispatch();
  const modalRoot = document.getElementById('modal-root');
  const openAndClose = (e: any) => {
    if (e.target.id !== 'popup') { return; }
    dispatch(openResponsesModal(false));
  };

  return ReactDOM.createPortal(
    (
      <ModalOverlay animatedProp={animatedProp} id='popup' onClick={(e) => { openAndClose(e); }}>
        {/*  <Popup id='popup'> */}

        {children}

        {/*   </Popup> */}
      </ModalOverlay>
    ), modalRoot!,
  );
};

export default NewModalCustom;
