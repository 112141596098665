/* eslint-disable ternary/no-dupe */
/* eslint-disable no-nested-ternary */
/* eslint-disable ternary/nesting */
/* eslint-disable react/require-default-props */
import React, {
  FC, memo, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router';
import toast, { Toaster } from 'react-hot-toast';
import star from '../../assets/smallStar.svg';
import Themes from '../../themes';
import { useDispatch, useSelector } from '../../store/store.types';
import {
  openSpecCreatePopup,
  setHeadingForPopup,
  setProjectId,
  openFullProjectViewPopup,
  setRequestUser,
  setPublishStatus,
  openFolderDeletePopup,
  setFolderId,
  setType,
  openContactsForm,
  setContactFormType,
  setObjectForForm,
  openResponseToProjectPopup,
  setPairForPopup,
  setChoosePopupOpen,
} from '../../store/allSlice';
import { UniversalButton } from '../RestyledButtons';
import { RoundTeamDiv } from '../widgets/NewAvatarWithText';
import {
  PenIconCustomized,
  DelPicCustomized,
  LinkToProject,
  ClassSvg,
} from '../icons';
import { TravelsFontMixixn24, TravelsFontMixixn20 } from '../../constants/fontsConfigs';
import {
  useUnPublishProjectMutation,
  useRespondToProjectMutation,
  useGetBackProjectResponseMutation,
  useDeclineProjectMutation,
  useSendContactsMutation,
  useAgreeToorijectMutation,
  useIResponseToProjectMutation,
  useIGetResponseToProjectBackMutation,
  useGetAllInvitedProjectsQuery,
  useGetCurrentUserProjectsQuery,
} from '../../api/api';
import { Spec } from '../../types/apiTypes';
import { useOnScreen } from '../../helpers/hooks';

const ProjectPlate = styled.li<{ status: any, isPublished: boolean, requestSend: boolean, pos:boolean }>`
    border: ${({ theme: { mainButtonColor } }) => `${mainButtonColor} 0.73px solid `};
    border-radius: 20px;
    box-shadow: 0 4px 20px 0 #d4ddfd;
    background-color: ${({ status, theme: { plateColor } }) => (status === 'accepted' ? '#dee5ff' : plateColor)};
    width: 100%;
    max-height: 457px;
    height: 100%;
    position: relative;
    display: flex;
    min-height: ${({ pos }) => (pos ? '546px' : 'none')};
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box;
    align-items: center;
    & button:first-of-type {
      background-color: ${({
    requestSend, status, isPublished, theme: { plateColor, sliderColor },
  }) => (status === 'accepted' ? '#dee5ff' : isPublished || requestSend ? plateColor : sliderColor)};
    }
    &:hover {
        box-shadow: ${({ status, theme: { mainButtonColor } }) => (`0px 0px 20px 0px ${mainButtonColor}`)};
        cursor: pointer;
    }
`;

const MainInfoDiv = styled.div<{ status: any }>`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-self: center;
    overflow: hidden;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 32px;
      right: 0;
      box-shadow: ${({ status, theme: { mainButtonColor } }) => (status === 'accepted' ? 'none' : 'inset -38px -8px 15px -15px rgb(241, 244, 255)')}; 
    }
`;

const ButtonsDiv = styled.div`
  display: flex;
  margin-left: auto;
  
  position: absolute;
  top: 15px;
  right: 15px;
`;

const PlateHeader = styled.div`
  display: flex;
  gap: 42px;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  position: relative;
  /* box-shadow: inset -38px -8px 15px -15px rgb(241, 244, 255); */
  
`;
const PlateBottom = styled.div`
  padding-left: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
`;
const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 278px;
  height: 214px;
  outline: ${({ theme: { mainButtonColor } }) => `${mainButtonColor} 0.73px solid `};
  border-radius: 20px;
  flex-shrink: 0;
`;
const Image = styled.img<{ width: number, height: number }>`
  width: ${({ width }) => width}%;
  height: ${({ height }) => height}%;
  object-fit: cover;
`;
const H3 = styled.h3`
  display: flex;
  gap: 17px;
  margin: 0;
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn24}
 
`;
const CustomH3 = styled(H3)`
   overflow: hidden;
  text-overflow: ellipsis;
   display: -moz-box;
  -moz-box-orient: vertical;
   display: -webkit-box;
  -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   line-clamp: 3;
   box-orient: vertical;
`;
const Text = styled.p`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  margin: 0;
  max-height: 95px;
    height: 100%;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${TravelsFontMixixn20}
`;

const CustomRoundTeamDiv = styled(RoundTeamDiv)`
    /* white-space: break-spaces;
    width: min-content; */
    height: auto;
   
`;

export const SpecSmallPlate = styled.li`
  display: flex;
height: 32px;
padding: 4px 15px;

align-items: baseline;
border-radius: 36px;
border: ${({ theme: { mainButtonColor } }) => `${mainButtonColor} 1px solid`};
background-color: transparent;
color:${({ theme: { mainBg, mainTextColor } }) => mainTextColor};
${TravelsFontMixixn20}
letter-spacing: -0.5px;
box-sizing: border-box;
white-space: nowrap;
width: fit-content;

`;

export const SpecList = styled.ul<{ status?: string }>`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
  &::after {
      content: '';
      position: absolute;
      height: 32px;
      width: 32px;
      right: 0;
      box-shadow: ${({ status, theme: { mainButtonColor } }) => (status === 'accepted' ? 'none' : 'inset -38px -8px 15px -15px rgb(241, 244, 255)')}; 
    }
`;

const Wrapper = styled.div`
  display: flex;
    gap: 10px;
    width: 100%;
    height: 54px;
    align-self: flex-end;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    justify-content: end;
`;

const ProjectPlateCustom: FC<{
  ownerId?: number,
  currentUserId?: number,
  currentUserProfessions?: string[],
  projectName: string,
  projectImage: string,
  projectDirection: string,
  projectsSpecialist?: Spec[],
  projectStage: string,
  deleteFunc?: () => void,
  redactFunc?: () => void,
  projectIdea: string,
  isPublished?: boolean,
  projectId: number,
  withLink?: boolean,
  withSpecList?: boolean,
  isRequestSend?: boolean,
  setIntersecting?: (status: boolean) => void,
  buttosForProjectResponse?: boolean,
  status: string,
  vk?: string,
  tg?: string,
  gitHub?: string,
  email?: string,
  extra?: string,
  userImage?: string,
  userName?: string,
}> = ({
  userImage,
  userName,
  vk,
  tg,
  gitHub,
  email,
  extra,
  currentUserProfessions,
  ownerId,
  buttosForProjectResponse = false,
  currentUserId,
  status,
  projectDirection,
  setIntersecting,
  withLink = false,
  withSpecList = false,
  projectIdea,
  projectImage,
  projectName,
  projectsSpecialist = [],
  projectStage,
  deleteFunc,
  projectId,
  redactFunc,
  isPublished = false,
  isRequestSend = false,
}) => {
  const { theme } = useSelector((state) => state.all);
  const [unPublish] = useUnPublishProjectMutation();
  const [respond] = useIResponseToProjectMutation();
  const [getRespondBack] = useIGetResponseToProjectBackMutation();
  const [sendContacts] = useSendContactsMutation();
  const [declineProject] = useDeclineProjectMutation();
  const { data, refetch: re } = useGetCurrentUserProjectsQuery({ offset: 0, limit: 10000 });
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef<HTMLLIElement>(null);
  const [agreeToProject] = useAgreeToorijectMutation();
  const { data: prj, refetch } = useGetAllInvitedProjectsQuery();

  const intersectingState = useOnScreen(ref);
  useEffect(() => {
    setIntersecting && setIntersecting(intersectingState);
  }, [intersectingState]);
  const changeStatusFunc = async (e: any) => {
    e.stopPropagation();
    if (isPublished) {
      await unPublish(projectId);
      toast.success('Проект снят с публикации');
      dispatch(setRequestUser(Date.now()));
      return;
    }
    dispatch(openSpecCreatePopup(true));
    dispatch(setHeadingForPopup(projectName));
    dispatch(setProjectId(projectId));
  };

  const respondToProject = async (e:any) => {
    e.stopPropagation();
    if (isRequestSend) {
      await getRespondBack({ userId: currentUserId!, projectId });
      dispatch(setRequestUser(Date.now()));
      dispatch(openFullProjectViewPopup(false));
      toast.success('Ваш отклик отправлен');
      return;
    }
    const needSpecs = projectsSpecialist.map((el) => el.specialist_name);
    if (needSpecs.some((el) => currentUserProfessions?.indexOf(el) !== -1)) {
      await respond({ userId: currentUserId!, projectId });
      dispatch(setRequestUser(Date.now()));
      toast.success('Ваш отклик отправлен');
    } else {
      dispatch(setPairForPopup({ prof: '', proj: projectName }));
      dispatch(openResponseToProjectPopup(true));
    }
  };

  const openFullViewPopup = () => {
    dispatch(setProjectId(projectId));
    dispatch(openFullProjectViewPopup(true));
  };

  const sendContactsFunc = async (e:any) => {
    e.stopPropagation();
    await agreeToProject({ userId: currentUserId!, id: projectId });
    await refetch();
  };

  const openChooseRedactPopup = (e:any) => {
    e.stopPropagation();
    dispatch(setProjectId(projectId));
    dispatch(setHeadingForPopup(projectName));
    dispatch(setChoosePopupOpen(true));
  };
  return (
    <ProjectPlate
      pos={location.pathname === '/projects-window'}
      onClick={openFullViewPopup}
      requestSend={isRequestSend}
      isPublished={isPublished}
      status={status}
      ref={ref}>
      {status === 'accepted'
          && <ClassSvg />}
      <PlateHeader>
        <ImageWrapper>
          <Image
            width={projectImage ? 100 : 55}
            height={projectImage ? 100 : 56}
            src={projectImage || star}
            alt='аватарка' />
        </ImageWrapper>
        <MainInfoDiv status={status}>
          <CustomH3>{projectName}</CustomH3>
          <CustomRoundTeamDiv isOrange>{projectStage}</CustomRoundTeamDiv>
          <CustomRoundTeamDiv isOrange={false}>{projectDirection}</CustomRoundTeamDiv>
        </MainInfoDiv>
        {!withLink
            && (
              <ButtonsDiv>
                <PenIconCustomized onClick={isPublished ? openChooseRedactPopup : (redactFunc && redactFunc)} color='rgba(243, 160, 59, 1)' />
                <DelPicCustomized onClick={(e:any) => { e.stopPropagation(); deleteFunc && deleteFunc(); }} color='rgba(201, 68, 32, 1)' />
              </ButtonsDiv>
            )}
      </PlateHeader>
      <PlateBottom>
        <H3>Идея проекта</H3>
        <Text>{projectIdea}</Text>
        {withSpecList
            && (
              <>
                <H3>Требуемые специалисты</H3>
                <SpecList status={status}>
                  {projectsSpecialist?.map((item) => (
                    <SpecSmallPlate>{item.specialist_name}</SpecSmallPlate>
                  ))}
                </SpecList>
              </>
            )}
        {/*  {withLink
            && (
              <H3 onClick={openFullViewPopup}>
                Подробнее о проекте
                <LinkToProject />
              </H3>
            )} */}
        {!withLink
            && (
              <UniversalButton
                onClick={changeStatusFunc}
                style={{ alignSelf: 'end', marginTop: 'auto' }}
                type='button'
                textColor={Themes[theme].mainTextColor}
                backColor={isPublished ? Themes[theme].plateColor : Themes[theme].sliderColor}
                borderColor={isPublished ? Themes[theme].mainButtonColor : 'transparent'}
                paddingLeft={35}
                paddingTop={15}>
                {isPublished ? 'Отозвать проект' : 'Опубликовать'}
              </UniversalButton>
            )}

        {withLink && location.pathname !== '/responses-projects'
            && status === 'accepted'
          ? (
            <UniversalButton
              onClick={(e) => {
                e.stopPropagation(); dispatch(openContactsForm(true)); dispatch(setContactFormType(2)); dispatch(setObjectForForm({
                  tel: null,
                  name: userName,
                  email,
                  vk,
                  github: gitHub,
                  tg,
                  extra,
                  image: userImage,
                }));
              }}
              style={{ alignSelf: 'end', marginTop: 'auto' }}
              type='button'
              disabled={false}
              textColor={Themes[theme].mainTextColor}
              backColor={isRequestSend ? Themes[theme].plateColor : Themes[theme].sliderColor}
              borderColor={isRequestSend ? Themes[theme].mainButtonColor : 'transparent'}
              paddingLeft={35}
              paddingTop={15}>
              Показать контакты
            </UniversalButton>
          )

          : currentUserId !== ownerId && location.pathname !== '/responses-projects' ? (
            <UniversalButton
              onClick={respondToProject}
              style={{ alignSelf: 'end', marginTop: 'auto' }}
              type='button'
              disabled={isRequestSend && location.pathname !== '/my-responses'}
              textColor={Themes[theme].mainTextColor}
              backColor={isRequestSend ? Themes[theme].plateColor : Themes[theme].sliderColor}
              borderColor={isRequestSend ? Themes[theme].mainButtonColor : 'transparent'}
              paddingLeft={35}
              paddingTop={15}>
              {!isRequestSend ? 'Откликнуться' : 'Отправлен отклик'}
            </UniversalButton>
          ) : null}

      </PlateBottom>
      {buttosForProjectResponse
          && (
            <Wrapper>
              {status === 'accepted'
                ? (
                  <UniversalButton
                    onClick={(e) => {
                      e.stopPropagation(); dispatch(openContactsForm(true)); dispatch(setContactFormType(2)); dispatch(setObjectForForm({
                        tel: null,
                        name: userName,
                        email,
                        vk,
                        github: gitHub,
                        tg,
                        extra,
                        image: userImage,
                      }));
                    }}
                    style={{ alignSelf: 'end', marginTop: 'auto' }}
                    type='button'
                    disabled={false}
                    textColor={Themes[theme].mainTextColor}
                    backColor={isRequestSend ? Themes[theme].plateColor : Themes[theme].sliderColor}
                    borderColor={isRequestSend ? Themes[theme].mainButtonColor : 'transparent'}
                    paddingLeft={35}
                    paddingTop={15}>
                    Показать контакты
                  </UniversalButton>
                )
                : (
                  <>
                    <UniversalButton
                      onClick={(e) => { e.stopPropagation(); dispatch(setType(2)); dispatch(openFolderDeletePopup(true)); dispatch(setFolderId(projectId)); dispatch(setProjectId(currentUserId!)); }}
                      style={{ alignSelf: 'end', marginTop: 'auto' }}
                      type='button'
                      textColor={Themes[theme].mainTextColor}
                      backColor={Themes[theme].plateColor}
                      borderColor={Themes[theme].mainButtonColor}
                      paddingLeft={35}
                      paddingTop={15}>
                      Отказать
                    </UniversalButton>
                    <UniversalButton
                      onClick={sendContactsFunc}
                      style={{ alignSelf: 'end', marginTop: 'auto' }}
                      type='button'
                      textColor={Themes[theme].mainTextColor}
                      backColor={isPublished ? Themes[theme].plateColor : Themes[theme].sliderColor}
                      borderColor={isPublished ? Themes[theme].mainButtonColor : 'transparent'}
                      paddingLeft={35}
                      paddingTop={15}>
                      Поделиться контактом
                    </UniversalButton>
                  </>
                )}
            </Wrapper>
          )}
    </ProjectPlate>
  );
};

export default memo(ProjectPlateCustom);
