/* eslint-disable import/extensions */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable radix */
import React, {
  ChangeEvent, FC, useCallback, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { delMany, getMany } from 'idb-keyval';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { professions, arrForProfessionSearch, levels } from '../../constants/textsForLanding';
import { TravelsFontMixixn20, TravelsFontMixixn24 } from '../../constants/fontsConfigs';
import NewSelectWithPlural, { SelectLevels } from './NewSelectWithPlural';
import {
  ErrorForInput, InputWithTags, RequiredSpan, TextAreaWithNoValidation,
} from '../FormElements';
import { UniversalButton } from '../RestyledButtons';
import { useSelector, useDispatch } from '../../store/store.types';
import Themes from '../../themes';
import { TAchievement, TSkill } from '../../types/apiTypes';
import {
  useAddProfessionMutation,
  useCreateAchieveMutation,
  useCreateHardSkillMutation,
  useDeleteAchieveMutation,
  useLazyGetHardSkillsQuery,
  useLazyGetSoftSkillsQuery,
  useNewGetCurrentUserQuery,
  useUpdateAchieveMutation,
  useUpdateProfessionMutation,
} from '../../api/api';
import { useAutoSaveFormsProfCreate } from '../../helpers/hooks';
import BlockForAchievements from './BlockForAchievements';
import { openCreateProfessionPopup, setProfId, setRequestUser } from '../../store/allSlice';

const Header2 = styled.h2`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn24}
  align-self: center;
`;

const P = styled.p`
  color: ${({ theme: { placeHolderColor } }) => placeHolderColor};
  ${TravelsFontMixixn20}
  align-self: center;
`;

const Section = styled.section`
    box-sizing: border-box;
    padding-inline: 20px;
    display: flex;
    padding-top: 35px;
    padding-bottom: 90px;
    flex-direction: column;
    position: relative;
    width: 100%;
    gap: 66px;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme: { modalOverlay } }) => modalOverlay};
`;

const Plate = styled.form`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 1370px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  border-radius: 20px;
  border:${({ theme: { mainButtonColor } }) => `0.734px solid ${mainButtonColor}`};
  background: ${({ theme: { mainBg } }) => mainBg};
  box-sizing: border-box;
`;

const AchivementBlock = styled.div`
  gap: 25px;
  flex-direction: column;
  display: flex;
`;
const AchivementsHeader = styled.h3`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  margin: 0;
  ${TravelsFontMixixn20}
  position: relative;
`;

const AchievementsP = styled.p`
   ${TravelsFontMixixn20}
   margin: 0;
   color: ${({ theme: { placeholderColor } }) => placeholderColor};
`;
/// уровень, выпадающий список скиллов у инпут с тегами, переходы, апи

const CreateProfessionForm: FC<{ status: string }> = ({ status }) => {
  const { theme, profId } = useSelector((state) => state.all);
  const { data, error } = useNewGetCurrentUserQuery();
  const [createProfession] = useAddProfessionMutation();
  const [updateProfession] = useUpdateProfessionMutation();
  const [createSkill] = useCreateHardSkillMutation();
  const [updateAchievement] = useUpdateAchieveMutation();
  const navigate = useNavigate();
  const [getSkillByName] = useLazyGetSoftSkillsQuery();
  const [getHardSkillByName] = useLazyGetHardSkillsQuery();
  const [softSkills, setSoftSkills] = useState<TSkill[]>([]);
  const [hardSkills, setHardSkills] = useState<TSkill[]>([]);
  const [level, setLevel] = useState<string>('');
  const [profession, setProfessionName] = useState<string>('');
  const [expirience, setExpirience] = useState<string>('');
  const [goals, setGoals] = useState<string>('');
  const [objectForSave, setValuesForSaveOject] = useAutoSaveFormsProfCreate();
  const [isSubmited, setSubmit] = useState(false);
  const dispatch = useDispatch();
  const [achievements, setAchievements] = useState<TAchievement[]>([
    {
      id: null,
      achievement_name: '',
      image: '',
      file_name: '',
    }]);
  const timer = useRef<string | number | NodeJS.Timeout | undefined>();
  const [downloadSofts, setDownloadedSofts] = useState<TSkill[]>([]);
  const [downloadHards, setDownloadedHards] = useState<TSkill[]>([]);
  const [usersSoftsFromLk, setUserSoftsFromLk] = useState<TSkill[]>([]);
  const [usersHardsFromLk, setUserHardsFromLk] = useState<TSkill[]>([]);
  const setSoftSkillInProfession = useCallback((el: TSkill) => {
    if (el.name === '' || softSkills.find((item) => el.name === item.name)) { return; }
    const copy = [...softSkills];
    copy.push(el);
    setSoftSkills(copy);
  }, [softSkills]);
  useEffect(() => {
    if (data) {
      setUserHardsFromLk(data?.hard_skills!);
      setUserSoftsFromLk(data.soft_skills!);
    }
  }, [data, hardSkills, softSkills]);
  const deleteSoftSkill = useCallback((index: number) => {
    const copy = [...softSkills];
    copy.splice(index, 1);
    setSoftSkills(copy);
  }, [softSkills]);

  const setHardSkillInProfession = useCallback(async (el: TSkill) => {
    if (el.name === '' || hardSkills.find((item) => el.name === item.name)) { return; }
    if (el.id === 9999999) {
      const customHard = await createSkill({ name: el.name }).unwrap();
      const copyArr = [...hardSkills];
      copyArr.push(customHard);
      setHardSkills(copyArr);
      return;
    }
    const copy = [...hardSkills];
    copy.push(el);
    setHardSkills(copy);
  }, [hardSkills]);

  const deleteHardSkill = useCallback((index: number) => {
    const copy = [...hardSkills];
    copy.splice(index, 1);
    setHardSkills(copy);
  }, [hardSkills]);
  const checkForFindEmptyValuesInAch = () => achievements?.some((item) => {
    const condition1 = item.achievement_name === '';
    const condition2 = item.image === '';
    return condition1 || condition2;
  });
  const checkSoftSkillsError = () => softSkills?.length === 0;
  const checkHardSkillsError = () => hardSkills?.length === 0;
  const checkProfession = () => profession !== '';
  const checkLevel = () => level !== '';
  const checkGoals = () => goals !== '';
  /*  const checkHards = () => hardSkills.length !== 0; */
  const checkExpirience = () => expirience?.length > 10;
  const pushProfession = async (e: any) => {
    e.preventDefault();
    setSubmit(true);
    if (!checkExpirience()
      || checkForFindEmptyValuesInAch()
      || checkSoftSkillsError()
      || checkHardSkillsError()
      || !checkLevel()
      || !checkProfession()) { return; }
    const modifiedHards = hardSkills?.map((el) => el.id);
    const modifiedSofts = softSkills?.map((el) => el.id);
    const profObj = {
      profession_name: profession,
      level,
      experience: expirience,
      hard_skills: modifiedHards,
      soft_skills: modifiedSofts,
      development_goals: goals,
    };
    try {
      profId ? await updateProfession({
        prof: { ...profObj, achievements },
        userId: data?.id!,
        professionId: profId,
      })
        : await createProfession({
          prof: { ...profObj, achievements },
          userId: data?.id!,
        });
      setSubmit(false);
      await delMany(['hard_skills_prof',
        'soft_skills_prof',
        'profession_name',
        'level',
        'experience',
        'development_goals',
        'achievements']);
      dispatch(openCreateProfessionPopup(false));
      dispatch(setProfId(null));
      dispatch(setRequestUser(Date.now()));
    } catch (err: any) {
      console.log(err);
    }
  };
  const setExpToState = (e: ChangeEvent<HTMLTextAreaElement>, index?: number) => {
    const exp = e.target.value;
    setExpirience(exp);
  };

  const setGoalsToState = (e: ChangeEvent<HTMLTextAreaElement>, index?: number) => {
    const goal = e.target.value;
    setGoals(goal);
  };
  const getSkillsFromDataBase = async (item: string) => {
    try {
      const softsArray = await getSkillByName(item).unwrap();
      setDownloadedSofts(softsArray);
    } catch (err: any) {
      console.log(err);
    }
  };
  const getSkillsHardFromDataBase = async (item: string) => {
    try {
      const hardsArray = await getHardSkillByName(item).unwrap();
      setDownloadedHards(hardsArray);
    } catch (err: any) {
      console.log(err);
    }
  };

  const getHardsSkillsFromDataBaseWithTimer = (item: string) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(getSkillsHardFromDataBase, 500, item);
  };

  const getSkillsFromDataBaseWithTimer = (item: string) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(getSkillsFromDataBase, 500, item);
  };

  const redactAchieveObject = useCallback((e: ChangeEvent<any>, index?: number) => {
    const copyArr = structuredClone(achievements);
    const changeObject = copyArr[index!];
    switch (e.target.name) {
      case 'achieveName': {
        changeObject.achievement_name = e.target.value;
        setAchievements(copyArr);
        break;
      }
      case 'result': {
        changeObject.description = e.target.value;
        setAchievements(copyArr);
        break;
      }
    }
  }, [achievements]);

  const addNewObjectToAchieves = useCallback(() => {
    const copyArr = [...achievements];
    copyArr.push({
      achievement_name: '',
      image: '',
      file_name: '',
    });
    setAchievements(copyArr);
  }, [achievements]);

  const submitAchievements = useCallback(async (index: number) => {
    try {
      const currentSavedObject = achievements[index];

      await updateAchievement({ achieve: currentSavedObject, userId: data?.id!, achieveId: currentSavedObject.id });
    } catch (err) {
      console.log(err);
    }
  }, [achievements]);

  const deleteObjInAchievements = useCallback((index: number, pos: number) => {
    const copyArr = structuredClone(achievements);
    copyArr.splice(pos, 1);
    setAchievements(copyArr);

    /*  try {
      await deleteAchievement({ userId: data?.id!, achieveId: index });
    } catch (err) {
      console.log(err);
    } */
  }, [achievements]);

  useEffect(() => {
    if (data && profId) {
      console.log('am i here');
      const currentProf = data?.professions?.find((el) => el.id === profId);
      setSoftSkills(currentProf?.soft_skills!);
      setHardSkills(currentProf?.hard_skills!);
      setLevel(currentProf?.level!);
      setProfessionName(currentProf?.profession_name!);
      setExpirience(currentProf?.experience!);
      setGoals(currentProf?.development_goals!);
      setAchievements(currentProf?.achievements!);
    }
  }, [data, status]);
  const makeFileForDownload = useCallback((e: ChangeEvent<HTMLInputElement>, index?: number) => {
    if (e.currentTarget.id === 'achievedel') {
      const arrCopy = structuredClone(achievements);
      const currentChangeObject = arrCopy[index!];
      currentChangeObject.image = '';
      currentChangeObject.file_name = '';
      setAchievements(arrCopy);
      return;
    }
    switch (e.target.name) {
      case 'achieve': {
        const reader = new FileReader();
        /* if (/\.(jpe?g|png)$/i.test(e.target.files![0].name)) { */
        reader.readAsDataURL(e.target.files![0]);
        reader.addEventListener('load', () => {
          const arrCopy = structuredClone(achievements);
          const currentChangeObject = arrCopy[index!];
          currentChangeObject.image = reader.result! as string;
          currentChangeObject.file_name = e.target.files![0].name;
          setAchievements(arrCopy);
        });
        /*  } else {
          toast.error('Необходим jpg или png файл');
        } */
        break;
      }
    }
  }, [achievements]);

  const putAllValues = async () => {
    const [
      hards,
      softs,
      profName,
      lvl,
      exp,
      devGoals,
      achs,
    ] = await getMany([
      'hard_skills_prof',
      'soft_skills_prof',
      'profession_name',
      'level',
      'experience',
      'development_goals',
      'achievements']);
    setValuesForSaveOject({
      development_goals: devGoals,
      hard_skills: hards,
      soft_skills: softs,
      level: lvl,
      experience: exp,
      achievements: achs,
      profession_name: profName,
    });
    if (data) {
      const currentProf = data?.professions?.find((el) => el.id === profId);
      setSoftSkills(currentProf?.soft_skills! || softs || []);
      setHardSkills(currentProf?.hard_skills! || hards || []);
      setLevel(currentProf?.level! || lvl || '');
      setProfessionName(currentProf?.profession_name! || profName || '');
      setExpirience(currentProf?.experience! || exp || '');
      setGoals(currentProf?.development_goals! || devGoals || '');
      setAchievements(currentProf?.achievements! || achs || [
        {
          id: null,
          achievement_name: '',
          image: '',
          file_name: '',
        }]);
    }
  };

  useEffect(() => {
    if (data && !profId && status === 'entered') {
      (
        async () => {
          console.log('am i here 2');
          await putAllValues();
          /// / как только он срабатывает то все ломается
        })();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
    }
  }, [data]);

  useEffect(() => {
    console.log('im mount too');
    setValuesForSaveOject({
      ...objectForSave,
      soft_skills: softSkills || [],
      hard_skills: hardSkills || [],
      profession_name: profession || '',
      level: level || '',
      experience: expirience || '',
      development_goals: goals || '',
      achievements: achievements || [
        {
          id: null,
          achievement_name: '',
          image: '',
          file_name: '',
        }],
    });
  }, [softSkills, hardSkills, profession, level, expirience, goals, achievements]);
  return (
    <Plate onSubmit={pushProfession}>
      <Header2>Специальность</Header2>
      <P>Выберите специальность, которую Вы хотите выложить на витрину</P>
      <NewSelectWithPlural
        withError={!checkProfession() && isSubmited}
        forRequired
        label='Название специальности'
        setProfession={setProfessionName}
        professionValue={profession}
        placeholder1='Выберите свою специальность'
        placeholder2='Введите специальность, например: Data engineer'
        emptyCase='Не нашли вашей специальности'
        baseArr={professions}
        arrForSearch={arrForProfessionSearch} />
      <SelectLevels
        iconZIndex={999}
        labelText='Уровень'
        placeholder='Укажите ваш уровень'
        arrayForMap={levels}
        withError={!checkLevel() && isSubmited}
        forRequired
        setLevel={setLevel}
        level={level} />
      <InputWithTags
        savedHardFromLk={usersHardsFromLk}
        setValueToRequest={getHardsSkillsFromDataBaseWithTimer}
        hasError={checkHardSkillsError() && isSubmited}
        setTagToArray={setHardSkillInProfession}
        deleteTagFromArray={deleteHardSkill}
        forRequired
        label='Hard skills'
        name='hardSkills'
        type='text'
        withButton
        downloadedValues={downloadHards}
        placeholder='Напишите hard skill. Например: Figma'
        maxLength={200}
        tags={hardSkills} />
      <InputWithTags
        savedSoftFromLk={usersSoftsFromLk}
        setValueToRequest={getSkillsFromDataBaseWithTimer}
        hasError={checkSoftSkillsError() && isSubmited}
        setTagToArray={setSoftSkillInProfession}
        deleteTagFromArray={deleteSoftSkill}
        forRequired
        downloadedValues={downloadSofts}
        label='Soft skills'
        name='softSkills'
        type='text'
        placeholder='Напишите soft skill. Например: упорство'
        maxLength={200}
        tags={softSkills} />
      <TextAreaWithNoValidation
        errorMessage={isSubmited && !checkExpirience()}
        forRequired
        minLength={10}
        name='exp'
        label='Опыт работы'
        maxLength={1000}
        value={expirience}
        placeholder='Расскажите про Ваш опыт'
        height={160}
        onChange={setExpToState} />
      <TextAreaWithNoValidation
        errorMessage={isSubmited && !checkGoals()}
        forRequired
        minLength={10}
        name='goals'
        label='Цели развития в рамках специальности'
        maxLength={10000}
        value={goals}
        placeholder='Расскажите про Ваши цели и амбиции'
        height={160}
        onChange={setGoalsToState} />
      <AchivementBlock>
        <AchivementsHeader>
          Достижения
          <RequiredSpan>*</RequiredSpan>
        </AchivementsHeader>
        {!checkForFindEmptyValuesInAch()
          ? <AchievementsP>Расскажите о своих победах в конкурсах и участии в форумах/конфиренциях</AchievementsP>
          : (isSubmited && <ErrorForInput>Заполните все поля в ваших достижениях</ErrorForInput>)}
        <BlockForAchievements
          onChange={redactAchieveObject}
          addOption={addNewObjectToAchieves}
          achievementsList={achievements || []}
          fileOnChange={makeFileForDownload}
          deleteFunc={deleteObjInAchievements}
          submitAchievements={submitAchievements} />
      </AchivementBlock>
      <UniversalButton
        style={{ alignSelf: 'end' }}
        type='submit'
        textColor={Themes[theme].mainTextColor}
        backColor={Themes[theme].sliderColor}
        borderColor=''
        paddingLeft={40}
        paddingTop={20}>
        {profId ? 'Сохранить' : 'Добавить себя, как специалиста'}
      </UniversalButton>
    </Plate>
  );
};
export default CreateProfessionForm;
