import React, { FC } from 'react';
import styled from 'styled-components';
import {
  ComfortaFontMixixn39,
  TravelsFontMixixn20,
  TravelsFontMixixn30,
  TravelsFontMixixn15,
  TravelsFontMixixn24,
  ComfortaFontMixixn31,
} from '../../constants/fontsConfigs';
import { FullDonePicBlackIcon, GreenOkIcon } from '../icons';
import { UniversalButton } from '../RestyledButtons';
import { useSelector } from '../../store/store.types';
import Themes from '../../themes';
import OrangeSlice from '../../assets/orangePieceSmall1.svg';
import OrangeSliceShadow from '../../assets/orangePieceSmall2.svg';
import { SubscriptionInclusive } from '../SubscriptionInclusive';
import {
  CostElement,
  PayBlockWithOrangeSlices,
  OtherFuncs,
} from './PayFormAndSubscription/PayComponents';

const SubcsriptionAboutContainer = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const SubscriptionName = styled.div`
    padding: 20px 44px;
    background-color: ${({ theme: { sliderColor } }) => sliderColor};
    color: ${({ theme: { mainTextColor } }) => mainTextColor};     
    ${TravelsFontMixixn20}
    font-size: 25px;
    font-style: normal;
    cursor: pointer;
    border-radius: 36px;
    box-shadow: ${({ theme: { sliderColor } }) => `0px 0px 20px 0px ${sliderColor}`};
`;

const SubscriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const SubscriptionBlock: FC = () => {
  const { theme } = useSelector((state) => state.all);
  return (
    <>
      <SubcsriptionAboutContainer>
        <SubscriptionWrapper>
          <SubscriptionName>Премиум</SubscriptionName>
          <GreenOkIcon />
        </SubscriptionWrapper>
        <CostElement text='на 30 дней' cost={499} />
      </SubcsriptionAboutContainer>
      <SubscriptionInclusive theme={theme} subType='dop' />
      <PayBlockWithOrangeSlices />
      <OtherFuncs theme={theme} />
    </>
  );
};

export default SubscriptionBlock;
