/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-nested-ternary */
/* eslint-disable ternary/nesting */
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Toaster } from 'react-hot-toast';
import { SectionHeader } from '../ui-lib/TextBlocks';
import ProjectPlateCustom from '../ui-lib/ProjectPlateRedisined/ProjectPlateCustom';
import SectionPlateWithFilter from '../ui-lib/widgets/SectionPlateWithFilter';
import {
  useGetAllPublishedProjectsQuery,
  useNewGetCurrentUserQuery,
  useLazyGetAllPublishedProjectsQuery,
} from '../api/api';
import { makeToast } from '../helpers/promts';
import { useSelector } from '../store/store.types';
import { Skeleton3 } from '../ui-lib/widgets/Skeleton';
import { TNewProject } from '../types/apiTypes';
import { professions, projectDirections } from '../constants/textsForLanding';

const ProjectList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, minmax(320px, 676px));
  grid-auto-rows: 1fr;
  gap: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: -30px;
  & li {
    max-height: none;
  }
`;

const WindowList: FC = () => {
  const { data: user, isLoading: loading, refetch } = useNewGetCurrentUserQuery();
  const { data: prjk } = useGetAllPublishedProjectsQuery({ offset: 0, limit: 1000 });
  const [getProjects, { data }] = useLazyGetAllPublishedProjectsQuery();
  const [curOfset, setOffset] = useState(0);
  const [intersect, setIntersecting] = useState<boolean>(false);
  const [localStateProjects, setLocalStateProjects] = useState<TNewProject[]>([]);
  const { filterDirectionsValues, filterProfessionValues, requestUser } = useSelector((state) => state.all);
  const [preventStop, setStop] = useState(true);
  const [canGetMore, setCanGetMore] = useState(true);
  /*   useEffect(() => {
    makeToast(error, undefined);
  }, [error, status]);
 */
  const filterWithDirectionsAndProfessionsTest1 = (arr: TNewProject[]) => {
    console.log(filterDirectionsValues);
    const arr3 = ['Информационные технологии (IT)', 'Культура и творчество', 'Медицина', 'Предпринимательство', 'Промышленность', 'Социалка', 'Спорт', 'Экология', 'Другое'];
    const direct: any[] = [];
    const secArr: any[] = [];
    filterDirectionsValues.forEach((el) => {
      if (arr3.includes(el)) {
        direct.push(el);
      }
    });
    console.log(direct);
    projectDirections.forEach((elm) => {
      console.log(direct.includes(elm.name));
      if (direct.includes(elm.name)) {
        elm.array.forEach((u) => {
          secArr.push(u);
        });
      }
    });

    const arr4 = ['ИТ-разработка', 'Упаковка продукта, менеджмент продукта, оптимизация процессов', 'Креативные индустрии, искусство, развлечения', 'Экономика-финансы, аналитика, юридические услуги', 'Специалисты в сфере науки', 'Специалисты техническо-промышленной сферы'];
    const direct1: any[] = [];
    const secArr1: any[] = [];
    filterProfessionValues.forEach((el) => {
      if (arr4.includes(el)) {
        direct1.push(el);
      }
    });
    professions.forEach((elm) => {
      if (direct1.includes(elm.name)) {
        elm.array.forEach((u) => {
          secArr1.push(u);
        });
      }
    });

    const firstStepFiltration = arr?.filter((item) => (filterDirectionsValues.length === 0 ? item : ([...filterDirectionsValues, ...secArr].includes(item.project_direction))));
    console.log(secArr);
    const secondStepFiltration = firstStepFiltration?.filter((el) => {
      const expertsArr = el.required_specialists;
      const hasExpert = expertsArr.some((item) => [...filterProfessionValues, ...secArr1].includes(item.specialist_name));
      return filterProfessionValues.length === 0 ? el : hasExpert;
    });
    return secondStepFiltration;
  };

  useEffect(() => {
    (async () => {
      if (curOfset === 0) {
        setLocalStateProjects([]);
      }
      const projects = await getProjects({ offset: curOfset, limit: 4 }).unwrap();
      if (preventStop) {
        setLocalStateProjects([...localStateProjects, ...projects.results]);
      }
      if (projects.next === null) {
        setStop(false);
      }
    })();
  }, [user, data, curOfset]);

  useEffect(() => {
    setStop(true);
    refetch();
  }, [requestUser]);

  useEffect(() => {
    if (intersect) {
      setOffset((prev) => prev + 4);
    }
  }, [intersect]);

  return (

    <SectionPlateWithFilter withOneSelect={false} path='/'>
      <Toaster position='top-center' />
      <SectionHeader>Витрина проектов</SectionHeader>
      {!data && !prjk
        ? <Skeleton3 />

        : (
          <ProjectList>
            {filterWithDirectionsAndProfessionsTest1(prjk?.results!)?.filter((itm) => (itm.user_id === user?.id)).map((el) => (
              <ProjectPlateCustom
                status='pending'
                currentUserProfessions={user?.professions?.map((elem) => elem.profession_name!)}
                setIntersecting={setIntersecting}
                ownerId={el.user_id}
                currentUserId={user?.id}
                projectDirection={el.project_direction}
                withLink
                withSpecList
                projectIdea={el.project_idea}
                projectImage={el.project_logo}
                projectName={el.project_name}
                projectsSpecialist={el.required_specialists}
                projectStage={el.project_stage}
                projectId={el.id!}
                isRequestSend={user?.responded_projects?.indexOf(el?.id!)! > -1} />
            ))}
            {filterWithDirectionsAndProfessionsTest1(localStateProjects)?.filter((itm) => (itm.user_id !== user?.id)).map((el) => (
              <ProjectPlateCustom
                status='pending'
                currentUserProfessions={user?.professions?.map((elem) => elem.profession_name!)}
                setIntersecting={setIntersecting}
                ownerId={el.user_id}
                currentUserId={user?.id}
                projectDirection={el.project_direction}
                withLink
                withSpecList
                projectIdea={el.project_idea}
                projectImage={el.project_logo}
                projectName={el.project_name}
                projectsSpecialist={el.required_specialists}
                projectStage={el.project_stage}
                projectId={el.id!}
                isRequestSend={user?.responded_projects?.indexOf(el?.id!)! > -1} />
            ))}
          </ProjectList>
        )}
    </SectionPlateWithFilter>

  );
};

export default WindowList;
