import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import {
  BlackDelIcon,
  ExtraLinkIcon,
  GitIcon,
  SmsTrackIcon,
  TgIcon,
  VkIcon,
} from '../icons';
import {
  TravelsFontMixixn24,
  TravelsFontMixixn20,
  TravelsFontMixixn30,

} from '../../constants/fontsConfigs';
import { UniversalButton } from '../RestyledButtons';
import Themes from '../../themes';
import { CustomMaskedInput } from '../FormElements';
import { useSelector, useDispatch } from '../../store/store.types';
import { openConfirmPopup, openContactsForm } from '../../store/allSlice';

const Form = styled.form`
  padding: 28px 30px;
  border-radius: 20px;
  background-color: ${({ theme: { plateColor } }) => plateColor};
  border: ${({ theme: { mainButtonColor } }) => `.73px solid ${mainButtonColor}`};
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 672px;
  width: 100%;
  align-items: start;
  box-sizing: border-box;
`;
const Wrapper = styled.div`
  display: flex;

  gap: 10px;
`;

export const ImageBox = styled.div<{ width: number, height: number }>`
    border-radius: 50%;
    border: ${({ theme: { mainTextColor } }) => `${mainTextColor} 1px solid`};
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SquareBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 278px;
  height: 214px;
  outline: ${({ theme: { mainButtonColor } }) => `${mainButtonColor} 0.73px solid `};
  border-radius: 20px;
  flex-shrink: 0;
  top: -30px;
    left: -20px;
    position: relative;
`;

export const Image = styled.img<{ width:number, height:number }>`
  width: ${({ width }) => width}%;
  height: ${({ height }) => height}%;
  object-fit: cover;
`;

const Container = styled.div`
    max-width: 760px;
    width: 100%;
    display: flex;
    gap: 70px;
    align-self: flex-start;
`;

const TextBox = styled.div`
  display:flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
const LinksContainer = styled.div`
  display:flex ;
  gap: 10px;
`;
const SocialLink = styled.a`
  margin: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor:pointer;
`;

const H2 = styled.h2`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn30}
`;

const ColoredP = styled.p`
   color: ${({ theme: { sliderColor } }) => sliderColor};
   ${TravelsFontMixixn30}
`;

const Label = styled.label`
  ${TravelsFontMixixn24}
  color: ${({ theme: { mainTextColor } }) => `1ox solid ${mainTextColor}`};

`;

const P = styled.p`
  ${TravelsFontMixixn20};
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
`;

const ContacsFormPopup: FC = () => {
  const [code, setCode] = useState<number | string>('');
  const { theme, contactFormType, objectForForm } = useSelector((state) => state.all);
  console.log(objectForForm);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  switch (contactFormType) {
    case 1: {
      return (
        <Form data-cy='regConfirm'>
          <BlackDelIcon
            onClick={(e: any) => { e.preventDefault(); e.stopPropagation(); dispatch(openContactsForm(false)); }}
            style={{ position: 'absolute', top: '-10px', right: '-27px' }} />
          <Container>

            <ImageBox width={181} height={181}>
              <Image width={181} height={181} src={objectForForm.image} alt='аватарка' />
            </ImageBox>

            <TextBox>
              <H2>
                {objectForForm.name}
              </H2>
              <Wrapper>
                {objectForForm.email && <P>{objectForForm.email}</P>}
              </Wrapper>
              <LinksContainer>
                {objectForForm.tg && <SocialLink href={objectForForm.tg} target='_blank' rel='noopener noreferrer'><TgIcon /></SocialLink>}
                {objectForForm.vk && <SocialLink href={objectForForm.vk} target='_blank' rel='noopener noreferrer'><VkIcon /></SocialLink>}
                {objectForForm.github && <SocialLink href={objectForForm.github} target='_blank' rel='noopener noreferrer'><GitIcon /></SocialLink>}
                {objectForForm.extra && <SocialLink href={objectForForm.extra} target='_blank' rel='noopener noreferrer'><ExtraLinkIcon /></SocialLink>}
              </LinksContainer>
            </TextBox>
          </Container>

          {/* <UniversalButton
            onClick={() => { dispatch(openContactsForm(false)); }}
            type='button'
            textColor={Themes[theme].mainTextColor}
            backColor={Themes[theme].sliderColor}
            borderColor=''
            paddingLeft={44}
            paddingTop={20}>
            Закрыть
          </UniversalButton> */}
        </Form>
      );
    }
    default: {
      return (
        <Form data-cy='regConfirm'>
          <BlackDelIcon
            onClick={(e: any) => { e.preventDefault(); e.stopPropagation(); dispatch(openContactsForm(false)); }}
            style={{ position: 'absolute', top: '-10px', right: '-27px' }} />

          <Container>

            <ImageBox width={181} height={181}>
              <Image width={181} height={181} src={objectForForm.image} alt='аватарка' />
            </ImageBox>

            <TextBox>
              <H2>
                {objectForForm.name}
              </H2>
              <Wrapper>
                {objectForForm.email && <P>{objectForForm.email}</P>}
              </Wrapper>
              <LinksContainer>
                {objectForForm.tg && <SocialLink href={objectForForm.tg} target='_blank' rel='noopener noreferrer'><TgIcon /></SocialLink>}
                {objectForForm.vk && <SocialLink href={objectForForm.vk} target='_blank' rel='noopener noreferrer'><VkIcon /></SocialLink>}
                {objectForForm.github && <SocialLink href={objectForForm.github} target='_blank' rel='noopener noreferrer'><GitIcon /></SocialLink>}
                {objectForForm.extra && <SocialLink href={objectForForm.extra} target='_blank' rel='noopener noreferrer'><ExtraLinkIcon /></SocialLink>}
              </LinksContainer>
            </TextBox>
          </Container>

          {/*  <UniversalButton
            onClick={() => { dispatch(openContactsForm(false)); }}
            type='button'
            textColor={Themes[theme].mainTextColor}
            backColor={Themes[theme].sliderColor}
            borderColor=''
            paddingLeft={44}
            paddingTop={20}>
            Закрыть
          </UniversalButton> */}
        </Form>
      );
    }
  }
};

export default ContacsFormPopup;
