export const First = {
  mainText: 'Собери команду',
  paragraphsArray: [
    'Расскажи о своем проекте в ',
    'Опубликуй зарос на специалиста в ',
    'Найди единомышленников в ',
  ],
};

export const Second = {
  mainText: 'Присоединись к проекту',
  paragraphsArray: [
    'Заполни информацию о себе в ',
    'Расскажи о своем опыте в ',
    'Укажи свои компетенции в ',
    'Откликнись на понравившийся проект в ',
  ],
};

export const Third = {
  mainText: 'Создай портфолио',
  paragraphsArray: [
    'Расскажи о своих хобби в ',
    'Загрузи важные файлы в ',
    'Зафиксируй свой опыт в ',
  ],
};

export const levels = ['Начальный', 'Средний', 'Продвинутый'];
export const stages = [
  'Идея',
  'Концепция',
  'Прототип',
  'Выигран грант',
  'Выигран крупный конкурс',
  'MVP',
  'Пройдена акселерационная программа',
  'Первые продажи',
  'Привелечены венчурные инвестиции',
  'Есть весомый партнёр',
  'Есть крупный заказчик',
  'Масштабирование',
  'Вышли на международный уровень',
];
export const monetizationlevels = ['оплачивается', 'не оплачивается'];
export const arrForProfessionSearch = [
  'Backend-разработчик (JavaScript, Python, Ruby, Java, C#, Go и PHP)',
  'CV Engineer (Специалист по компьютерному зрению)',
  'Data engineer',
  'Data Scientist (Дата-сайентист)',
  'Frontend-разработчик (Html, CSS, JavaScript, React, Vue, TypeScript, Elm, Angular, JQuery, Swift)',
  'ML-разработчик',
  'Веб-дизайнер, UX/UI дизайнер',
  'Разработчик игр, гейм-дизайнер',
  'Разработчик приложения для iOS и Android',
  'Системный администратор (сетевой инженер)',
  'Специалист по информационной безопасности (ИБ)',
  'Тестировщик (QA engineer), автоматизатор',
  'Техлид (Tech Lead), DevOps-инженер',
  'Event-менеджер',
  'HR, рекрутер',
  'PR-менеджер, рекламный агент',
  'SMM-специалист, контент-менеджер',
  'Грантрайтер',
  'Делопроизводитель',
  'Копирайтер',
  'Креативный директор',
  'Логист',
  'Маркетолог',
  'Менеджер по продажам, менеджер по работе с клиентами',
  'Методолог',
  'Продакт-менеджер',
  'Проджект-менеджер ',
  'Продуктовый аналитик',
  'Секретарь, ассистент',
  'Спичрайтер',
  'Технический директор',
  'Дизайнер, web-дизайнер',
  'Актёр, аниматор',
  'Архитектор',
  'Блогер',
  'Видеомонтажер',
  'Визажист, гример',
  'Гид, экскурсовод',
  'Диджей',
  'Журналист',
  'Звукооператор, кинооператор',
  'Имиджмейкер, стилист',
  'Искусствовед',
  'Модельер',
  'Музыкант, дирижер',
  'Мультипликатор',
  'Писатель',
  'Режиссер, сценарист',
  'Танцор, хореограф',
  'Фотограф, ретушер',
  'SEO-специалист',
  'Аналитик данных ',
  'Бизнес-аналитик',
  'Брокер',
  'Налоговый консультант',
  'Системный аналитик',
  'Специалист-безопасник',
  'Финансист, бухгалтер',
  'Финансовый, инвестиционный аналитик',
  'Экономист',
  'Юрист',
  'Агроном, флорист',
  'Астроном, метеоролог',
  'Биолог',
  'Виновед, винодел',
  'Вирусолог',
  'Востоковед',
  'Врач (любой и смежные профессии: диетолог, косметолог, массажист…)',
  'Географ',
  'Геодезист',
  'Геолог',
  'Зоолог, ветеринар, грумер',
  'Криминалист',
  'Математик, физик, химик',
  'Переводчик, лингвист',
  'Политолог, политтехнолог',
  'Преподаватель, педагог дополнительного образования ',
  'Психолог, нейробиолог',
  'Социолог',
  'Спортсмен, тренер',
  'Фармацевт',
  'Филолог',
  'Эколог',
  'Этнограф',
  '3d-модельер',
  'Биотехнолог, биоинженер',
  'Генный инженер, генетик',
  'Инженер в машиностроении',
  'Инженер в строительстве',
  'Инженер по связи и телекоммуникациям',
  'Инженер-конструктор',
  'Инженер-проектировщик',
  'Кондитер, повар',
  'Лаборант',
  'Промышленный дизайнер',
  'Реставратор',
  'Сварщик',
  'Специалист по металлообработке',
  'Специалиста по метрологии, стандартизации и сертификации',
  'Технолог пищевого производства',
  'Технолог по нефтепереработке',
  'Товаровед',
  'Швея, портной, закройщик',
  'Энергетик',
];

export const arrForDirectionsSearch = [
  'Волонтерство',
  'Исследования в IT',
  'Краеведение, туризм',
  'Новые онлайн и оффлайн пространства',
  'Образование, профориентация',
  'Патриотизм, история',
  'Работа с инклюзивностью',
  'Развитие сообществ',
  'Семья (ценности, проблемы, взаимоотношения)',
  'Урбанистика, благоустройство',
  'Биотехнология',
  'Военная промышленность',
  'Добывающая и перерабатывающая промышленность',
  'Исследования в культуре',
  'Космические технологии',
  'Металлургия',
  'Машиностроение',
  'Перерабатывающая промышленность',
  'Пищевая промышленность',
  'Строительство и производство материалов',
  'Телекоммуникации',
  'Фармацевтическая промышленность',
  'Химическая промышленность',
  'Электрика',
  'Электроника, микроэлектроника и нанотехнологии',
  'Электроэнергетика (гидро-, ветро-, атомная, геотермальная)',
  'Инновационное предпринимательство',
  'Исследования в медицине',
  'Коммерческо-торговое предпринимательство',
  'Посредническое предпринимательство',
  'Производственное предпринимательство',
  'Социальное предпринимательство',
  'Страховое предпринимательство',
  'Технологическое предпринимательство',
  'Финансово-кредитное предпринимательство',
  'Борьба с вредными привычками',
  'Инновационные технологии',
  'Исследования в предпринимательстве',
  'Лечение',
  'Первая помощь',
  'Реабилитаци',
  'Архитектура ',
  'Дизайн и мода',
  'Живопись и графика',
  'Исследования в промышленности',
  'Литература, поэзия',
  'Медиа, СМИ, журналистика',
  'Музыка',
  'Развлечения',
  'Театр и кино',
  'Фото и видео',
  'Хореография',
  'Автоматизация, оптимизация, цифровизация решений',
  'Анализ данных',
  'Искусственный интеллект',
  'Исследования в социальной сфере',
  'Кибербезопасность',
  'Мобильная разработка',
  'Облачные технологии',
  'Разработка игр',
  'Разработка программного обеспечения',
  'Робототехника',
  'Сбор команд для участия в хакатонах и геймджемах',
  'VR, AR',

];

export const professions = [
  {
    name: 'ИТ-разработка',
    array: ['Backend-разработчик (JavaScript, Python, Ruby, Java, C#, Go и PHP)',
      'CV Engineer (Специалист по компьютерному зрению)',
      'Data engineer',
      'Data Scientist (Дата-сайентист)',
      'Frontend-разработчик (Html, CSS, JavaScript, React, Vue, TypeScript, Elm, Angular, JQuery, Swift)',
      'ML-разработчик',
      'Веб-дизайнер, UX/UI дизайнер',
      'Разработчик игр, гейм-дизайнер',
      'Разработчик приложения для iOS и Android',
      'Системный администратор (сетевой инженер)',
      'Специалист по информационной безопасности (ИБ)',
      'Тестировщик (QA engineer), автоматизатор',
      'Техлид (Tech Lead), DevOps-инженер',
    ],
  },
  {
    name: 'Упаковка продукта, менеджмент продукта, оптимизация процессов',
    array: ['Event-менеджер',
      'HR, рекрутер',
      'PR-менеджер, рекламный агент',
      'SMM-специалист, контент-менеджер',
      'Грантрайтер',
      'Делопроизводитель',
      'Копирайтер',
      'Креативный директор',
      'Логист',
      'Маркетолог',
      'Менеджер по продажам, менеджер по работе с клиентами',
      'Методолог',
      'Продакт-менеджер',
      'Проджект-менеджер ',
      'Продуктовый аналитик',
      'Секретарь, ассистент',
      'Спичрайтер',
      'Технический директор'],
  },
  {
    name: 'Креативные индустрии, искусство, развлечения',
    array: [
      'Дизайнер, web-дизайнер',
      'Актёр, аниматор',
      'Архитектор',
      'Блогер',
      'Видеомонтажер',
      'Визажист, гример',
      'Гид, экскурсовод',
      'Диджей',
      'Журналист',
      'Звукооператор, кинооператор',
      'Имиджмейкер, стилист',
      'Искусствовед',
      'Модельер',
      'Музыкант, дирижер',
      'Мультипликатор',
      'Писатель',
      'Режиссер, сценарист',
      'Танцор, хореограф',
      'Фотограф, ретушер',

    ],
  },
  {
    name: 'Экономика-финансы, аналитика, юридические услуги',
    array: [
      'SEO-специалист',
      'Аналитик данных ',
      'Бизнес-аналитик',
      'Брокер',
      'Налоговый консультант',
      'Системный аналитик',
      'Специалист-безопасник',
      'Финансист, бухгалтер',
      'Финансовый, инвестиционный аналитик',
      'Экономист',
      'Юрист',

    ],
  },
  {
    name: 'Специалисты в сфере науки',
    array: [
      'Агроном, флорист',
      'Астроном, метеоролог',
      'Биолог',
      'Виновед, винодел',
      'Вирусолог',
      'Востоковед',
      'Врач (любой и смежные профессии: диетолог, косметолог, массажист…)',
      'Географ',
      'Геодезист',
      'Геолог',
      'Зоолог, ветеринар, грумер',
      'Криминалист',
      'Математик, физик, химик',
      'Переводчик, лингвист',
      'Политолог, политтехнолог',
      'Преподаватель, педагог дополнительного образования ',
      'Психолог, нейробиолог',
      'Социолог',
      'Спортсмен, тренер',
      'Фармацевт',
      'Филолог',
      'Эколог',
      'Этнограф',

    ],
  },
  {
    name: 'Специалисты техническо-промышленной сферы',
    array: [
      '3d-модельер',
      'Биотехнолог, биоинженер',
      'Генный инженер, генетик',
      'Инженер в машиностроении',
      'Инженер в строительстве',
      'Инженер по связи и телекоммуникациям',
      'Инженер-конструктор',
      'Инженер-проектировщик',
      'Кондитер, повар',
      'Лаборант',
      'Промышленный дизайнер',
      'Реставратор',
      'Сварщик',
      'Специалист по металлообработке',
      'Специалиста по метрологии, стандартизации и сертификации',
      'Технолог пищевого производства',
      'Технолог по нефтепереработке',
      'Товаровед',
      'Швея, портной, закройщик',
      'Энергетик',

    ],
  },
];

export const projectDirections = [
  {
    name: 'Информационные технологии (IT)',
    array: [
      'Автоматизация, оптимизация, цифровизация решений',
      'Анализ данных',
      'Искусственный интеллект',
      'Исследования в IT',
      'Кибербезопасность',
      'Мобильная разработка',
      'Облачные технологии',
      'Разработка игр',
      'Разработка программного обеспечения',
      'Робототехника',
      'Сбор команд для участия в хакатонах и геймджемах',
      'VR, AR',

    ],
  },
  {
    name: 'Культура и творчество',
    array: [
      'Архитектура ',
      'Дизайн и мода',
      'Живопись и графика',
      'Исследования в культуре',
      'Литература, поэзия',
      'Медиа, СМИ, журналистика',
      'Музыка',
      'Развлечения',
      'Театр и кино',
      'Фото и видео',
      'Хореография',

    ],
  },
  {
    name: 'Медицина',
    array: [
      'Борьба с вредными привычками',
      'Инновационные технологии',
      'Исследования в медицине',
      'Лечение',
      'Первая помощь',
      'Реабилитаци',

    ],
  },
  {
    name: 'Предпринимательство',
    array: [
      'Инновационное предпринимательство',
      'Исследования в предпринимательстве',
      'Коммерческо-торговое предпринимательство',
      'Посредническое предпринимательство',
      'Производственное предпринимательство',
      'Социальное предпринимательство',
      'Страховое предпринимательство',
      'Технологическое предпринимательство',
      'Финансово-кредитное предпринимательство',

    ],
  },
  {
    name: 'Промышленность',
    array: [
      'Биотехнология',
      'Военная промышленность',
      'Добывающая и перерабатывающая промышленность',
      'Исследования в промышленности',
      'Космические технологии',
      'Металлургия',
      'Машиностроение',
      'Перерабатывающая промышленность',
      'Пищевая промышленность',
      'Строительство и производство материалов',
      'Телекоммуникации',
      'Фармацевтическая промышленность',
      'Химическая промышленность',
      'Электрика',
      'Электроника, микроэлектроника и нанотехнологии',
      'Электроэнергетика (гидро-, ветро-, атомная, геотермальная)',

    ],
  },
  {
    name: 'Социалка',
    array: [
      'Волонтерство',
      'Исследования в социальной сфере',
      'Краеведение, туризм',
      'Новые онлайн и оффлайн пространства',
      'Образование, профориентация',
      'Патриотизм, история',
      'Работа с инклюзивностью',
      'Развитие сообществ',
      'Семья (ценности, проблемы, взаимоотношения)',
      'Урбанистика, благоустройство',

    ],
  },
  {
    name: 'Спорт',
    array: [],
  },
  {
    name: 'Экология',
    array: [],
  },
  {
    name: 'Другое',
    array: [],
  },
];
