/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import Carousel from 'react-elastic-carousel';
import {
  TravelsFontMixixn20,
  ComfortaFontMixixn31,
  ComfortaFontMixixn39,
} from '../../constants/fontsConfigs';
import Image1 from '../../assets/1.png';
import Image2 from '../../assets/2.png';
import Image3 from '../../assets/6.png';
import Image4 from '../../assets/4.png';
import Image5 from '../../assets/5.png';
import Image6 from '../../assets/7.png';
import { PeachArrowIcon } from '../icons';

const TeamSection = styled.section`
  max-width: 1440px;
  width: 100%;
  padding-inline: 20px;
  display: flex;
  flex-direction: column;
  gap: 70px;
  margin-bottom: 150px;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  overflow: hidden;
  & button {
    background-color: rgba(243, 160, 59, 0.5);
    color:${({ theme: { sliderColor } }) => sliderColor};
    box-shadow: none;
  }
  & button:hover:enabled {
    background-color: rgba(243, 160, 59, 0.5);
    color:${({ theme: { sliderColor } }) => sliderColor};
    box-shadow: none;
  }
  & button:enabled {
    background-color: rgba(243, 160, 59, 0.5);
    color:${({ theme: { sliderColor } }) => sliderColor};
    box-shadow: none;
  }
  & button:hover {
    background-color: rgba(243, 160, 59, 0.5);
    color:${({ theme: { sliderColor } }) => sliderColor};
    box-shadow: none;
  }
  & button:active {
    background-color: rgba(243, 160, 59, 0.5);
    color:${({ theme: { sliderColor } }) => sliderColor};
    box-shadow: none;
  }
  & button:focus:enabled {
    background-color: rgba(243, 160, 59, 0.5);
    color:${({ theme: { sliderColor } }) => sliderColor};
    box-shadow: none;
  }
`;

const TeamSectionHeader = styled.h2`
  color: ${({ theme: { mainButtonColor } }) => mainButtonColor};
  ${ComfortaFontMixixn39}
  margin: 0;
  -webkit-text-stroke-width: medium;
`;

const TeamSectionSliderContiner = styled.ul<{ marginLeft: number }>`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 75px;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  transition: margin-left 250ms;
  background-color: ${({ theme: { mainBg } }) => mainBg};
  max-width: 1250px;
  width: 100%;
`;

const TeamSectionCardHeader = styled.h3`
   color: ${({ theme: { mainButtonColor } }) => mainButtonColor};
   ${ComfortaFontMixixn31}
   margin: 0;
   -webkit-text-stroke-width: medium;
`;

const TeamSectionP = styled.p`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn20}
  margin: 0;
  text-align: center;
`;

const TeamSectionSliderItem = styled.li`
  position: relative;
  max-width: 360px;
  overflow: hidden;
  width: 100%;
  flex-shrink: 0;
  height: 409px;
  border-radius: 37.75px;
  border: ${({ theme: { subMainBg } }) => `${subMainBg} 3px solid`};
  flex-direction: column;
  display:  flex;
  transition: .5s;
  align-items: center;
  gap: 12px;
  @media screen and (max-width:1360px) {
    flex-shrink: 1;
  }
`;

const TeamSectionSliderElTop = styled.div`
  width: 100%;
  height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { subMainBg } }) => subMainBg};
  box-shadow: 0px 0px 30.2px 0px rgba(0, 0, 0, 0.10);

`;

const SliderImage = styled.img`
  width: 182px;
  height: 232px;
  object-fit: cover;
`;

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme: { mainBg } }) => `${mainBg} 3px solid`};
`;

const WrappperForAll = styled.div`
  display: flex;
  overflow: hidden;
  max-width: 1370px;
  width: 100%;
  align-items: center;
`;
const SliderNode: FC<{ img: string, name: string, profession: string }> = ({ img, name, profession }) => (
  <TeamSectionSliderItem>
    <TeamSectionSliderElTop>
      <SliderImage src={img} alt='здесь крутые ребята' />
    </TeamSectionSliderElTop>
    <TeamSectionCardHeader>{name}</TeamSectionCardHeader>
    <TeamSectionP>{profession}</TeamSectionP>
  </TeamSectionSliderItem>
);

const SliderForLanding: FC = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    {
      width: 550, itemsToShow: 1, itemsToScroll: 1, pagination: false,
    },
    { width: 850, itemsToShow: 2, pagination: false },
    {
      width: 1250, itemsToShow: 3, itemsToScroll: 1, pagination: false,
    },
    { width: 1450, itemsToShow: 6, pagination: false },
    { width: 1750, itemsToShow: 6, pagination: false },
  ];
  const [isChanged, changeSlides] = useState(true);
  const [cardWidth, setCardWidth] = useState<number>(860);
  const [count, setCount] = useState<number>(1);
  const [position, setPosition] = useState<number>(0);
  const prevMethod = () => {
    const newPosition = cardWidth * count + position;
    setPosition(Math.min(newPosition, 0));
  };
  const nextMethod = () => {
    const newPosition = position - cardWidth * count;
    setPosition(Math.min(newPosition, 0));
  };
  return (
    <TeamSection>
      <TeamSectionHeader>Команда</TeamSectionHeader>
      {/* <WrappperForAll>
        <PeachArrowIcon style={{ transform: 'rotate(180deg)', zIndex: '5', flexShrink: '0' }} onClick={() => prevMethod()} />
        <Wrapper> */}
      <Carousel
        enableAutoPlay
        autoPlaySpeed={10000}
        isRTL={false}
        itemPadding={[10, 30]}
        breakPoints={breakPoints}>
        <SliderNode img={Image2} name='Ермакова Ариадна' profession='продакт-менеджер' />
        <SliderNode img={Image5} name='Куркина Татьяна' profession='тимлид' />
        <SliderNode img={Image4} name='Уланова Мария' profession='методолог разработки структуры' />
        <SliderNode img={Image1} name='Зайченко Евгения' profession='дизайнер' />
        <SliderNode img={Image3} name='Трубачев Георгий' profession='фронтэнд-разработчик' />
        <SliderNode img={Image6} name='Морозов Александр' profession='бэкенд-разработчик' />
      </Carousel>
      {/*  </Wrapper>
        <PeachArrowIcon style={{ zIndex: '5', flexShrink: '0' }} onClick={() => nextMethod()} />
      </WrappperForAll> */}
    </TeamSection>
  );
};

export default SliderForLanding;
