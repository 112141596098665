/* eslint-disable object-shorthand */
/* eslint-disable ternary/no-dupe */
/* eslint-disable react/require-default-props */
import React, {
  FC, memo, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from '../store/store.types';
import { ImageBox, Image } from './widgets/NewAvatarWithText';
import { TravelsFontMixixn24, TravelsFontMixixn30, TravelsFontMixixn20 } from '../constants/fontsConfigs';
import { TSkill } from '../types/apiTypes';
import { useOnScreen } from '../helpers/hooks';
import { UniversalButton } from './RestyledButtons';
import Themes from '../themes';
import { useDeclineProjectMutation, useGetSpecsWantedToProjectQuery, useSendContactsMutation } from '../api/api';
import {
  openContactsForm, openFolderDeletePopup, setContactFormType, setFolderId, setObjectForForm, setProjectId,
} from '../store/allSlice';
import { ClassSvg } from './icons';
/// без кнопки
/// с кнопкой пригласить и отказать
/// только одна кнопка

const Plate = styled.div<{ status: string }>`
    position: relative;
    width: 100%;
    padding: 25px;
    background-color: ${({ status, theme: { plateColor } }) => (status === 'accepted' ? '#dee5ff' : plateColor)};
    border-radius: 20px;
    border: ${({ theme: { mainButtonColor } }) => `1px solid ${mainButtonColor}`};
    display: flex;
    gap: 19px;
    box-sizing: border-box;
    & button:first-of-type {
      background-color: ${({ status, theme: { plateColor } }) => (status === 'accepted' ? '#dee5ff' : plateColor)};
    }
    &:hover {
        box-shadow: ${({ theme: { mainButtonColor } }) => `0px 0px 20px 0px ${mainButtonColor}`};
        cursor: pointer;
    }
`;

const Wrapper = styled.div`
  display: flex;
    gap: 10px;
    width: max-content;
    height: 54px;
    align-self: flex-end;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    justify-content: end;
    position: relative;
    left: -45px;
`;

const ContentBox = styled.div`
  display: flex ;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
`;

const P = styled.p`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn24}
`;

const Span = styled.span`
height: 57px;
  color: ${({ theme: { sliderColor } }) => sliderColor};
  ${TravelsFontMixixn24}
  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;
    @media screen and (max-width: 1320px) {
      width: 290px;
    }
`;

const RoundSpan = styled.span`
  display: flex;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border:${({ theme: { sliderColor } }) => `${sliderColor} 1px solid`};
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn20}
`;

const TagBox = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  gap: 10px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 26px;
    height: 100%;
    box-shadow: inset -38px -8px 15px -15px rgb(241, 244, 255);
  }
`;

const RoundTagBlock = styled.li`
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
  border-radius: 100px;
  background-color: ${({ theme: { sliderColor } }) => sliderColor};
  ${TravelsFontMixixn20}
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  word-break: break-all;
  flex-shrink: 0;
  `;

const NewPlateForSpec: FC<{
  image: string,
  name: string,
  specislity: string,
  level: string,
  onClick: () => void,
  hardSkills?: TSkill[];
  setIntersecting?: any,
  withOneButton?: boolean,
  widthTwoButton?: boolean,
  userId?: number,
  status?: string,
  projectId?: number,
  func?: (e: any) => void,
  tel?: number,
  vk?: string,
  tg?: string,
  gitHub?: string,
  email?: string,
  extra?: string,
}> = ({
  tel,
  vk,
  tg,
  gitHub,
  email,
  extra,
  setIntersecting,
  image,
  func,
  onClick,
  userId,
  status,
  projectId,
  name,
  specislity,
  withOneButton = false,
  widthTwoButton = false,
  level,
  hardSkills,
}) => {
  const { theme, objectForForm } = useSelector((state) => state.all);
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const intersectingState = useOnScreen(ref);
  const [sendContacts] = useSendContactsMutation();
  const { data, refetch } = useGetSpecsWantedToProjectQuery();
  const [declineProject] = useDeclineProjectMutation();
  useEffect(() => {
    setIntersecting && setIntersecting(intersectingState);
  }, [intersectingState]);

  const sendContactsFunc = async (e: any) => {
    e.stopPropagation();
    await sendContacts({ userId: userId!, id: projectId! });
    await refetch();
  };
  const declineProjectFunc = async (e: any) => {
    e.stopPropagation();
    await declineProject({ userId: userId!, id: projectId! });
  };
  console.log(objectForForm);
  return (
    <Plate status={status!} ref={ref} onClick={onClick}>
      {status === 'accepted' && <ClassSvg />}
      <ImageBox width={150} height={150}>
        <Image width={100} height={100} src={image} alt='аватарка' />
      </ImageBox>
      <ContentBox>
        <P>
          {name}
        </P>
        <Span>
          {specislity}
        </Span>
        <RoundSpan>
          {level}
        </RoundSpan>
        <TagBox>

          {!withOneButton && hardSkills?.map((el, index) => (
            <RoundTagBlock>
              {el.name}
            </RoundTagBlock>
          ))}

        </TagBox>
        {withOneButton
            && (
              status === 'accepted'
                ? (
                  <UniversalButton
                    onClick={(e) => {
                      e.preventDefault(); e.stopPropagation();
                      dispatch(openContactsForm(true)); dispatch(setContactFormType(1)); dispatch(setObjectForForm({
                        ...objectForForm,
                        tel: null,
                        name: name,
                        email: email,
                        vk: vk,
                        github: gitHub,
                        tg: tg,
                        extra: extra,
                        image: image,
                      }));
                    }}
                    style={{ alignSelf: 'end', marginTop: 'auto' }}
                    type='button'
                    disabled={false}
                    textColor={Themes[theme].mainTextColor}
                    backColor={Themes[theme].plateColor}
                    borderColor={Themes[theme].mainButtonColor}
                    paddingLeft={35}
                    paddingTop={15}>
                    Показать контакты
                  </UniversalButton>
                )
                : (
                  <UniversalButton
                    onClick={func}
                    style={{ alignSelf: 'end', marginTop: 'auto' }}
                    type='button'
                    disabled={false}
                    textColor={Themes[theme].mainTextColor}
                    backColor={Themes[theme].plateColor}
                    borderColor={Themes[theme].mainButtonColor}
                    paddingLeft={35}
                    paddingTop={15}>
                    Отозвать запрос
                  </UniversalButton>
                )
            )}
        {widthTwoButton
            && (
              status === 'accepted'
                ? (
                  <UniversalButton
                    onClick={(e) => {
                      e.preventDefault(); e.stopPropagation();
                      dispatch(openContactsForm(true)); dispatch(setContactFormType(1)); dispatch(setObjectForForm({
                        ...objectForForm,
                        tel: null,
                        name: name,
                        email: email,
                        vk: vk,
                        github: gitHub,
                        tg: tg,
                        extra: extra,
                        image: image,
                      }));
                    }}
                    style={{ alignSelf: 'end', marginTop: 'auto' }}
                    type='button'
                    disabled={false}
                    textColor={Themes[theme].mainTextColor}
                    backColor={Themes[theme].plateColor}
                    borderColor={Themes[theme].mainButtonColor}
                    paddingLeft={35}
                    paddingTop={15}>
                    Показать контакты
                  </UniversalButton>
                )
                : (
                  <Wrapper>
                    <UniversalButton
                      onClick={(e) => { e.stopPropagation(); dispatch(openFolderDeletePopup(true)); dispatch(setFolderId(projectId!)); dispatch(setProjectId(userId!)); }}
                      style={{ alignSelf: 'end', marginTop: 'auto' }}
                      type='button'
                      textColor={Themes[theme].mainTextColor}
                      backColor={Themes[theme].plateColor}
                      borderColor={Themes[theme].mainButtonColor}
                      paddingLeft={35}
                      paddingTop={15}>
                      Отказать
                    </UniversalButton>
                    <UniversalButton
                      onClick={sendContactsFunc}
                      style={{ alignSelf: 'end', marginTop: 'auto' }}
                      type='button'
                      textColor={Themes[theme].mainTextColor}
                      backColor={Themes[theme].sliderColor}
                      borderColor='transparent'
                      paddingLeft={35}
                      paddingTop={15}>
                      Пригласить в команду
                    </UniversalButton>
                  </Wrapper>
                )
            )}
      </ContentBox>

    </Plate>
  );
};

export default memo(NewPlateForSpec);
