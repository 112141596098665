/* eslint-disable ternary/nesting */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lone-blocks */
import React, {
  ChangeEvent,
  FC,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from '../store/store.types';
import {
  setRequestUser,
  openFullProjectViewPopup,
} from '../store/allSlice';
import { TravelsFontMixixn24, TravelsFontMixixn20 } from '../constants/fontsConfigs';

import { UniversalButton } from './RestyledButtons';

import Themes from '../themes';
import {
  useNewGetCurrentUserQuery,
  useGetUserProjectByIdQuery,
  useRespondToProjectMutation,
  useGetBackProjectResponseMutation,
  useIGetResponseToProjectBackMutation,
} from '../api/api';
import NewAvatarWithText from './widgets/NewAvatarWithText';
import { LinkToProject } from './icons';
import { SpecSmallPlate } from './ProjectPlateRedisined/ProjectPlateCustom';
import { LinkForFileLoad } from '../pages/ProfileAbout';
import star from '../assets/smallStar.svg';

const Plate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: ${({ theme: { mainButtonColor } }) => `${mainButtonColor} 0.73px solid`};
  border-radius: 20px;
  max-width: 1370px;
  width: 100%;
  background-color: ${({ theme: { mainBg } }) => mainBg};
  padding: 31px 20px;
  box-sizing: border-box;
`;

const H2 = styled.h2`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn24}
  margin:0;
`;

const H3 = styled.h3`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn20}
  font-weight: 700;
  margin:0;
`;

const P = styled.p`
    width: 100%;
    ${TravelsFontMixixn20}
    margin: 0;
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
`;

const CustomLink = styled(Link)`
  ${TravelsFontMixixn20}
  font-weight: 700;
  color: ${({ theme: { mainButtonColor } }) => mainButtonColor};
  text-decoration: none;
  display: flex;
  gap: 20px;
  & path {
    stroke: ${({ theme: { mainButtonColor } }) => mainButtonColor};
  }
`;

const LinkContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
`;

const SpecList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 31px;
`;

const SpecItem = styled.li`
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 100%;
`;

const FullInfoAboutProjectPopup: FC<{ stat: string }> = ({ stat }) => {
  const { theme, specCreatePopupHeading, projectId } = useSelector((state) => state.all);
  const { data: user, isLoading: loading } = useNewGetCurrentUserQuery();
  const dispatch = useDispatch();
  const [isRequestSend, setRequetsStatus] = useState(false);
  const [respond] = useRespondToProjectMutation();
  const [getRespondBack] = useIGetResponseToProjectBackMutation();
  const location = useLocation();
  console.log(location);
  const { data } = useGetUserProjectByIdQuery(projectId!);
  const respondToProject = async () => {
    if (isRequestSend) {
      await getRespondBack({ userId: user?.id!, projectId: projectId! });
      dispatch(setRequestUser(Date.now()));
      dispatch(openFullProjectViewPopup(false));
      toast.success('Отклик отозван');
      return;
    }
    await respond(projectId!);
    toast.success('Отклик отправлен');
    dispatch(openFullProjectViewPopup(false));
    dispatch(setRequestUser(Date.now()));
  };
  useEffect(() => {
    setRequetsStatus(user?.responded_projects?.indexOf(projectId!)! > -1);
  }, [user]);
  useEffect(() => {
    if (location.pathname === `/projects/${projectId}`) {
      dispatch(openFullProjectViewPopup(false));
    }
  }, [location]);
  return (
    <Plate>
      <NewAvatarWithText
        needSquareImage
        width={data?.project_logo ? 100 : 56}
        height={data?.project_logo ? 100 : 56}
        extraLink={data?.project_link || ''}
        image={data?.project_logo || star}
        header={data?.project_name || ''}
        stage={data?.project_stage || ''}
        direction={data?.project_direction || ''} />
      <H2>О проекте</H2>
      <H3>Идея проекта</H3>
      <P>{data?.project_idea}</P>
      <H2>Требуемые специалисты</H2>
      <SpecList>
        {data?.required_specialists.map((el) => (
          <SpecItem>
            <SpecSmallPlate style={{ whiteSpace: 'break-spaces', height: 'auto' }}>{el.specialist_name}</SpecSmallPlate>
            <H3>Уровень</H3>
            <P>{el.specialist_level}</P>
            <H3>Задача</H3>
            <P>{el.task}</P>
          </SpecItem>
        ))}
      </SpecList>
      <CustomLink to={`/projects/${data?.id}`}>
        Ссылка на подробную информацию о проекте
        <LinkToProject />
      </CustomLink>
      <LinkContainer>
        {data?.market_assessment_file && (
          <LinkForFileLoad style={{ width: 'fit-content', textDecoration: 'none' }} download href={data?.market_assessment_file}>
            {data?.market_assessment_file_name}
            ,
          </LinkForFileLoad>
        )}

        {data?.competitors_file
          && (
            <LinkForFileLoad style={{ width: 'fit-content', textDecoration: 'none' }} download href={data?.competitors_file}>
              {data?.competitors_file_name}
              ,
            </LinkForFileLoad>
          )}

        {data?.financial_model_file
          && (
            <LinkForFileLoad style={{ width: 'fit-content', textDecoration: 'none' }} download href={data?.financial_model_file}>
              {data?.financial_model_file_name}
              ,
            </LinkForFileLoad>
          )}
        {data?.road_map_file
          && (
            <LinkForFileLoad style={{ width: 'fit-content', textDecoration: 'none' }} download href={data?.road_map_file}>
              {data?.road_map_file_name}
            </LinkForFileLoad>
          )}
      </LinkContainer>
      {data?.user_id !== user?.id && location.pathname !== '/responses-projects'
        && (
          <UniversalButton
            onClick={respondToProject}
            style={{ alignSelf: 'end', marginTop: 'auto' }}
            type='button'
            disabled={isRequestSend && location.pathname !== '/my-responses'}
            textColor={Themes[theme].mainTextColor}
            backColor={isRequestSend ? Themes[theme].mainBg : Themes[theme].sliderColor}
            borderColor={isRequestSend ? Themes[theme].mainButtonColor : 'transparent'}
            paddingLeft={35}
            paddingTop={15}>
            {!isRequestSend ? 'Откликнуться' : location.pathname === '/my-responses' ? 'Отозвать запрос' : 'Отправлен отклик'}
          </UniversalButton>
        )}
    </Plate>
  );
};

export default FullInfoAboutProjectPopup;
