/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  Routes, Route, useLocation, useNavigate,
} from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { Transition } from 'react-transition-group';
import Themes from '../themes';
import HeaderElement from '../ui-lib/widgets/Header';
import FooterElement from '../ui-lib/widgets/Footer';
import { routes } from '../constants/routes';
import {
  setTheme,
  setAppSuccess,
  openCreateProfessionPopup,
  openImagePopupOpenInProfileAbout,
  openSpecCreatePopup,
  setProfId,
  setHeadingForPopup,
  openFullProjectViewPopup,
  openContactsForm,
  openSpecResumePopup,
} from '../store/allSlice';
import {
  useLazyNewGetCurrentUserQuery,
  jwt,
  useRespondToProjectMutation,
  useActivateUserMutation,
} from '../api/api';
import { useSelector, useDispatch } from '../store/store.types';
import ProtectedRoute from '../ui-lib/ProtectedRoute';
import Registration from '../pages/Registration';
import Login from '../pages/Login';
import MainLandingPage from '../pages/MainLandingPage';
import ResponseModal from '../ui-lib/ResponseModal';
import BigImageSlider from '../ui-lib/widgets/BigImageSlider';
import TeamList from '../pages/TeamList';
import DeleteFolderModal from '../ui-lib/DeleteFolderModal';
import WindowList from '../pages/WindowList';
import ExpertsResponses from '../pages/ExpertsResponses';
import ConfirmPassPopup from '../ui-lib/widgets/ConfirmPopup';
import CreateSpecforProject from '../ui-lib/widgets/CreateSpecForProject';
import ImageInBigScreen from '../ui-lib/widgets/ImageInBigScreen';
import { UniversalPopupPlateWithCross } from '../ui-lib/Popup';
import CreateProfessionForm from '../ui-lib/widgets/CreateProfessionForm';
import defaultAva from '../assets/dedaultAvaImg.png';
import NewModalCustom from '../ui-lib/widgets/NewModal';
import ContacsFormPopup from '../ui-lib/widgets/ContactsFormPopup';
import FullInfoAboutProjectPopup from '../ui-lib/FullInfoAboutprojectPopup';
import AttentionPopup from '../ui-lib/widgets/AttentionPopup';
import AttentionPopupProjects from '../ui-lib/widgets/AttentionPopupProjects';
import Settings from '../pages/Settings';
import ResetPassword from '../pages/ResetPassword';
import EmailSendPopup from '../ui-lib/widgets/EmailSendPopup';
import ChoosePopup from '../ui-lib/widgets/ChoosePopup';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  background-color: ${({ theme: { mainBg, subMainBg } }) => mainBg}; 
  width: 100%;
  align-items: center;
  min-height: calc(100vh - 85px);

`;

const App = () => {
  const [colorState, setColorState] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, overlayLength, type } = useSelector((state) => state.all);
  const dispatch = useDispatch();
  const {
    isExit,
    isFetchSuccess,
    currentFilter,
    isResponsesModalOpen,
    isBigPopupOpen,
    isDeleteFolderModalOpen,
    isRedactFolderPopupOpen,
    isCreateFolderModalOpen,
    isProfessionCreatePopupOpen,
    isConfirmOpen,
    isImagePopupOpenInProfileAbout,
    isSpecCreatePopupOpen,
    requestUser,
    isFullProjectViewPopupOpen,
    isContactsFormOpen,
    pair,
    isEmailSendPopupOpen,
    isSpecResumePopupOpen,
    isResponseToProjectPopupOpen,
    isChoosePopupOpen,
  } = useSelector((state) => state.all);
  console.log(isSpecCreatePopupOpen);
  const [respond, { data: test, isLoading, status }] = useRespondToProjectMutation();
  const [getCurrentUser, { data }] = useLazyNewGetCurrentUserQuery();
  const [activate] = useActivateUserMutation();
  const { pathname } = useLocation();
  const getUser = async () => {
    if (jwt.test()) {
      try {
        console.log(1234556);
        await getCurrentUser();
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    const localTheme = localStorage.getItem('theme');
    if (localTheme) {
      dispatch(setTheme(localTheme as 'light' | 'dark'));
    }
  }, []);

  useEffect(() => {
    getUser();
  }, [isExit, jwt.test()]);

  const activateUser = async () => {
    const urlArr = location.hash.split('/');
    if (urlArr[1] === 'activate') {
      await activate({ uid: urlArr[2], token: urlArr[3] });
      toast.success('Учетная запись активирована');
      navigate('/login');
    }
  };

  const resetPaswordRedirect = async () => {
    const urlArr = location.hash.split('/');
    if (urlArr[1] === 'password') {
      navigate(`/reset/${urlArr[4]}/${urlArr[5]}`);
    }
  };

  useEffect(() => {
    resetPaswordRedirect();
  }, []);

  useEffect(() => {
    activateUser();
  }, []);

  return (
    <ThemeProvider theme={Themes[theme]}>

      <HeaderElement userAvatar={data?.image || defaultAva} isColourChange={colorState} />
      <Main id='main'>
        <React.Suspense>

          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/registration' element={<Registration />} />
            <Route path='/' element={<MainLandingPage />} />
            <Route path='/projects-window' element={<WindowList />} />
            <Route path='/team-list' element={<TeamList />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/reset/:id/:nte' element={<ResetPassword />} />
            {routes.map(({ path, Component }, index) => (
              <Route element={<ProtectedRoute />}>
                <Route key={index} path={path} element={Component} />
              </Route>
            ))}

          </Routes>

        </React.Suspense>

        <Transition unmountOnExit in={isChoosePopupOpen} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>
              <ChoosePopup />
            </NewModalCustom>
          )}
        </Transition>

        <Transition unmountOnExit in={isEmailSendPopupOpen} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>
              <EmailSendPopup />
            </NewModalCustom>
          )}
        </Transition>

        <Transition unmountOnExit in={isResponseToProjectPopupOpen} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>

              <AttentionPopupProjects prof={pair.prof} proj={pair.proj} />

            </NewModalCustom>
          )}
        </Transition>

        <Transition unmountOnExit in={isSpecResumePopupOpen} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>

              <AttentionPopup prof={pair.prof} proj={pair.proj} />

            </NewModalCustom>
          )}
        </Transition>

        <Transition unmountOnExit in={isContactsFormOpen} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>
              <ContacsFormPopup />
            </NewModalCustom>
          )}
        </Transition>

        <Transition unmountOnExit in={isSpecCreatePopupOpen} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>
              <UniversalPopupPlateWithCross propForAnimate={state} close={() => { dispatch(openSpecCreatePopup(false)); dispatch(setHeadingForPopup('')); }}>
                <CreateSpecforProject status={state} />
              </UniversalPopupPlateWithCross>
            </NewModalCustom>
          )}
        </Transition>
        <Transition unmountOnExit in={isConfirmOpen} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>
              <ConfirmPassPopup />
            </NewModalCustom>
          )}
        </Transition>
        <Transition unmountOnExit in={isFullProjectViewPopupOpen} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>
              <UniversalPopupPlateWithCross propForAnimate={state} close={() => { dispatch(openFullProjectViewPopup(false)); }}>
                <FullInfoAboutProjectPopup stat={state} />
              </UniversalPopupPlateWithCross>
            </NewModalCustom>
          )}
        </Transition>
        <Transition unmountOnExit in={isResponsesModalOpen} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>
              <ResponseModal />
            </NewModalCustom>
          )}
        </Transition>
        <Transition unmountOnExit in={isDeleteFolderModalOpen} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>
              <DeleteFolderModal type={type!} />
            </NewModalCustom>
          )}
        </Transition>
        <Transition unmountOnExit in={isBigPopupOpen} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>
              <BigImageSlider />
            </NewModalCustom>
          )}
        </Transition>
        <Transition unmountOnExit in={isImagePopupOpenInProfileAbout} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>
              <ImageInBigScreen close={openImagePopupOpenInProfileAbout} />
            </NewModalCustom>
          )}
        </Transition>
        <Transition unmountOnExit in={isProfessionCreatePopupOpen} timeout={300}>
          {(state) => (
            <NewModalCustom animatedProp={state}>
              <UniversalPopupPlateWithCross propForAnimate={state} close={() => { dispatch(openCreateProfessionPopup(false)); dispatch(setProfId(null)); }}>
                <CreateProfessionForm status={state} />
              </UniversalPopupPlateWithCross>
            </NewModalCustom>
          )}
        </Transition>
      </Main>
      {location.pathname === '/' && <FooterElement />}
    </ThemeProvider>
  );
};

export default App;
