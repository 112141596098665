import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { SmsTrackIcon } from '../icons';
import {
  TravelsFontMixixn24,
  TravelsFontMixixn20,

} from '../../constants/fontsConfigs';
import { UniversalButton } from '../RestyledButtons';
import Themes from '../../themes';
import { CustomMaskedInput } from '../FormElements';
import { useSelector, useDispatch } from '../../store/store.types';
import { openConfirmPopup } from '../../store/allSlice';

const Form = styled.form`
  padding: 31px 55px;
  border-radius: 20px;
  background-color: ${({ theme: { mainBg } }) => mainBg};
  border: ${({ theme: { mainTextColor } }) => `1px solid ${mainTextColor}`};
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 780px;
  width: 100%;
  align-items: start;
  box-sizing: border-box;
  & button {
    align-self: center;
  }
`;

const Label = styled.label`
  ${TravelsFontMixixn24}
  color: ${({ theme: { mainTextColor } }) => `1ox solid ${mainTextColor}`};

`;

const P = styled.p`
  ${TravelsFontMixixn20};
  color: ${({ theme: { mainTextColor } }) => `1ox solid ${mainTextColor}`};
`;

const ConfirmPassPopup: FC = () => {
  const [code, setCode] = useState<number | string>('');
  const { theme } = useSelector((state) => state.all);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Form data-cy='regConfirm'>
      <SmsTrackIcon style={{ alignSelf: 'center' }} />
      <Label htmlFor='submit'>Подтвердите Email</Label>
      <P>
        Мы отправили вам ссылку для активации учётной записи.
        <br />
        {' '}
        Перейдите в вашу почту и активируйте учётную запись.
      </P>
      {/* <CustomMaskedInput
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        placeholder='6–ти значный код активации'
        maxLength={6}
        name='submit'
        guide={false}
        value={code}
        onChange={(e) => setCode(e.target.value)}
        id='submit' /> */}
      <UniversalButton
        onClick={() => { navigate('/login'); dispatch(openConfirmPopup(false)); }}
        type='button'
        textColor={Themes[theme].mainTextColor}
        backColor={Themes[theme].sliderColor}
        borderColor=''
        paddingLeft={44}
        paddingTop={20}>
        Закрыть
      </UniversalButton>
    </Form>
  );
};

export default ConfirmPassPopup;
