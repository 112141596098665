import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router';
import { clear } from 'idb-keyval';
import { SectionHeader } from '../ui-lib/TextBlocks';
import { BasicInput, TextAreaWithNoValidation } from '../ui-lib/FormElements';
import Button from '../ui-lib/Button';
import { TravelsFontMixixn20 } from '../constants/fontsConfigs';
import {
  jwt,
  projectsApi,
  useChangePasswordMutation,
  useNewGetCurrentUserQuery,
  userApi,
  useResetPasswordMutation,
  useSetNewPasswordMutation,
} from '../api/api';
import { makeToast } from '../helpers/promts';
import { useSelector, useDispatch } from '../store/store.types';
import { UniversalButton } from '../ui-lib/RestyledButtons';
import SubscriptionBlock from '../ui-lib/widgets/Subscription';
import Themes from '../themes';

const CustomSectionHeader = styled(SectionHeader)`
  -webkit-text-stroke-width: medium;
`;

export const Form = styled.form`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  z-index: 99999;
  padding:20px;
  border-radius: 50px;
  background: #FFFEFA;
  width: 459px;
  min-height: 231px;
  flex-shrink: 0;
  backdrop-filter: blur(4px);
`;
export const Section = styled.section`
  box-sizing: border-box;
  padding-inline: 40px;
  max-width: 1362px; 
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 100px;
  & button {
    align-self: center;
    margin-top: 35px;
  }
`;
const SettingsSection = styled(Section)<{ makeWider: boolean }>`
    align-items: center;
    padding-top: 90px;
    gap: 50px;
    padding-bottom: 0;
    width: 100%;
    max-width: ${({ makeWider }) => (makeWider ? '1440px' : '1362px')};
`;
const SwitcherPanel = styled.div`
    display: flex;
    gap: 45px;
    align-self: flex-start;
`;
const RecoveryButton = styled.button`
  text-decoration: underline;
 ${TravelsFontMixixn20}
 color: ${({ theme: { mainButtonColor } }) => mainButtonColor};
 border: none;
 cursor: pointer;
 background-color: transparent;
`;
const SwitcherDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  cursor: pointer;
`;

const P = styled.p`
  margin:0;
  font-family: 'TTTravels';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color:${({ theme: { mainTextColor } }) => mainTextColor};
  -webkit-text-stroke-width: medium;
`;

const Span = styled.span<{ isCurrent: boolean }>`
  height: 2px;
  background-color: ${({ theme: { mainButtonColor } }) => mainButtonColor} ;
  width:${({ isCurrent }) => (isCurrent ? '100%' : '0%')};
  transition: all ease .3s;
`;

const Plate = styled.div<{ makeWider: boolean }>`
    max-width: ${({ makeWider }) => (makeWider ? '1440px' : '1362px')};
    width: 100%;
    min-height: 537px;
    border-radius: 91px;
    display: flex;
    flex-direction: column;
    gap: ${({ makeWider }) => (makeWider ? '22px' : '42px')}; ;
    align-items: center;
    justify-content: flex-start;
    padding-left:  ${({ makeWider }) => (makeWider ? '0' : '100px')};
    padding-right: ${({ makeWider }) => (makeWider ? '0' : '100px')};
    padding-bottom: 36px;
    box-sizing: border-box;
`;
const ResetForm = styled.form`
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-self: flex-start;
  & button {
        margin-top: 0;
        align-self: flex-start;
    }
`;

const MailForm = styled.form`
    padding-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-self: flex-start;
     & button {
        margin-top: 0;
        align-self: flex-end;
    }
`;

const MailAddress = styled.div`
    display: flex;
    width: fit-content;
    padding: 20px 44px;
    border-radius: 36px;
    background-color: ${({ theme: { buttonSubcolor } }) => (buttonSubcolor)};
    color: ${({ theme: { mainTextColor } }) => (mainTextColor)};
    font-family: 'TTTravels';
    border: ${({ theme: { mainButtonColor } }) => (`1px solid ${mainButtonColor}`)};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 80%; /* 20px */
    letter-spacing: -0.625px;
    z-index: 0;
    text-align: center;
    z-index: 9999999;
    position: relative;
`;

const ResetPassword: FC = () => {
  const [isResetShown, showReset] = useState<string>('password');
  const { theme } = useSelector((state) => state.all);
  const { id, nte } = useParams();
  const [message, setMessage] = useState<string>('');
  const [update, { status, data: st }] = useSetNewPasswordMutation();
  const { data: user, error } = useNewGetCurrentUserQuery();
  const [res] = useResetPasswordMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const [isSubmited, setSubmit] = useState<boolean>(false);
  const {
    register, formState: { errors }, reset, handleSubmit,
  } = useForm();
  const changePassword = async (data: any) => {
    try {
      await update({ new_password: data.new_password, uid: id!, token: nte! }).unwrap();
      console.log(st);
      jwt.remove();
      await clear();
      navigate('/');
      dispatch(userApi.util.resetApiState());
      dispatch(projectsApi.util.resetApiState());

      setSubmit(true);
    } catch (err) {
      setSubmit(false);
      toast.error('Попробуйте ещё раз');
    }
  };

  return (
    <SettingsSection makeWider={isResetShown === 'subscription'}>
      <Toaster position='top-center' />
      <CustomSectionHeader>Сброс пароля</CustomSectionHeader>
      <Plate makeWider={isResetShown === 'subscription'}>

        <ResetForm onSubmit={(handleSubmit(changePassword))}>
          <BasicInput
            errorMessage={errors?.oldPassword?.message as string}
            maxLength={100}
            type='password'
            options={{
              required: 'Заполните поле',
              minLength: {
                value: 8,
                message: 'Поле должно содержать минимум 8 символов',
              },
              maxLength: {
                value: 100,
                message: 'Поле должно содержать маскимум 100 символов',
              },
              pattern: {
                value: /^(?=.*[a-zA-Z])(?=.*\d).+$/,
                message: 'Некорректное значение',
              },
            }}
            register={register}
            name='new_password'
            label='Новый пароль :' />
          <UniversalButton
            type='submit'
            textColor={Themes[theme].mainBg}
            backColor={Themes[theme].mainButtonColor}
            borderColor={Themes[theme].mainButtonColor}
            paddingLeft={44}
            paddingTop={20}>
            Отправить
          </UniversalButton>
        </ResetForm>

      </Plate>
    </SettingsSection>
  );
};

export default ResetPassword;
