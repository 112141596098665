import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { clear, delMany } from 'idb-keyval';
import {
  RoundTagBlock,
  TagBox,
} from '../ui-lib/FormElements';
import NewBaseSection from '../ui-lib/widgets/NewBaseSection';
import NewAvatarWithText from '../ui-lib/widgets/NewAvatarWithText';
import {
  DocumentIcon,
} from '../ui-lib/icons';
import {
  TravelsFontMixixn20,
  TravelsFontMixixn24,
} from '../constants/fontsConfigs';
import {
  jwt,
  projectsApi,
  useNewGetCurrentUserQuery,
  userApi,
} from '../api/api';
import { Skeleton1 } from '../ui-lib/widgets/Skeleton';
import avatarDefault from '../assets/dedaultAvaImg.png';
import Themes from '../themes';
import { useSelector, useDispatch } from '../store/store.types';
import { UniversalButton } from '../ui-lib/RestyledButtons';
import { Details, SummaryColored } from '../ui-lib/widgets/Accordeon';
import { openImagePopupOpenInProfileAbout, setImageForDiploma } from '../store/allSlice';

const CustomTagBox = styled(TagBox)`
 display: flex;
 flex-wrap: wrap;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 26px;
    height: 100%;
    z-index: 99999999;
    box-shadow: inset -38px -8px 15px -15px rgb(251, 252, 252);
  }
`;

export const ProfessionsList = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-self: flex-start;
  width: 100%;
`;
export const P = styled.p`
  ${TravelsFontMixixn20}
  color:${({ theme: { mainTextColor } }) => mainTextColor};
  margin: 0;
  word-break: break-all;
`;
export const Header3 = styled.h3`
  ${TravelsFontMixixn24}
  font-size: 26px;
  color:${({ theme: { mainTextColor } }) => mainTextColor};
  margin: 0;
`;

const Header5 = styled.h3`
  ${TravelsFontMixixn24}
  font-size: 20px;
  color:${({ theme: { mainTextColor } }) => mainTextColor};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
   display: -moz-box;
  -moz-box-orient: vertical;
   display: -webkit-box;
  -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   line-clamp: 3;
   box-orient: vertical;
`;

export const StudyList = styled.ul`
    display: grid;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    row-gap: 15px;
    column-gap: 20px;
    grid-template-columns: repeat(2, minmax(340px, 1fr));
    grid-auto-flow: row;
`;
export const StudyListItem = styled.li`
    display: flex;
    flex-direction: column;
    gap: 15px;

`;
export const LinkContainer = styled.div`
  display: flex ;
  gap:5px ;
`;

export const LinkForFileLoad = styled.a`
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
    ${TravelsFontMixixn20}
    text-decoration-line: underline;
    text-transform: uppercase;
    align-self: center;
    cursor: pointer;
    width: 84%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
`;

export const Diploma = styled.p`
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
    ${TravelsFontMixixn20}
    text-decoration-line: underline;
    text-transform: uppercase;
    align-self: center;
    cursor: pointer;
    margin: 0;
    width: 84%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-self: flex-end;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const LeftToggle = styled.div`
  left: 0;
  top: 31px;
  position: absolute;
  background-color: transparent;
  width: 30px;
  z-index: 9999999999999;
  height: 54px;
`;
const RightToggle = styled.div`
  right: 0;
  top: 27px;
  position: absolute;
  background-color: transparent;
  width: 30px;
  height: 54px;
  z-index: 999999999999;
 
`;

const InvisibleList = styled.ul<{ isHovered: boolean }>`
  height: ${({ isHovered }) => (isHovered ? 'fit-content' : '0px')};
  opacity: ${({ isHovered }) => (isHovered ? '1' : '0')}; ;
  background-color: ${({ theme: { plateColor, focusColor } }) => plateColor};
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 20px;
  border: ${({ theme: { mainButtonColor } }) => `${mainButtonColor} 1px solid`};
  position: absolute;
  padding: 20px;
    z-index: 999999999999;
    top: 110px;
    box-sizing: border-box;
    overflow: hidden;
    transition: all ease .3s;
`;

const ProfileAbout: FC = () => {
  const { data, error, isLoading } = useNewGetCurrentUserQuery();
  console.log(data);
  const [isHovered, setHover] = useState(false);
  const [isHovered1, setHover1] = useState(false);
  const { theme } = useSelector((state) => state.all);
  const ref = useRef<HTMLUListElement>(null);
  const timer = useRef<string | number | NodeJS.Timeout | undefined>();
  const ref2 = useRef<HTMLUListElement>(null);
  const timer2 = useRef<string | number | NodeJS.Timeout | undefined>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(data?.soft_skills_edu);
  /// выход
  const exitFrom = async () => {
    jwt.remove();
    await clear();
    await delMany(['avatar',
      'address',
      'birth_date',
      'vk',
      'telegram',
      'github',
      'extra_social_network',
      'hard_skills',
      'soft_skills',
      'educations',
      'soft_skills_edu',
      'tests']);
    navigate('/');
    dispatch(userApi.util.resetApiState());
    dispatch(projectsApi.util.resetApiState());
  };
  const openImageInBigScreen = (img: string) => {
    dispatch(setImageForDiploma(img));
    dispatch(openImagePopupOpenInProfileAbout(true));
  };
  /* const infiniteScroll = (position: number) => {
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      ref.current?.scrollBy({
        left: position,
        behavior: 'smooth',
      });
    }, 100);
  };
  const infiniteScroll2 = (position: number) => {
    clearInterval(timer.current);
    timer2.current = setInterval(() => {
      ref2.current?.scrollBy({
        left: position,
        behavior: 'smooth',
      });
    }, 100);
  }; */
  return (
    !data ? <Skeleton1 />
      : (
        <NewBaseSection
          goBackFunc={() => navigate('/')}
          title='Личный кабинет'>
          <NewAvatarWithText
            width={100}
            height={100}
            image={data?.image || avatarDefault}
            vkLink={data?.vk || ''}
            extraLink={data?.extra_social_network || ''}
            tgLink={data?.telegram || ''}
            gitLink={data?.github || ''}
            header={data?.full_name || ''}
            age={data?.age || ''}
            address={data?.address || ''} />
          <ProfessionsList>
            {data?.hard_skills?.length !== 0
              && (
                <Details>
                  <SummaryColored>Hard skills</SummaryColored>
                  <Div>
                    {/* <LeftToggle
                      style={{ top: '66px' }}
                      onMouseOver={() => { infiniteScroll(-10); console.log(123); }}
                      onMouseOut={() => clearInterval(timer.current)} /> */}

                    <Header3>Hard skills</Header3>
                    <CustomTagBox onMouseLeave={() => setHover(false)} onMouseOver={() => setHover(true)} ref={ref}>

                      {data?.hard_skills?.map((el) => (
                        <RoundTagBlock>
                          {el.name}
                        </RoundTagBlock>
                      ))}

                    </CustomTagBox>
                    {/*  <InvisibleList isHovered={isHovered}>
                      {data?.hard_skills?.map((el, index) => (
                        <RoundTagBlock>
                          {el.name}
                        </RoundTagBlock>
                      ))}
                    </InvisibleList> */}
                    {/* <RightToggle
                      style={{ top: '66px' }}
                      onMouseOut={() => clearInterval(timer.current)}
                      onMouseOver={() => { infiniteScroll(10); console.log(123); }} /> */}
                  </Div>

                </Details>
              )}
            {data?.educations?.length !== 0
              && (
                <Details>
                  <SummaryColored>Образование</SummaryColored>
                  <StudyList>
                    {data?.educations?.map((el) => (
                      <StudyListItem>
                        <Header5>{el.organization_name}</Header5>
                        <P>{el.speciality}</P>
                        <P>{el.education_end_year}</P>
                        <LinkContainer>
                          {el.image && <DocumentIcon />}
                          {el.image?.startsWith('data:image')
                            ? <Diploma onClick={() => openImageInBigScreen(el.image || '')}>{el.file_name}</Diploma>
                            : <LinkForFileLoad download href={el.image || ''}>{el.file_name}</LinkForFileLoad>}
                        </LinkContainer>
                      </StudyListItem>
                    ))}

                  </StudyList>
                </Details>
              )}
            {data?.soft_skills?.length !== 0 && (
              <Details>
                <SummaryColored>Soft skills</SummaryColored>
                <Div>
                  {/*  <LeftToggle
                    style={{ top: '66px' }}
                    onMouseOver={() => { infiniteScroll2(-10); console.log(123); }}
                    onMouseOut={() => clearInterval(timer.current)} /> */}

                  <Header3>Soft skills</Header3>
                  <CustomTagBox onMouseLeave={() => setHover1(false)} onMouseOver={() => setHover1(true)}>
                    {data?.soft_skills?.map((el) => (
                      <RoundTagBlock>
                        {el.name}
                      </RoundTagBlock>
                    ))}

                  </CustomTagBox>
                  {/* <InvisibleList isHovered={isHovered1}>
                    {data?.soft_skills?.map((el, index) => (
                      <RoundTagBlock>
                        {el.name}
                      </RoundTagBlock>
                    ))}
                  </InvisibleList> */}
                  {/*  <RightToggle
                    style={{ top: '66px' }}
                    onMouseOut={() => clearInterval(timer.current)}
                    onMouseOver={() => { infiniteScroll2(10); console.log(123); }} /> */}
                </Div>

              </Details>
            )}
            {data?.soft_skills_edu?.length !== 0
              && (
                <Details>
                  <SummaryColored>Образование/курсы soft skills</SummaryColored>
                  <StudyList>
                    {data?.soft_skills_edu?.map((el) => (
                      <StudyListItem>
                        <Header5>{el.organization_name}</Header5>
                        <P>{el.speciality}</P>
                        <P>{el.education_end_year}</P>
                        <LinkContainer>
                          {el.image && <DocumentIcon />}
                          {el.image?.startsWith('data:image')
                            ? <Diploma onClick={() => openImageInBigScreen(el.image || '')}>{el.file_name}</Diploma>
                            : <LinkForFileLoad download href={el.image || ''}>{el.file_name}</LinkForFileLoad>}
                        </LinkContainer>
                      </StudyListItem>
                    ))}

                  </StudyList>
                </Details>
              )}
            {data?.tests?.length !== 0 && (
              <Details>
                <SummaryColored>Тесты soft skills</SummaryColored>
                <StudyList>
                  {data?.tests?.map((el) => (

                    <StudyListItem>
                      <Header3>{el.test_name}</Header3>
                      <P>{el.result}</P>
                      <LinkContainer>
                        {el.image && <DocumentIcon />}
                        {el.image?.startsWith('data:image')
                          ? <Diploma onClick={() => openImageInBigScreen(el.image || '')}>{el.file_name}</Diploma>
                          : <LinkForFileLoad download href={el.image || ''}>{el.file_name}</LinkForFileLoad>}

                      </LinkContainer>
                    </StudyListItem>

                  ))}

                </StudyList>
              </Details>
            )}
            {data?.professions?.map((el) => (
              <Details>
                <SummaryColored>{el.profession_name}</SummaryColored>
                <Div style={{ marginBottom: '15px' }}>
                  <Header3>Уровень</Header3>
                  <P>{el.level}</P>
                </Div>
                <Div style={{ marginBottom: '15px' }}>
                  <Header3>Hard skills</Header3>
                  <TagBox>
                    {el?.hard_skills?.map((item) => (
                      <RoundTagBlock>
                        {item.name}
                      </RoundTagBlock>
                    ))}

                  </TagBox>
                </Div>
                <Div style={{ marginBottom: '15px' }}>
                  <Header3>Soft skills</Header3>
                  <TagBox>
                    {el?.soft_skills?.map((item) => (
                      <RoundTagBlock>
                        {item.name}
                      </RoundTagBlock>
                    ))}
                  </TagBox>
                </Div>
                <Div style={{ marginBottom: '15px' }}>
                  <Header3 style={{ marginTop: '15px' }}>
                    Опыт работы

                  </Header3>
                  <P>
                    {el.experience}
                  </P>
                </Div>

              </Details>

            ))}
          </ProfessionsList>
          <ButtonWrapper>
            <UniversalButton
              onClick={() => navigate('/profile')}
              style={{ alignSelf: 'end' }}
              type='button'
              textColor={Themes[theme].mainTextColor}
              backColor={Themes[theme].mainBg}
              borderColor={Themes[theme].mainButtonColor}
              paddingLeft={44}
              paddingTop={20}>
              Редактировать
            </UniversalButton>
            {' '}
            <UniversalButton
              onClick={() => exitFrom()}
              style={{ alignSelf: 'end' }}
              type='button'
              textColor={Themes[theme].mainBg}
              backColor='#C94420'
              borderColor=''
              paddingLeft={44}
              paddingTop={20}>
              Выйти
            </UniversalButton>
          </ButtonWrapper>
        </NewBaseSection>
      )
  );
};

export default ProfileAbout;
