/* eslint-disable import/prefer-default-export */
/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import styled from 'styled-components';
import {
  FullDonePicBlackIcon,
  GreenOkIcon,
  RedCross,
} from '../../icons';
import {
  ComfortaFontMixixn39,
  TravelsFontMixixn15,
  TravelsFontMixixn20,
  TravelsFontMixixn24,
  TravelsFontMixixn30,
} from '../../../constants/fontsConfigs';
import OrangeSlice from '../../../assets/orangePieceSmall1.svg';
import OrangeSliceShadow from '../../../assets/orangePieceSmall2.svg';
import { UniversalButton } from '../../RestyledButtons';
import Themes from '../../../themes';
import { useSelector } from '../../../store/store.types';

export const UniversalContentContainer = styled.div<{ isBorderClicked: boolean, width: number, direction:boolean }>`
    padding: 20px 24px;
    display: flex;
    flex-direction: ${({ direction }) => (direction ? 'column' : 'row')};
    gap: 20px;
    border-radius: 20px;
    border: ${({ isBorderClicked, theme: { mainTextColor, sliderColor } }) => (isBorderClicked ? `3px solid ${sliderColor}` : `1px solid ${mainTextColor}`)};
    background-color: ${({ theme: { mainBg } }) => mainBg};
    position: relative;
    max-width: ${({ width }) => width}px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;  
`;

const ListItem = styled.li`
    display: flex;
    gap: 12px;
    align-items: center;
    color:  ${({ theme: { mainTextColor } }) => mainTextColor};
    ${TravelsFontMixixn20}
    position: relative;
    &::after {
        content: attr(id);
        position: absolute;
        bottom: -22px;
        left: 50%;
        transform: translateX(-50%);
    }  
`;

export const TextSpan = styled.span`
  ${TravelsFontMixixn20}
  font-weight: 700;
  color: ${({ theme: { sliderColor } }) => sliderColor};
  display: inline;
`;

export const ListItemWithIcon: FC<{ text: string, id?: string }> = ({ text, id = '' }) => (
  <ListItem id={id}>
    <FullDonePicBlackIcon />
    {text}
  </ListItem>
);

export const ListItemWithiconAndColoredTextv: FC<{ children: any }> = ({ children }) => (
  <ListItem>
    <FullDonePicBlackIcon />
    {children}
  </ListItem>
);

export const BuyFuncComponent: FC<{ text: string, isChoosen:boolean, onClick?:(func:string)=>void, funcList?:string[] }> = ({
  text, isChoosen = false, onClick, funcList,
}) => (
  <ListItem>
    {isChoosen ? <GreenOkIcon /> : <RedCross />}
    {text}
  </ListItem>
);

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Line = styled.div`
  width: 100%;
  background-color: ${({ theme: { mainTextColor } }) => mainTextColor};
  height: 1px;
`;

const CostWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: end;
    color:  ${({ theme: { mainTextColor } }) => mainTextColor};
    ${TravelsFontMixixn15}
`;

const CostSpan = styled.span`
    position: relative;
    display: block;
    ${TravelsFontMixixn30}

    &::after {
        content: '₽';
        position: absolute;
        top: -8px;
        right: -2px;
        height: 2px;
        width: 2px;
        font-size: 12px;
        font-weight: 400;
        -webkit-text-stroke-width: thin;
    }
`;

export const CostElement: FC<{ cost: number, text: string }> = ({ cost, text }) => (
  <CostWrapper>
    <CostSpan>{cost}</CostSpan>
    {text}
  </CostWrapper>
);

const Header2 = styled.h2`
    color: ${({ theme: { mainButtonColor } }) => mainButtonColor};
    text-align: center;
    -webkit-text-stroke-width: medium;
    ${ComfortaFontMixixn39}
    margin: 0;
`;

const GridHeader = styled(Header2)`
    grid-column: -1/1;
    text-align: center;
`;

const Header3 = styled.h3`
    ${TravelsFontMixixn24}
    color: ${({ theme: { mainButtonColor } }) => mainButtonColor};
    text-align: center;
    -webkit-text-stroke-width:thin;
    margin: 0;
`;
const BlackHeader3 = styled(Header3)`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
`;

const PayBlock = styled.div`
    max-width: 1440px;
    
    display: grid;
    grid-template-columns: .5fr 1fr;
    grid-auto-flow: row;
   
    width: 100%;
    box-sizing: border-box;
    position: relative;
`;
const DivForSlices = styled.div`
  position: absolute;
  width: 100%;
  height: 785px;
`;
const OrangeSliceDiv = styled.div`
  height: 508px;
  width: 609px;
  background: url(${OrangeSlice});
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 0;
`;

const OrangeSliceDivShadow = styled.div`
  height: 579px;
  width: 482px;
  background: url(${OrangeSliceShadow});
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 0;
`;
const FormBoxLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 80px;
    border-right: ${({ theme: { mainTextColor } }) => `1px solid ${mainTextColor}`};
    padding-right: 10px;
    max-width: 530px;
    width: 100%;
    z-index: 2;
`;

const FormBoxRight = styled.div`
    display: grid;
    gap: 50px;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    grid-auto-flow: row;
    justify-items: center;
    padding-left: 30px;
`;

export const P = styled.p`
  ${TravelsFontMixixn20}
  color:  ${({ theme: { mainTextColor } }) => mainTextColor};
`;

const FuncsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const PayBlockWithOrangeSlices: FC = () => {
  const { theme } = useSelector((state) => state.all);
  return (
    <PayBlock>
      <DivForSlices>
        <OrangeSliceDiv style={{ top: '154px', left: '0', transform: 'rotate(120deg)' }} />
        <OrangeSliceDivShadow style={{ bottom: '-125px', left: '250px', transform: 'rotate(256deg)' }} />
        <OrangeSliceDiv style={{ top: '239px', right: '-48px', transform: 'rotate(306deg)' }} />
        <OrangeSliceDivShadow style={{ bottom: '162px', right: '73px', transform: 'rotate(84deg)' }} />
      </DivForSlices>
      <FormBoxLeft>
        <Header2>
          Дополнительные
          <br />
          {' '}
          функции
        </Header2>
        <UniversalContentContainer direction width={415} isBorderClicked={false}>
          <Header3>
            {' '}
            Дополнительные
            <br />
            {' '}
            функции
          </Header3>
          <List>
            <ListItemWithIcon text='Публикация 1 проекта на Витрину проектов' id='и/или' />
            <ListItemWithIcon text='Публикация 1 анкеты на Витрину специалистов' id='и/или' />
            <ListItemWithIcon text='1 отклик на вакансию или проект' />
          </List>
          <CostElement text='за одну функцию' cost={99} />
          <UniversalButton
            style={{ marginTop: '0' }}
            type='button'
            textColor={Themes[theme].mainTextColor}
            backColor={Themes[theme].sliderColor}
            borderColor=''
            paddingLeft={40}
            paddingTop={20}>
            Выбрать функцию
          </UniversalButton>
        </UniversalContentContainer>
      </FormBoxLeft>
      <FormBoxRight>
        <GridHeader>Подписка</GridHeader>
        <UniversalContentContainer direction width={415} isBorderClicked>
          <Header3>Премиум</Header3>
          <List>
            <ListItemWithIcon text='Заполнение 1 hard skill' />
            <ListItemWithIcon text='Добавление 1 папки с достижением' />
            <ListItemWithIcon text='Добавление 1 проекта' />
            <ListItemWithIcon text='Неограниченный просмотр Витрины проектов и Витрины специалистов' />
          </List>
          <Line />
          <List>
            <ListItemWithiconAndColoredTextv>
              <P>
                Публикация
                {' '}
                <TextSpan>2 проектов</TextSpan>
                {' '}
                на Витрину проектов
              </P>
            </ListItemWithiconAndColoredTextv>
            <ListItemWithiconAndColoredTextv>
              <P>
                Публикация
                {' '}
                <TextSpan>2 анкет</TextSpan>
                {' '}
                на Витрину специалистов
              </P>
            </ListItemWithiconAndColoredTextv>
            <ListItemWithiconAndColoredTextv>
              <P>
                <TextSpan>2 отклика</TextSpan>
                {' '}
                на вакансию или проект
              </P>
            </ListItemWithiconAndColoredTextv>

          </List>
          <CostElement text='на 30 дней' cost={499} />
          <UniversalButton
            style={{ marginTop: '0' }}
            type='button'
            textColor={Themes[theme].mainTextColor}
            backColor={Themes[theme].sliderColor}
            borderColor=''
            paddingLeft={40}
            paddingTop={20}>
            Подписаться
          </UniversalButton>
        </UniversalContentContainer>
        <UniversalContentContainer direction width={415} isBorderClicked={false}>
          <Header3>Премиум +</Header3>
          <List style={{ marginBottom: 'auto' }}>
            <ListItemWithiconAndColoredTextv>
              <P>
                <TextSpan>Неограниченные</TextSpan>
                {' '}
                действия на платформе
              </P>
            </ListItemWithiconAndColoredTextv>
            <ListItemWithIcon text='Новости о будущих мероприятиях, форумах и акселлераторах' />
            <ListItemWithIcon text='Проверка и обратная связь от разработчиков ваших проектов и анкет' />
          </List>
          <CostElement text='на 30 дней' cost={999} />
          <UniversalButton
            style={{ marginTop: '0' }}
            type='button'
            textColor={Themes[theme].mainTextColor}
            backColor={Themes[theme].sliderColor}
            borderColor=''
            paddingLeft={40}
            paddingTop={20}>
            Подписаться
          </UniversalButton>
        </UniversalContentContainer>
      </FormBoxRight>
    </PayBlock>
  );
};

export const PaySection = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const OtherFuncs: FC<{ theme: 'dark' | 'light' }> = ({ theme }) => (
  <>
    <BlackHeader3 style={{ alignSelf: 'baseline' }}>Другие предложения</BlackHeader3>
    <FuncsWrapper>
      <UniversalContentContainer width={637} direction isBorderClicked={false}>
        <Header3>Дополнительные функции</Header3>
        <P>выберите дополнительные  функции из списка, которые помогут вам улучшить Ваш проект!</P>
        <List>
          <ListItemWithIcon text='Публикация 1 проекта на Витрину проектов' id='и/или' />
          <ListItemWithIcon text='Публикация 1 анкеты на Витрину специалистов' id='и/или' />
          <ListItemWithIcon text='1 отклик на вакансию или проект' />
        </List>
        <CostElement text='за одну функцию' cost={99} />
        <UniversalButton
          style={{ marginTop: '0' }}
          type='button'
          textColor={Themes[theme].mainTextColor}
          backColor={Themes[theme].sliderColor}
          borderColor=''
          paddingLeft={40}
          paddingTop={20}>
          Выбрать функцию
        </UniversalButton>
      </UniversalContentContainer>
      <UniversalContentContainer width={637} direction isBorderClicked={false}>
        <Header3>Премиум+</Header3>
        <List>
          <ListItemWithiconAndColoredTextv>
            <P>
              <TextSpan>Неограниченные</TextSpan>
              {' '}
              действия на платформе
            </P>
          </ListItemWithiconAndColoredTextv>

          <ListItemWithIcon text='Новости о будущих мероприятиях, форумах и акселлераторах' />
          <ListItemWithIcon text='Проверка и обратная связь от разработчиков ваших проектов и анкет ' />
        </List>
        <CostElement text='за 30 дней' cost={999} />
        <UniversalButton
          style={{ marginTop: '0' }}
          type='button'
          textColor={Themes[theme].mainTextColor}
          backColor={Themes[theme].sliderColor}
          borderColor=''
          paddingLeft={40}
          paddingTop={20}>
          Подписаться
        </UniversalButton>
      </UniversalContentContainer>
    </FuncsWrapper>
  </>
);
