/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable radix */
import React, {
  FC, useCallback, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router';
import { delMany } from 'idb-keyval';
import NewAvatarWithText from '../ui-lib/widgets/NewAvatarWithText';
import NewBaseSection from '../ui-lib/widgets/NewBaseSection';
import { Skeleton1 } from '../ui-lib/widgets/Skeleton';
import { useSelector, useDispatch } from '../store/store.types';
import {
  useNewGetCurrentUserQuery,
  useGetUsersQuery,
  useNewGetUserByIdQuery,
  useDeleteProfessionMutation,
  useGetCurrentUserProjectsQuery,
  useIInviteUserToProjectMutation,
  useDeleteIInviteUserToProjectMutation,
} from '../api/api';
import {
  ButtonWrapper,
  Div,
  Header3,
  LinkContainer,
  LinkForFileLoad,
  ProfessionsList,
  StudyList,
  StudyListItem,
  P,
  Diploma,
} from './ProfileAbout';
import { Details, SummaryColored } from '../ui-lib/widgets/Accordeon';
import { RoundTagBlock, TagBox } from '../ui-lib/FormElements';
import { DocumentIcon } from '../ui-lib/icons';
import { UniversalButton, ButtonWithDropBox } from '../ui-lib/RestyledButtons';
import Themes from '../themes';
import avatarDefault from '../assets/dedaultAvaImg.png';
import {
  openCreateProfessionPopup,
  openImagePopupOpenInProfileAbout,
  setImageForDiploma,
  setProfId,
} from '../store/allSlice';

const LeftToggle = styled.div`
    left: 0;
    top: 66px;
    position: absolute;
    background-color: transparent;
    width: 30px;
    z-index: 9999999999999;
    height: 54px;
  `;
const RightToggle = styled.div`
    right: 0;
    top: 66px;
    position: absolute;
    background-color: transparent;
    width: 30px;
    height: 54px;
    z-index: 999999999999;
   
  `;

export const CustomPropsBreadcrumb: FC<{ text: string, text2?: string }> = ({ text, text2 }) => (
  <>
    {!text2 && <span>{text}</span>}
    <span>{text2}</span>
  </>
);

const SpecResumeResponses: FC = () => {
  const { profId, userId } = useParams();
  const { data: user, error, isLoading } = useNewGetCurrentUserQuery();
  const { data } = useNewGetUserByIdQuery(parseInt(userId!));
  const dispatch = useDispatch();
  const ref = useRef<HTMLUListElement>(null);
  const timer = useRef<string | number | NodeJS.Timeout | undefined>();
  const ref2 = useRef<HTMLUListElement>(null);
  const timer2 = useRef<string | number | NodeJS.Timeout | undefined>();
  /*  const { data: allUsers } = useGetUsersQuery(); */
  const [deleteProfession] = useDeleteProfessionMutation();
  const { data: projects } = useGetCurrentUserProjectsQuery({ offset: 0, limit: 10000 });
  const { theme, isProfessionCreatePopupOpen } = useSelector((state) => state.all);
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const deleteProf = useCallback(async () => {
    try {
      await deleteProfession({ userId: parseInt(userId!), professionId: parseInt(profId!) });
      navigate('/team-list');
    } catch (err: any) {
      console.log(err);
    }
  }, []);
  const findPathName = () => {
    const proffession = data?.professions?.find((el) => el.id === parseInt(profId!))?.profession_name || '';
    /*  const userCurrent = allUsers?.find((el) => el.id === parseInt(userId!))?.full_name || ''; */

    return [
      { path: '/my-responses', breadcrumb: CustomPropsBreadcrumb, props: { text: 'Мои отклики' } },
      { path: '/my-responses/:id', breadcrumb: null, props: { text: '' } },
      /* { path: '/achieves/:id/create', breadcrumb: 'Создание достижения' },  */
      { path: '/my-responses/:profId/:userId', breadcrumb: CustomPropsBreadcrumb, props: { text: (proffession || ''), text2: ('Анкета' || '') } },

    ];
  };

  const openImageInBigScreen = (img: string) => {
    dispatch(setImageForDiploma(img));
    dispatch(openImagePopupOpenInProfileAbout(true));
  };
  useEffect(() => {
    delMany(['hard_skills_prof',
      'soft_skills_prof',
      'profession_name',
      'level',
      'experience',
      'development_goals',
      'achievements']);
  }, [isProfessionCreatePopupOpen]);
  const infiniteScroll = (position: number) => {
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      ref.current?.scrollBy({
        left: position,
        behavior: 'smooth',
      });
    }, 100);
  };
  const infiniteScroll2 = (position: number) => {
    clearInterval(timer.current);
    timer2.current = setInterval(() => {
      ref2.current?.scrollBy({
        left: position,
        behavior: 'smooth',
      });
    }, 100);
  };
  return (
    !data && !user ? <Skeleton1 />
      : (
        <NewBaseSection
          routes={findPathName()}
          goBackFunc={() => navigate('/my-responses')}
          title='Анкета'>
          <NewAvatarWithText
            width={100}
            height={100}
            image={data?.image || avatarDefault}
            header={data?.full_name || ''}
            proffessionName={data?.professions?.find((el) => el.id === parseInt(profId!))?.profession_name}
            age={data?.age || '0'}
            address={data?.address || ''} />
          <ProfessionsList>
            {data?.hard_skills?.length !== 0 && (
            <Details open>
              <SummaryColored>Hard skills</SummaryColored>
              <Div>
                <LeftToggle
                  style={{ top: '66px' }}
                  onMouseOver={() => { infiniteScroll(-10); console.log(123); }}
                  onMouseOut={() => clearInterval(timer.current)} />
                <Header3>Hard skills</Header3>
                <TagBox ref={ref}>
                  {data?.hard_skills?.map((el) => (
                    <RoundTagBlock>
                      {el.name}
                    </RoundTagBlock>
                  ))}
                </TagBox>
                <RightToggle
                  style={{ top: '66px' }}
                  onMouseOut={() => clearInterval(timer.current)}
                  onMouseOver={() => { infiniteScroll(10); console.log(123); }} />
              </Div>
            </Details>
            )}
            {data?.educations?.length !== 0 && (
            <Details open>
              <SummaryColored>Образование</SummaryColored>
              <StudyList>
                {data?.educations?.map((el) => (
                  <StudyListItem>
                    <Header3>{el.organization_name || ''}</Header3>
                    <P>{el.speciality || ''}</P>
                    <P>{el.education_end_year || ''}</P>
                    {el.image && (
                    <LinkContainer>
                      <DocumentIcon />
                      {el.image?.startsWith('data:image')
                        ? <Diploma onClick={() => openImageInBigScreen(el.image || '')}>{el.file_name}</Diploma>
                        : <LinkForFileLoad download href={el.image}>{el.file_name}</LinkForFileLoad>}
                      {/*  <LinkForFileLoad download href={el.image}>{el.file_name}</LinkForFileLoad> */}
                    </LinkContainer>
                    )}
                  </StudyListItem>
                ))}

              </StudyList>
            </Details>
            )}
            {data?.soft_skills?.length !== 0 && (
            <Details open>
              <SummaryColored>Soft skills/опыт</SummaryColored>
              <Div>
                <LeftToggle
                  style={{ top: '66px' }}
                  onMouseOver={() => { infiniteScroll2(-10); }}
                  onMouseOut={() => clearInterval(timer.current)} />
                <Header3>Soft skills</Header3>
                <TagBox>
                  {data?.soft_skills?.map((el) => (
                    <RoundTagBlock>
                      {el.name}
                    </RoundTagBlock>
                  ))}

                </TagBox>
                <RightToggle
                  style={{ top: '66px' }}
                  onMouseOut={() => clearInterval(timer.current)}
                  onMouseOver={() => { infiniteScroll2(10); }} />
              </Div>
              {data?.professions?.find((el) => el.id === parseInt(profId!))?.experience && (
              <Div>
                <Header3 style={{ marginTop: '15px' }}>
                  Опыт взаимодействия в команде
                </Header3>
                <P>
                  {data?.professions?.find((el) => el.id === parseInt(profId!))?.experience || ''}
                </P>
              </Div>
              )}
            </Details>
            )}
            {data?.soft_skills_edu?.length !== 0 && (
            <Details open>
              <SummaryColored>Образование/курсы soft skills</SummaryColored>
              <StudyList>
                {data?.soft_skills_edu?.map((el) => (
                  <StudyListItem>
                    <Header3>{el.organization_name || ''}</Header3>
                    <P>{el.speciality || ''}</P>
                    <P>{el.education_end_year || ''}</P>
                    {el.image && (
                    <LinkContainer>
                      <DocumentIcon />
                      {el.image?.startsWith('data:image')
                        ? <Diploma onClick={() => openImageInBigScreen(el.image || '')}>{el.file_name}</Diploma>
                        : <LinkForFileLoad download href={el.image}>{el.file_name}</LinkForFileLoad>}
                    </LinkContainer>
                    )}
                  </StudyListItem>
                ))}

              </StudyList>
            </Details>
            )}
            {data?.tests?.length !== 0 && (
            <Details open>
              <SummaryColored>Тесты о личностных характерисиках</SummaryColored>
              <StudyList>
                {data?.tests?.map((el) => (
                  <StudyListItem>
                    <Header3>{el.test_name || ''}</Header3>
                    {el.image && (
                    <LinkContainer>
                      <DocumentIcon />
                      {el.image?.startsWith('data:image')
                        ? <Diploma onClick={() => openImageInBigScreen(el.image || '')}>{el.file_name}</Diploma>
                        : <LinkForFileLoad download href={el.image}>{el.file_name}</LinkForFileLoad>}
                    </LinkContainer>
                    )}
                  </StudyListItem>
                ))}

              </StudyList>
            </Details>
            )}
            {data?.professions?.find((el) => el.id === parseInt(profId!))?.development_goals && (
            <Details>
              <SummaryColored>Цели развития в рамках специальности</SummaryColored>
              <Div>
                <P>
                  {data?.professions?.find((el) => el.id === parseInt(profId!))?.development_goals || ''}
                </P>
              </Div>
            </Details>
            )}
            {data?.professions?.find((el) => el.id === parseInt(profId!))?.achievements.length !== 0 && (
            <Details open>
              <SummaryColored>Достижения</SummaryColored>
              <StudyList>
                {data?.professions?.filter((el) => el.id === parseInt(profId!))[0].achievements?.map((el) => (
                  <StudyListItem>
                    <Header3>{el.achievement_name || ''}</Header3>
                    {el.image && (
                    <LinkContainer>
                      <DocumentIcon />
                      {el.image?.startsWith('data:image')
                        ? <Diploma onClick={() => openImageInBigScreen(el.image || '')}>{el.file_name}</Diploma>
                        : <LinkForFileLoad download href={el.image}>{el.file_name}</LinkForFileLoad>}
                    </LinkContainer>
                    )}
                  </StudyListItem>
                ))}

              </StudyList>
            </Details>
            )}
          </ProfessionsList>
          {parseInt(userId!) === user?.id && (
          <ButtonWrapper>
            <UniversalButton
              onClick={() => { dispatch(openCreateProfessionPopup(true)); dispatch(setProfId(+profId!)); }}
              style={{ alignSelf: 'end' }}
              type='button'
              textColor={Themes[theme].mainTextColor}
              backColor={Themes[theme].mainBg}
              borderColor={Themes[theme].mainButtonColor}
              paddingLeft={44}
              paddingTop={20}>
              Редактировать
            </UniversalButton>
            {' '}
            <UniversalButton
              onClick={deleteProf}
              style={{ alignSelf: 'end' }}
              type='button'
              textColor={Themes[theme].mainBg}
              backColor='#C94420'
              borderColor=''
              paddingLeft={44}
              paddingTop={20}>
              Удалить
            </UniversalButton>
          </ButtonWrapper>
          )}
        </NewBaseSection>
      )
  );
};

export default SpecResumeResponses;
