/* eslint-disable no-trailing-spaces */
/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import {
  TgIcon,
  VkIcon,
  GitIcon,
  ExtraLinkIcon,
} from '../icons';
import { TravelsFontMixixn30, TravelsFontMixixn20 } from '../../constants/fontsConfigs';
import { ButtonWithDropBox } from '../RestyledButtons';

export const ImageBox = styled.div<{ width: number, height: number }>`
    border-radius: 50%;
    border: ${({ theme: { mainTextColor } }) => `${mainTextColor} 1px solid`};
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SquareBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 278px;
  height: 214px;
  outline: ${({ theme: { mainButtonColor } }) => `${mainButtonColor} 0.73px solid `};
  border-radius: 20px;
  flex-shrink: 0;
  top: -30px;
    left: -20px;
    position: relative;
`;

export const Image = styled.img<{ width:number, height:number }>`
  width: ${({ width }) => width}%;
  height: ${({ height }) => height}%;
  object-fit: cover;
`;

const Container = styled.div`
    max-width: 760px;
    width: 100%;
    display: flex;
    gap: 70px;
    align-self: flex-start;
`;

const TextBox = styled.div`
  display:flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
const LinksContainer = styled.div`
  display:flex ;
  gap: 10px;
`;
const SocialLink = styled.a`
  margin: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  cursor:pointer;
`;

const H2 = styled.h2`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn30}
`;

const P = styled.p`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn20}
  white-space: break-spaces;
`;

const ColoredP = styled.p`
   color: ${({ theme: { sliderColor } }) => sliderColor};
   ${TravelsFontMixixn30}
`;
export const RoundTeamDiv = styled.div<{ isOrange: boolean }>`
display: flex;
height: 32px;
padding: 4px 15px;
justify-content: center;
align-items: center;
border-radius: 36px;
border: ${({ theme: { sliderColor }, isOrange }) => (isOrange ? 'none' : `${sliderColor} 1px solid`)};
background-color: ${({ theme: { sliderColor }, isOrange }) => (isOrange ? sliderColor : 'transparent')};
color:${({ theme: { mainBg, mainTextColor }, isOrange }) => mainTextColor};
${TravelsFontMixixn20}
letter-spacing: -0.5px;
box-sizing: border-box;
white-space: nowrap;
width: fit-content;
@media screen and (max-width: 620px) {
  font-size: 15px;
  padding: 11.582px 18.875px;
}
`;
const Wrapper = styled.div`
  display: flex;

  gap: 10px;
`;
const NewAvatarWithText: FC<{
  vkLink?: string,
  tgLink?: string,
  gitLink?: string,
  extraLink?: string,
  header: string,
  age?: string,
  proffessionName?: string,
  address?: string,
  image: string,
  stage?: string,
  width: number,
  height: number,
  direction?: string,
  needSquareImage?: boolean,
}> = ({
  vkLink,
  needSquareImage = false,
  tgLink,
  extraLink,
  gitLink,
  header,
  width,
  height,
  stage,
  direction,
  age,
  proffessionName,
  address,
  image,
}) => {
  /// na vinos v testi
  const location = useLocation();
  const ageFunc = (item: number) => {
    if (item % 10 < 2 && item % 10 !== 0) {
      return 'год';
    }
    if ((item % 10 > 1) && (item % 10 < 5)) {
      return 'года';
    }
    return 'лет';
  };
  return (
    <Container>
      {needSquareImage
        ? (
          <SquareBox>
            <Image width={width} height={height} src={image} alt='аватарка' />
          </SquareBox>
        )
        : (
          <ImageBox width={181} height={181}>
            <Image width={width} height={height} src={image} alt='аватарка' />
          </ImageBox>
        )}
      <TextBox>
        <H2>
          {header}
        </H2>
        <Wrapper>
          {stage && <RoundTeamDiv isOrange>{stage}</RoundTeamDiv>}
          {direction && <RoundTeamDiv isOrange={false}>{direction}</RoundTeamDiv>}
        </Wrapper>
        {proffessionName && <ColoredP>{proffessionName}</ColoredP>}
        {age && <P>{`${age} ${ageFunc(+age)} ${address ? ',' : ''}  ${address}`}</P>}
        <LinksContainer>
          {tgLink && <SocialLink href={tgLink} target='_blank' rel='noopener noreferrer'><TgIcon /></SocialLink>}
          {vkLink && <SocialLink href={vkLink} target='_blank' rel='noopener noreferrer'><VkIcon /></SocialLink>}
          {gitLink && <SocialLink href={gitLink} target='_blank' rel='noopener noreferrer'><GitIcon /></SocialLink>}
          {extraLink && <SocialLink href={extraLink} target='_blank' rel='noopener noreferrer'><ExtraLinkIcon /></SocialLink>}
        </LinksContainer>
      </TextBox>
    </Container>
  );
};

export default NewAvatarWithText;
