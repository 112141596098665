/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import toast, { Toaster } from 'react-hot-toast';

export const makeToast = (error?: any, status?: string) => {
  const toastId = 'qwerty';
  if (error) {
    toast.error('Перезагрузите страницу', {
      id: toastId,
    });
  }
  if (status === 'rejected') {
    toast.error('Перезагрузите страницу', {
      id: toastId,
    });
  }
  if (status === 'fulfilled') {
    toast.success('Успех!', {
      id: toastId,
    });
  }
  if (status === 'pending') {
    toast.loading('В процессе...', {
      id: toastId,
    });
  }
};

export const setPrompText = (promtText: string) => {
  switch (promtText) {
    case 'Интересы': {
      return 'Покажи, на чём ты концентрируешь своё внимание и в чём ты можешь быть хорош';
    }
    case 'Хобби': {
      return 'Расскажи, чем ты занимаешься в свободное время, что тебя привлекает помимо профессиональной деятельности';
    }
    case 'Интересный факт о себе': {
      return 'Не относись к этому пункту серьёзно)';
    }
    case 'Дополнительное образование': {
      return 'Расскажи о курсах, кружках и секциях, которые отражают твои увлечения или компетентность';
    }
    case 'Компетенции': {
      return 'Расскажи, что ты умеешь, чтобы другие пользователи могли понять, подходишь ли ты в их команду';
    }
    case 'Проекты пользователя': {
      return 'Выбери до 5 проектов, которые покажут твою экспертность в данной области';
    }
    case 'Направление': {
      return 'Выбери наиболее подходящее твоему проекту';
    }
    case 'Идея проекта': {
      return 'Расскажи о проекте в 100 символах, чтобы его можно было добавить в твоё портфолио';
    }
    case 'Команда': {
      return 'Укажи, какие специалисты есть в команде на данный момент и укажи их ID';
    }
    case 'Задача': {
      return 'Объясни, чего ты ждёшь от специалиста, чтобы найти наиболее подходящего ';
    }
    case 'Описание концепции': {
      return 'Расскажи о проекте так, чтобы другие специалисты или инвесторы поняли, для чего он создаётся и какое у него УТП. Не забудь указать, какой будет конечный продукт, сроки реализации и целевую аудиторию.  ';
    }
  }
};
