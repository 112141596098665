/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import React, {
  ChangeEvent,
  FC,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from '../../store/store.types';
import {
  openSpecCreatePopup,
  setOverlayLength,
  setPublishStatus,
  setRequestUser,
} from '../../store/allSlice';
import { TravelsFontMixixn24 } from '../../constants/fontsConfigs';
import NewSelectWithPlural, { SelectLevels } from './NewSelectWithPlural';
import { BasicTextArea, TextAreaWithNoValidation } from '../FormElements';
import { UniversalButton, ButtonWithCross } from '../RestyledButtons';
import {
  levels,
  monetizationlevels,
  arrForProfessionSearch,
  professions,
} from '../../constants/textsForLanding';
import Themes from '../../themes';
import { Spec } from '../../types/apiTypes';
import {
  useNewGetCurrentUserQuery,
  useUpdateProjectMutation,
  usePubishProjectMutation,
  useGetCurrentUserProjectsQuery,
} from '../../api/api';

const H2 = styled.h2`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn24}
  margin:0;
  align-self: center;
`;

const P = styled.p`
  color: ${({ theme: { mainTextColor } }) => mainTextColor};
  ${TravelsFontMixixn24}
  align-self: center;
  margin:0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
  border: ${({ theme: { mainButtonColor } }) => `${mainButtonColor} 0.73px solid`};
  border-radius: 20px;
  max-width: 1370px;
  width: 100%;
  background-color: ${({ theme: { mainBg } }) => mainBg};
  padding: 41px 26px;
  box-sizing: border-box;
`;

const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0;
  border: none;

`;

const InputsWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;
/// / СМЕНИТЬ на пут запрос с текущего патч запроса, чобы отдавать не все поля в проекте
const CreateSpecforProject: FC<{ status: string }> = ({ status }) => {
  const [updateProject] = useUpdateProjectMutation();
  const [publishProject] = usePubishProjectMutation();
  const { data: user, error } = useNewGetCurrentUserQuery();
  const { data, refetch } = useGetCurrentUserProjectsQuery({ offset: 0, limit: 10000 });
  const dispatch = useDispatch();
  const { theme, specCreatePopupHeading, projectId } = useSelector((state) => state.all);
  const [projects, setProjectsArr] = useState<Spec[]>([{
    specialist_name: '', specialist_level: '', labor_monetization: '', task: '',
  }]);
  const [isSubmited, setSubmit] = useState<boolean>(false);

  const pushSpecDifinitionInCurrentObjectInArr = (item: string, currentIndex?: number) => {
    const copyArr = structuredClone(projects);
    copyArr[currentIndex!].specialist_name = item;
    setProjectsArr(copyArr);
  };

  const pushSpecLevelInCurrentObjectInArr = (itm: string, currentIndex?: number) => {
    console.log(currentIndex);
    const copyArr = structuredClone(projects);
    copyArr[currentIndex!].specialist_level = itm;
    setProjectsArr(copyArr);
  };

  const pushSpecMonetizationInObjectInArr = (item: string, currentIndex?: number) => {
    const copyArr = structuredClone(projects);
    copyArr[currentIndex!].labor_monetization = item;
    setProjectsArr(copyArr);
  };

  const pushTaskInObjectInArr = (e: ChangeEvent<HTMLTextAreaElement>, currentIndex?: number) => {
    const copyArr = structuredClone(projects);
    copyArr[currentIndex!].task = e.target.value;
    setProjectsArr(copyArr);
  };

  const deleteSpecInArr = (currentIndex: number) => {
    const copyArr = structuredClone(projects);
    copyArr.splice(currentIndex, 1);
    setProjectsArr(copyArr);
    dispatch(setOverlayLength(copyArr.length));
  };
  const addNewSpecInArr = () => {
    const copyArr = structuredClone(projects);
    copyArr.push({
      specialist_name: '', specialist_level: '', labor_monetization: '', task: '',
    });
    setProjectsArr(copyArr);
    dispatch(setOverlayLength(copyArr.length));
  };

  const publish = async (e: any) => {
    e.preventDefault();
    setSubmit(true);
    if (projects.some((el) => {
      const condition1 = el.labor_monetization === '';
      const condition2 = el.specialist_level === '';
      const condition3 = el.specialist_name === '';
      const condition4 = el.task === '';
      return condition1 || condition2 || condition3 || condition4;
    })) { return; }
    if (projects.length === 0) { return; }
    const currentChangedProject = data?.results.find((el) => el.id === projectId);
    await updateProject({ project: { /* ...currentChangedProject!, */ required_specialists: projects }, userId: user?.id!, projectId: projectId! });
    await publishProject(projectId!);
    toast.success('Проект опубликован');
    dispatch(setRequestUser(Date.now()));
    dispatch(openSpecCreatePopup(false));
  };

  useEffect(() => {
    const currentChangedProject = data?.results.find((el) => el.id === projectId);
    setProjectsArr(currentChangedProject?.required_specialists.length === 0 ? [{
      specialist_name: '', specialist_level: '', labor_monetization: '', task: '',
    }] : currentChangedProject?.required_specialists!);
  }, [data, user]);
  return (
    <Form onSubmit={publish}>
      <H2>{specCreatePopupHeading}</H2>
      <P>Добавьте требуемых специалистов</P>
      {projects?.map((el, index) => (
        <Fieldset>
          <InputsWrapper>
            <NewSelectWithPlural
              currentIndex={index}
              withoutFirstStage
              zIndex={99999999}
              left={129}
              withError={el.specialist_name === '' && isSubmited}
              forRequired
              label='Специалист'
              setProfession={pushSpecDifinitionInCurrentObjectInArr}
              professionValue={el.specialist_name}
              placeholder1='Выберите специалиста'
              placeholder2='Введите специальность, например: Data engineer'
              emptyCase='Не нашли такого названия специальности'
              baseArr={professions}
              arrForSearch={arrForProfessionSearch} />
            <SelectLevels
              zIndex={9999999999}
              listZIndex={999999999}
              iconZIndex={9999999999}
              left={88}
              labelText='Уровень'
              placeholder='Выберите необходимый уровень знаний'
              arrayForMap={levels}
              withError={el.specialist_level === '' && isSubmited}
              forRequired
              currentIndex={index}
              setLevel={pushSpecLevelInCurrentObjectInArr}
              level={el.specialist_level} />
          </InputsWrapper>
          <SelectLevels
            zIndex={999999}
            listZIndex={9}
            left={206}
            labelText='Монетизация труда'
            placeholder='Выберите вариант оплаты труда'
            arrayForMap={monetizationlevels}
            withError={el.labor_monetization === '' && isSubmited}
            forRequired
            currentIndex={index}
            setLevel={pushSpecMonetizationInObjectInArr}
            level={el.labor_monetization} />
          <TextAreaWithNoValidation
            errorMessage={isSubmited && el.task === ''}
            forRequired
            minLength={10}
            name='task'
            label='Задачи'
            currentIndex={index}
            maxLength={1000}
            value={el.task}
            placeholder='Расскажите про задачи, которые необходимо надо будет выполнять в вашем проекте'
            height={160}
            onChange={pushTaskInObjectInArr} />
          <UniversalButton
            onClick={(e) => { e.preventDefault(); deleteSpecInArr(index); }}
            type='button'
            textColor={Themes[theme].mainTextColor}
            backColor='#c94420'
            borderColor=''
            paddingLeft={40}
            style={{ alignSelf: 'end' }}
            paddingTop={20}>
            Удалить
          </UniversalButton>
        </Fieldset>
      ))}
      <ButtonWithCross
        onClick={addNewSpecInArr}
        withText={false}
        height={56} />
      <UniversalButton
        type='submit'
        textColor={Themes[theme].mainTextColor}
        backColor={Themes[theme].sliderColor}
        borderColor=''
        paddingLeft={40}
        style={{ alignSelf: 'end' }}
        paddingTop={20}>
        Опубликовать
      </UniversalButton>
    </Form>
  );
};

export default CreateSpecforProject;
