/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from '../../store/store.types';
import {
  useDeclineProjectMutation,
  useDeleteProjectMutation,
  useNewGetCurrentUserQuery,
  useDisagreeToorijectMutation,
  useGetAllInvitedProjectsQuery,
  useGetSpecsWantedToProjectQuery,
} from '../../api/api';
import {
  setFolderId,
  openFolderDeletePopup,
  setProjectId,
  setChoosePopupOpen,
  openSpecCreatePopup,
  setHeadingForPopup,
} from '../../store/allSlice';
import Button from '../Button';
import { BlackDelIcon } from '../icons';

export const ControlContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
`;

export const Form = styled.form`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  z-index: 99999;
  padding:20px;
  border-radius: 50px;
  background: #FFFEFA;
  width: 459px;
  min-height: 231px;
  flex-shrink: 0;
  backdrop-filter: blur(4px);
`;

const ApproveText = styled.p`
    color: ${({ theme: { mainTextColor } }) => mainTextColor};
    font-family: 'TTTravels';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-self: center;
    margin-bottom: 23px;
    gap: 40px;
    display: flex;
    align-items: center;
    margin: 0;
`;

const ChoosePopup: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [deleteProject] = useDeleteProjectMutation();
  const [declineProject] = useDeclineProjectMutation();
  const [disagree] = useDisagreeToorijectMutation();
  const { data: prj, refetch } = useGetAllInvitedProjectsQuery();
  const { refetch: re } = useGetSpecsWantedToProjectQuery();
  const { folderId, projectId } = useSelector((state) => state.all);
  const { data } = useNewGetCurrentUserQuery();
  const deleteFunc = async (id: any) => {
    try {
      await deleteProject({ userId: data?.id!, projectId: folderId! });
      dispatch(openFolderDeletePopup(false));
      navigate('/projects');
    } catch (err) {
      console.log(err);
    }
  };
  const declineProjectFunc = async () => {
    await disagree({ userId: projectId!, id: folderId! });
    await refetch();
  };
  const declineProject1 = (e: any) => {
    e.stopPropagation();
    declineProject({ userId: projectId!, id: folderId! });
    re();
  };
  const addSpec = () => {
    dispatch(openSpecCreatePopup(true));
    dispatch(setProjectId(projectId));
    dispatch(setChoosePopupOpen(false));
  };
  return (
    <Form>
      <BlackDelIcon
        onClick={() => dispatch(setChoosePopupOpen(false))}
        style={{ position: 'absolute', top: '-27px', right: '-30px' }} />

      <Button onClick={addSpec} type='button' text='Добавить специалиста' isColored showYellow />
      <Button onClick={() => { navigate(`/projects/${projectId}`); dispatch(setChoosePopupOpen(false)); }} type='button' text='Редактировать проект' isColored={false} />

    </Form>
  );
};

export default ChoosePopup;
